import parse from 'html-react-parser';

import VideoPlayer from '@Eikochain/components/molecules/VideoPlayer';
import { Badge } from "@Eikochain/components/ui/badge";
import { CanvasElementTypes } from '@Eikochain/types';
import { ClockIcon } from "lucide-react";

export default function renderContentItemFromType (
  type: CanvasElementTypes,
  content: string | string[],
) {
  switch(type) {
    case "text":
      return (
        <div className="break-all overflow-hidden p-2">
          {parse(content as string)}
        </div>
      )
    case "imageSingle":
      return <Badge color="yellow"><ClockIcon /> Pending</Badge>
    case "imageCarousel":
      return <Badge color="yellow"><ClockIcon /> Pending</Badge>
    case "video":
      const params = {
        controls: "0",
        enablejsapi: "1",
      }
      const url = new URL(content as string);
      url.search = new URLSearchParams(params) as unknown as string;
      return (
        <VideoPlayer src={url as unknown as string} />
      )
    default:
      return null;
  }
};
