import { gql, useQuery } from 'urql';

const GetTotalProductEmissionsQuery = gql`
  query {
    totalProductEmissions
  }
`;

const useGetTotalProductEmissions = () => {
  const result = useQuery({
    query: GetTotalProductEmissionsQuery,
  })
  return result;
}

export default useGetTotalProductEmissions;
