import { Button } from "@Eikochain/components/ui/button";
import { DialogContent, DialogHeader, DialogTitle } from "@Eikochain/components/ui/dialog";
import { Input } from "@Eikochain/components/ui/input";
import { CopyIcon } from "lucide-react";
import { useState } from "react";

interface CopyLinkModalProps {
  id: string;
}

export default function CopyLinkModal ({
  id,
}: CopyLinkModalProps) {
  const [copied, setCopied] = useState(false);
  const value = `${window.location.origin}/MarketingPanels/ViewMarketingPanel?id=${id}`
  const handleCopy = async () => {
    await navigator.clipboard.writeText(value);
    setCopied(true);
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>
          Copy link
        </DialogTitle>
      </DialogHeader>
      <div className="flex justify-stretch gap-4">
        <Input
          className="w-full"
          value={value}
          readOnly
        />
        <Button variant="secondary" icon={<CopyIcon />} onClick={handleCopy}>
          {copied ? "Copied!" : "Copy link"}
        </Button>
      </div>
    </DialogContent>
  )
};
