import H3 from "@Eikochain/components/atoms/H3";
import StatusBadge, { Statuses } from "@Eikochain/components/molecules/StatusBadge";
import ActivitiesTable from "@Eikochain/components/ui/activities-table";
import ExportDataButton from "@Eikochain/components/ui/export-data-dialog/export-data-button";
import useGetUploadedFileDetailById from "@Eikochain/graphql/hooks/useGetUploadById";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { useParams } from "react-router-dom";

const UploadedFileDetail = () => {
  const breadcrumbs = [{
    name: "Shipping calculator",
    url: "/app/shipping-calculator"
  }]
  const { id: uploadId } = useParams<{ id: string }>();
  const [result] = useGetUploadedFileDetailById(uploadId as string);

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="flex flex-col p-4 gap-8">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-2">
            <H3>Emissions</H3>
            <div className="flex flex-row gap-4">
              <div className="flex-row gap-2">
                <span className="text-gray-800">Extracted from file upload </span>
                <span className="text-gray-500">{result?.data?.uploadById?.csvFile}</span>
              </div>
              {result?.data?.uploadById?.status ? <StatusBadge status={result?.data.uploadById.status as Statuses} /> : null}
            </div>
          </div>
          <div className="flex gap-2 self-end">
            <ExportDataButton
              disabled={!result?.data?.uploadById?.status || result?.data?.uploadById?.status !== "success"}
              csvUploadId={uploadId}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <ActivitiesTable
            data={result?.data?.uploadById?.activities ?? []}
            isFetching={result?.fetching}
            searchBy="name"
          />
        </div>
      </div>
    </BaseLayout>
  );
};

export default UploadedFileDetail;
