import { SigmaIcon } from "lucide-react";
import { ReactNode } from "react";
import { Button } from "../button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../tooltip";

interface CalculateEmissionsButtonProps {
  onCalculate?: () => void;
  loading: boolean;
  disabled: boolean;
  canBeRecalculated: boolean;
  notAllRequirementsMet?: boolean;
  className?: string;
}

type TooltipProviderWrapperProps = {
  content: string
}

export default function CalculateEmissionsButton ({
  onCalculate,
  loading = false,
  disabled = false,
  canBeRecalculated = false,
  notAllRequirementsMet = false,
  className,
}: CalculateEmissionsButtonProps) {
  const TooltipWrapper = ({ children }: { children: ReactNode }) => {
    const TooltipProviderWrapper = ({ content }: TooltipProviderWrapperProps) => (
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>{children}</TooltipTrigger>
          <TooltipContent>{content}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
    if (notAllRequirementsMet) {
      return <TooltipProviderWrapper content="All required activities need to be added for an initial estimate" />;
    }
    if (canBeRecalculated) {
      return <TooltipProviderWrapper content="Your emissions are not up to date, click to re-calculate" />;
    }
    return <TooltipProviderWrapper content="Emissions are up-to-date" />;
  };

  return (
    <TooltipWrapper>
      <Button
        onClick={onCalculate}
        loading={loading}
        disabled={disabled}
        icon={<SigmaIcon />}
        variant="fun"
        className={className}
      >
        Calculate emissions
      </Button>
    </TooltipWrapper>
  );
};
