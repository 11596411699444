import Spacer from "@Eikochain/components/atoms/Spacer";
import Modal from "@Eikochain/components/molecules/Modal/Modal";
import { whiteColor } from "@Eikochain/themes/constants";
import { Radio } from "antd";
import { XIcon } from "lucide-react";
import { useState } from "react";
import MarketingPanel from "../Panel";
import { MarketingPanelLayout } from "../types";

interface PreviewMarketingPanelModalProps {
  open: boolean;
  onClose: () => void;
}

const PreviewMarketingPanelModal = ({ open, onClose }: PreviewMarketingPanelModalProps) => {
  // Since the preview is only shown from the editor, we use the
  // local state to populate the instance
  const [displayMode, setDisplayMode] = useState<MarketingPanelLayout>("desktop")
  return (
    <Modal
      open={open}
      hideFooter
      width="fit-content"
      modalRender={() => (
        <div style={{ pointerEvents: "all" }}>
          <div className="flex justify-between items-center">
            <div />
            <div className="flex items-center">
              <Radio.Group
                defaultValue="desktop"
                buttonStyle="solid"
                onChange={(event) => setDisplayMode(event.target.value)}
              >
                <Radio.Button value="desktop">Desktop</Radio.Button>
                <Radio.Button value="mobile">Mobile</Radio.Button>
              </Radio.Group>
            </div>
            <XIcon
              style={{ fontSize: 28, color: whiteColor }}
              onClick={onClose}
            />
          </div>
          <Spacer height={2} />
          <MarketingPanel layout={displayMode} isEditing={false} />
        </div>
      )}
      maskClosable={true}
    />
  )
};

export default PreviewMarketingPanelModal;
