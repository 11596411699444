import { cn } from "@Eikochain/utils";
import { CheckIcon, ChevronDownIcon } from "lucide-react";
import { useState } from "react";
import { Control } from "react-hook-form";
import { Button } from "../../button";
import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from "../../command";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Popover, PopoverContent, PopoverTrigger } from "../../popover";

interface ProcessFieldProps {
  control: Control<any>;
  processes: string[]
  disabled?: boolean;
}

export default function ProcessField({
  control,
  processes,
  disabled = false,
}: ProcessFieldProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  return (
    <FormField
      control={control}
      name="process"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Process</FormLabel>
          <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  disabled={disabled}
                  className={cn(
                    "justify-between font-normal",
                    !field.value && "text-muted-foreground"
                  )}
                >
                  {field.value ?? "Select process"}
                  <ChevronDownIcon className="ml-2 h-4 w-4 shrink-0" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] p-0">
              <Command>
                <CommandList>
                  <CommandEmpty>No processes found</CommandEmpty>
                  <CommandGroup>
                    {
                      processes?.map(m => (
                        <CommandItem
                          key={m}
                          value={m}
                          onSelect={() => {
                            field.onChange(m);
                            setPopoverOpen(false)
                          }}
                        >
                          {m}
                          <CheckIcon
                            className={cn(
                              "ml-auto h-4 w-4",
                              m === field.value
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                        </CommandItem>
                      ))
                    }
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
