import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MarketingPanel from "@Eikochain/components/organisms/MarketingEditor/Panel";
import { IMarketingPanel } from "@Eikochain/components/organisms/MarketingEditor/types";

const ViewMarketingPanel = () => {
  const [instance, setInstance] = useState<IMarketingPanel | null>(null)
  const { id: marketingPanelId } = useParams<{ id: string }>();
  useEffect(() => {
    // TODO: Fill in fetching of marketing panel by ID
  }, [marketingPanelId])

  return (
    instance ? <MarketingPanel instance={instance} /> : null
  )
};

export default ViewMarketingPanel;
