import Spacer from "@Eikochain/components/atoms/Spacer";
import useDeleteSelectedElement from "@Eikochain/hooks/MarketingEditor/useDeleteSelectedElement";
import useDuplicateSelectedElement from "@Eikochain/hooks/MarketingEditor/useDuplicateSelectedElement";
import { selectedElementIdAtom } from "@Eikochain/state/atoms/CanvasContent";
import { CanvasElementTypes } from "@Eikochain/types";
import { FloatButton } from "antd";
import { useAtomValue } from "jotai";
import { CameraIcon, CopyIcon, Edit2Icon, FilmIcon, Trash2Icon, TypeIcon } from "lucide-react";
import { useState } from "react";
import CreateOrUpdateCanvasElementModal from "../Modals/CreateOrUpdateCanvasElementModal";

interface IFloatingMenu {
  onAddElementClick: (type: CanvasElementTypes) => void;
}

const FloatingMenu = ({
  onAddElementClick,
}: IFloatingMenu) => {
  const selectedElement = useAtomValue(selectedElementIdAtom);
  const [modalOpen, setModalOpen] = useState(false);

  function onEditSelectedElement() {
    setModalOpen(true)
  };

  const duplicateFn = useDuplicateSelectedElement();
  function onDuplicateSelectedElement() {
    const result = duplicateFn();
    return result;
  };

  const deleteFn = useDeleteSelectedElement();
  function onDeleteSelectedElement() {
    const result = deleteFn();
    return result;
  };

  return (
    <>
      <div className="absolute w-full h-full flex flex-col justify-end items-end">
        {
          selectedElement && (
            <>
              <FloatButton.Group
                shape="square"
                style={{ position: "relative", pointerEvents: "all", right: 20 }}
              >
                <FloatButton
                  icon={<Edit2Icon size={20} />}
                  tooltip={<div>Edit selected element</div>}
                  onClick={onEditSelectedElement}
                />
                <FloatButton
                  icon={<CopyIcon size={20} />}
                  tooltip={<div>Duplicate selected element</div>}
                  onClick={onDuplicateSelectedElement}
                />
                <FloatButton
                  icon={<Trash2Icon size={20} className="text-red-400" />}
                  tooltip={<div>Delete selected element</div>}
                  onClick={onDeleteSelectedElement}
                />
              </FloatButton.Group>
              <Spacer height={2} />
            </>
          )
        }
        <FloatButton.Group
          shape="square"
          style={{ position: "relative", pointerEvents: "all", right: 20 }}
        >
          <FloatButton
            icon={<TypeIcon size={20} />}
            tooltip={<div>Add text</div>}
            onClick={() => onAddElementClick("text")}
          />
          <FloatButton
            icon={<FilmIcon size={20} />}
            tooltip={<div>Add video</div>}
            onClick={() => onAddElementClick("video")}
          />
          <FloatButton
            icon={<CameraIcon size={20} />}
            tooltip={<div>Add image or carousel</div>}
            onClick={() => onAddElementClick("imageSingle")}
          />
        </FloatButton.Group>
      </div>
      <CreateOrUpdateCanvasElementModal
        title="Edit element"
        openWithSelectedInstance={true}
        open={modalOpen}
        onFinish={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      />
    </>
  )
};

export default FloatingMenu;
