import Head from "@Eikochain/components/atoms/Head";
import GlobalTheme from "@Eikochain/themes/GlobalTheme";
import { ReactNode } from "react";

interface CoreLayoutProps {
  title?: string;
  description?: string;
  children?: ReactNode;
}

const CoreLayout = ({
  title = "EikoIQ",
  description = "",
  children,
}: CoreLayoutProps) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta key="description" name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <link rel="shortcut icon" href="/favicon-small.ico" />
      </Head>
      <GlobalTheme>
        {children}
      </GlobalTheme>
    </>
  );
};

export default CoreLayout;
