import { DialogContent, DialogHeader, DialogTitle } from "@Eikochain/components/ui/dialog";
import SelectProductsToImport from "./SelectProductsToImport";

type ImportProductsModalProps = {
  organizationIntegrationId: string;
}

const ImportProductsModal = ({
  organizationIntegrationId,
}: ImportProductsModalProps) => {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Select products</DialogTitle>
      </DialogHeader>
      <SelectProductsToImport organizationIntegrationId={organizationIntegrationId} />
    </DialogContent>
  )
};

export default ImportProductsModal;
