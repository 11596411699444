import { gql, useQuery } from 'urql';

const GetProductByIdQuery = gql`
  query($id: String!) {
    productById(id: $id) {
      id: djangoId
      name
      description
      url
      category
    }
  }
`;

const useGetProductById = (id: string) => {
  const result = useQuery({
    query: GetProductByIdQuery,
    variables: {
      id
    }
  })
  return result;
}

export default useGetProductById;
