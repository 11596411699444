import { cn } from "@Eikochain/utils";
import { CheckIcon, ChevronDownIcon } from "lucide-react";
import { useState } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { Button } from "../../button";
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from "../../command";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Popover, PopoverContent, PopoverTrigger } from "../../popover";

interface ServiceTypeFieldProps {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  goodsTypes: {label: string, value: string}[]
}

export default function GoodsTypeField({
  control,
  setValue,
  goodsTypes
}: ServiceTypeFieldProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  return (
    <FormField
      control={control}
      name="goodsType"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Goods</FormLabel>
          <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between font-normal",
                    !field.value && "text-muted-foreground"
                  )}
                >
                  <span className="text-ellipsis overflow-hidden">
                    {field.value ?? "Select from goods"}
                  </span>
                  <ChevronDownIcon className="ml-2 h-4 w-4 flex-shrink-0" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] p-0">
              <Command>
                <CommandInput placeholder="Search goods types..." />
                <CommandList>
                  <CommandEmpty>No goods types found</CommandEmpty>
                  {goodsTypes.map(goodsType => (
                    <CommandItem
                      key={goodsType.value}
                      value={goodsType.value}
                      onSelect={() => {
                        setValue("goodsType", goodsType.value);
                        setPopoverOpen(false)
                      }}
                    >
                      {goodsType.label}
                      <CheckIcon
                        className={cn(
                          "ml-auto h-4 w-4",
                          goodsType.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
