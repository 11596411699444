import { AlertCircleIcon, AlertTriangleIcon } from "lucide-react";
import H3 from "../atoms/H3";
import { Card, CardContent, CardHeader, CardTitle } from "./card";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";

export function ErrorIcon () {
  return (
  <TooltipProvider>
    <Tooltip delayDuration={0}>
      <TooltipTrigger asChild>
        <AlertCircleIcon className="text-red-500" />
      </TooltipTrigger>
      <TooltipContent>Please fill out information to get a carbon estimate and range</TooltipContent>
    </Tooltip>
  </TooltipProvider>
)};

export function WarningIcon () {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <AlertTriangleIcon className="text-yellow-500" />
        </TooltipTrigger>
        <TooltipContent>We suggest filling out more activities to get a more accurate carbon estimate</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
};

interface CarbonCountMetricProps {
  icon: JSX.Element;
  title: string;
  showWarning?: boolean;
  showError?: boolean;
  metricValue: string;
}

export default function CarbonCountMetric({
  icon,
  title,
  showWarning,
  showError,
  metricValue,
}: CarbonCountMetricProps) {
  return (
    <Card>
      <CardHeader>
        {icon}
        <CardTitle>
          <span className="text-base font-normal">{title}</span>
        </CardTitle>
        <div className="ml-4">
          {showError ? <ErrorIcon /> : null}
          {showWarning ? <WarningIcon /> : null}
        </div>
      </CardHeader>
      <CardContent>
        <H3>
          {metricValue}
          <sup>
            {" "}
            kgCO<sub>2</sub>e
          </sup>
        </H3>
      </CardContent>
    </Card>
  )
}
