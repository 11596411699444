import { BreakdownItem } from "@Eikochain/__generated__/graphql";
import useGetEmissionsBreakdownByScope from "@Eikochain/graphql/hooks/useGetEmissionsBreakdownByScope";
import { BuildingIcon, CalculatorIcon, ExternalLinkIcon, RouterIcon, TruckIcon } from "lucide-react";
import { Link } from "react-router-dom";
import H3 from "../atoms/H3";
import Paragraph from "../atoms/P";
import { Button } from "./button";
import CalculateEmissionsButtonForScopes from "./calculate-emissions-button/calculate-emissions-button-for-scopes";
import CarbonCountMetric from "./carbon-count-metric";

export default function ScopedActivityMetrics () {
  const [result] = useGetEmissionsBreakdownByScope();
  const emissionsBreakdownByScope: BreakdownItem[] = result?.data?.emissionsBreakdownByScope;
  const nameIconMapping = [
    {
      "name": 'Scope 1',
      "icon": <BuildingIcon />
    },
    {
      "name": 'Scope 2',
      "icon": <RouterIcon />
    },
    {
      "name": 'Scope 3',
      "icon": <TruckIcon />
    },
    {
      "name": 'Total',
      "icon": <CalculatorIcon />
    }
  ]
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <H3>Metrics</H3>
          <Paragraph className="hidden md:flex">Carbon emissions for your operations are listed here.</Paragraph>
        </div>
        <div className="flex flex-col-reverse md:flex-row gap-2 items-end md:items-center">
          <Link to="https://www.nationalgrid.com/stories/energy-explained/what-are-scope-1-2-3-carbon-emissions" target="_blank">
            <Button variant="link" icon={<ExternalLinkIcon />}>
              Learn more about scope emissions
            </Button>
          </Link>
          <CalculateEmissionsButtonForScopes />
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
        {emissionsBreakdownByScope?.map(eb => (
          <CarbonCountMetric
            key={eb.name}
            icon={nameIconMapping.find(e => e.name === eb.name)?.icon!}
            title={eb.name}
            metricValue={eb.count.toString()}
          />
        ))}
      </div>
    </div>
  )
}
