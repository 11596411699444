import TriangleAlertIcon from "@Eikochain/components/svg/triangle-alert-icon";
import { useMemo } from "react";
import { Label, Pie, PieChart } from "recharts";
import { ChartConfig, ChartContainer, ChartLegend, ChartLegendContent, ChartTooltip, ChartTooltipContent } from "../chart";
import Spinner from "../spinner";

export interface PieChartProps {
    data: any[];
    dataTypeDisplayPluralised?: string;
    isFetching?: boolean;
    includeLegend?: boolean;
    withLabels?: boolean;
    customLabel?: string;
}

export default function CustomPieChart({
    data,
    dataTypeDisplayPluralised,
    isFetching,
    includeLegend,
    withLabels = false,
    customLabel
}: PieChartProps) {
    const dataForChartConfig = data.reduce((acc, item) => {
        acc[item.label] = {
            label: item.label,
            color: item.color
        };
        return acc;
    }, {});

    const chartConfig = {
        count: {
            label: "Count",
        },
        ...dataForChartConfig
    } satisfies ChartConfig;

    const totalValues = useMemo(() => {
        return data.reduce((acc, curr) => acc + curr.count, 0)
    }, [data])

    return (
        <>
            {
                data.length && totalValues ? (
                    <ChartContainer config={chartConfig} className="min-h-[200px] w-full">
                        <PieChart accessibilityLayer className="flex flex-row">
                            <ChartTooltip
                                cursor={false}
                                content={<ChartTooltipContent hideLabel />}
                            />
                            {includeLegend && <ChartLegend content={<ChartLegendContent />} />}
                            <Pie
                                data={data}
                                dataKey="count"
                                nameKey="label"
                                innerRadius={60}
                                strokeWidth={5}
                                label={withLabels ? (props) => (
                                    <text
                                        {...props}
                                        textAnchor="end"
                                        className="fill-foreground text-xs font-bold"
                                        fill="#000"
                                    >
                                        {props.count}
                                    </text>
                                ) : undefined}
                            >
                                <Label
                                    content={({ viewBox }) => {
                                        if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                                            return (
                                                <text
                                                    x={viewBox.cx}
                                                    y={viewBox.cy}
                                                    textAnchor="middle"
                                                    dominantBaseline="middle"
                                                >
                                                    <tspan
                                                        x={viewBox.cx}
                                                        y={viewBox.cy}
                                                        className="fill-foreground text-3xl font-bold text-black"
                                                    >
                                                        {isFetching ? <Spinner /> :
                                                            dataTypeDisplayPluralised && (customLabel ?? totalValues.toLocaleString())
                                                        }
                                                    </tspan>
                                                    {
                                                        dataTypeDisplayPluralised && (
                                                            <tspan
                                                                x={viewBox.cx}
                                                                y={(viewBox.cy || 0) + 24}
                                                                className="fill-muted-foreground"
                                                            >
                                                                {dataTypeDisplayPluralised}
                                                            </tspan>
                                                        )
                                                    }
                                                </text>
                                            )
                                        }
                                    }}
                                />
                            </Pie>
                        </PieChart>
                    </ChartContainer>
                ) : (
                    <div className="w-full h-full flex flex-col items-center justify-center gap-2">
                        <TriangleAlertIcon className="text-red-600" />
                        <h3 className="font-semibold">No data to display!</h3>
                    </div>
                )
            }
        </>
    )
}
