import { gql } from "urql";

export const UpdateCSVMutation = gql`
  mutation UpdateCSV($input: UpdateCSVInput!) {
    updateCsv(input: $input) {
      ... on UpdateCSVSuccess {
        csv {
          csvFile
        }
      }
      ... on UpdateCSVErrors {
        fieldErrors {
          fieldName
          errors
        }
      }
    }
  }
`;
