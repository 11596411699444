import { gql, useMutation } from 'urql';

export const CreateOrUpdateOrganizationIntegrationMutation = gql`
  mutation CreateOrUpdateOrganizationIntegration($input: CreateOrUpdateOrganizationIntegrationInput!) {
    createOrUpdateOrganizationIntegration(input: $input) {
      ... on CreateOrUpdateOrganizationIntegrationSuccess {
        organizationIntegration {
          organization {
            name
          }
        }
      }
      ... on CreateOrUpdateOrganizationIntegrationErrors {
        fieldErrors {
          fieldName
          errors
        }
      }
    }
  }
`;
