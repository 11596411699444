import { Panel } from "@Eikochain/__generated__/graphql"
import columns from "./columns"
import DataTable from "../data-table"

interface MarketingPanelsTableProps {
  data: Panel[];
  isFetching: boolean;
}

export default function MarketingPanelsTable({ data, isFetching }: MarketingPanelsTableProps) {
  return (
    <DataTable
      data={data}
      isFetching={isFetching}
      columns={columns}
      showRowCount={false}
    />
  )
}
