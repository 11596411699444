import ProductActivityMetricsSection from "@Eikochain/components/ui/product-activity-metrics-section";
import ProductActivityStepsSection from "@Eikochain/components/ui/product-activity-steps-section";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@Eikochain/components/ui/select";
import useGetProducts from "@Eikochain/graphql/hooks/useGetProducts";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { ProductType } from "@Eikochain/types";
import { UnplugIcon } from "lucide-react";
import { useEffect, useState } from "react";

const CarbonCalculator = () => {
  const [productOptions, setProductOptions] = useState<{ value: string; label: string }[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string | undefined>(productOptions[0]?.value);
  const [result] = useGetProducts();
  const breadcrumbs = [{
    name: "Product Calculator"
  }]

  useEffect(() => {
    const { data } = result;
    if (data) {
      setProductOptions(
        data.products.map((product: ProductType) => ({
          value: product.djangoId,
          label: product.name,
        }))
      );
      setSelectedProduct(data?.products?.[0]?.djangoId);
    }
  }, [result?.data]);

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="mt-6 flex flex-col p-4 gap-8 h-full">
        <div className="flex flex-col gap-2">
          <h3>Select a product</h3>
          <div className="flex max-w-[400px]">
            <Select
              value={selectedProduct}
              onValueChange={(value: string) => setSelectedProduct(value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a product" />
              </SelectTrigger>
              <SelectContent>
                {
                  productOptions?.length ? productOptions.map(m => (
                    <SelectItem key={m.value} value={m.value}>{m.label}</SelectItem>
                  )) : <SelectItem disabled value="empty">Create a product using the Products tab</SelectItem>
                }
              </SelectContent>
            </Select>
          </div>
        </div>
        {selectedProduct ? (
          <div className="flex flex-col gap-6 md:gap-12">
            <ProductActivityMetricsSection productId={selectedProduct} />
            <ProductActivityStepsSection productId={selectedProduct} />
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center h-full text-center gap-4">
            <UnplugIcon size={28} />
            No product selected!<br/>Either create one or pick one from the dropdown above
          </div>
        )}
      </div>
    </BaseLayout>
  );
};

export default CarbonCalculator;
