import GenerateGridLines from "@Eikochain/components/atoms/Grid/GenerateGridLines";
import View from "@Eikochain/components/atoms/View";
import CanvasNoStepSelected from "@Eikochain/components/molecules/CanvasNoStepSelected";
import useUpdatePanelState from "@Eikochain/hooks/MarketingEditor/useUpdatePanelState";
import { canvasMenuSelectedStepIdAtom } from "@Eikochain/state/atoms/CanvasMenuContent";
import { whiteColor } from "@Eikochain/themes/constants";
import { CanvasElementTypes } from "@Eikochain/types";
import { styled } from "goober";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";
import CanvasElementsHOC from "../Elements/CanvasElementsHOC";
import CreateOrUpdateCanvasElementModal from "../Modals/CreateOrUpdateCanvasElementModal/CreateOrUpdateCanvasElementModal";
import { MarketingPanelLayout } from "../types";
import FloatingMenu from "./FloatingMenu";

interface ICanvasContentContainer {
  height?: number;
  width?: number;
};

const CanvasContentContainer = styled(View)<ICanvasContentContainer>`
  position: relative;
  height: ${({ height }) => `${height}px` || "100%"};
  width: ${({ width }) => `${width}px` || "100%"};
  background: ${whiteColor};
`;

const FloatingMenuContainer = styled(View)<ICanvasContentContainer>`
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  position: absolute;
  pointer-events: none;
`;

interface MarketingCanvasProps {
  isEditing?: boolean;
  layout?: MarketingPanelLayout;
}

const MarketingCanvas = ({
  isEditing = false,
  layout = "desktop",
}: MarketingCanvasProps) => {
  // Having this makes it reload the entire panel...
  useUpdatePanelState()
  const selectedStepId = useAtomValue(canvasMenuSelectedStepIdAtom)
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDefaultOption, setModalDefaultOption] = useState<CanvasElementTypes>("text");
  const gridSize = 12;
  const [layoutForPreview, setLayoutForPreview] = useState(layout);
  const gridColumns = layoutForPreview === "mobile" ? 36 : 52;
  const gridRows = 42;
  const canvasWidth = (gridSize * gridColumns) + 1;
  const canvasHeight = (gridSize * gridRows) + 1;

  useEffect(() => {
    setLayoutForPreview(layout);
  }, [layout]);

  const CanvasHasElements = () => (
    <>
      {
        isEditing && (
          <>
            <FloatingMenuContainer width={canvasWidth} height={canvasHeight}>
              <FloatingMenu
                onAddElementClick={
                  (contentType: CanvasElementTypes) => {
                    setModalDefaultOption(contentType); setModalOpen(true)
                  }
                }
              />
            </FloatingMenuContainer>
            <GenerateGridLines cellSize={gridSize}/>
          </>
        )
      }
      <CanvasElementsHOC
        isEditing={isEditing}
        layout={layoutForPreview}
        gridSize={gridSize}
      />
    </>
  )

  const MemoCanvasContentContainer = useMemo(() => {
    // The `useUpdatePanelState` hook above, whilst needed to record the state of the panel,
    // also triggers an *endless* stream of rerenders for this component, which is why we memo it.
    // The rerenders are fine for text elements on the canvas, but for videos which we retrieve
    // from a third-party API (YouTube), this can be expensive and makes for a bad User Experience.
    return (
      <>
        <CanvasContentContainer
          flex
          direction="column"
          height={canvasHeight}
          width={canvasWidth}
          style={{
            top: layout === "mobile" ? 60 : 0 
          }}
          className="CanvasContentContainer"
        >
          {
            selectedStepId ? (
              <CanvasHasElements />
            ) : (
              isEditing ? <CanvasNoStepSelected /> : null
            )
          }
        </CanvasContentContainer>
        {
          isEditing && (
            <CreateOrUpdateCanvasElementModal
              title="Add element"
              open={modalOpen}
              initialElementType={modalDefaultOption}
              onFinish={() => setModalOpen(false)}
              onCancel={() => setModalOpen(false)}
            />
          )
        }
      </>
    )
  }, [selectedStepId, isEditing, modalOpen, modalDefaultOption, layoutForPreview])

  return MemoCanvasContentContainer
}

export default MarketingCanvas;
