import { getAuthToken } from '@Eikochain/auth';
import { Client, cacheExchange, fetchExchange } from 'urql';

const djangoApi = import.meta.env.VITE_DJANGO_API_URL;

export const client = new Client({
  url: `${djangoApi}/api/graphql/`,
  exchanges: [cacheExchange, fetchExchange],
  fetchOptions: () => {
    const token = getAuthToken()
    return token ? { headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json'
    }} : {}
  },
});
