import { Badge } from "../badge";

interface SupplierInformationRequestStatusBadgeProps {
    status: string;
}

export default function SupplierInformationRequestStatusBadge({
    status
}: SupplierInformationRequestStatusBadgeProps) {
    switch (status) {
        case "Delivered":
            return <Badge className="bg-blue-200 text-blue-800">Delivered</Badge>
        case "Failed to send":
            return <Badge className="bg-red-200 text-red-800">Failed to send</Badge>
        case "Complete":
            return <Badge className="bg-emerald-200 text-emerald-800">Complete</Badge>
        case "Responses received":
            return <Badge className="bg-yellow-200 text-yellow-800">Responses received</Badge>
        default:
            return <Badge>Delivered</Badge>
    }
}
