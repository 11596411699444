import { Activity } from "@Eikochain/__generated__/graphql";

interface ActivityParametersOverviewProps {
  activity: Activity
}

export default function ActivityParametersOverview({
  activity
}: ActivityParametersOverviewProps) {
  const renderActivityParams = () => {
    switch (activity.activityType) {
      case "Transport":
        return activity.transportMethod;
      case "Process":
        return `${activity.process} for ${activity.material}`;
      case "Packaging":
        return activity.material;
      case "Waste":
        return activity.disposalMethod;
      case "Fuel":
        return activity.fuelType;
      case "Services":
        return activity.serviceType;
      case "Goods":
        return activity.goodsType;
      default:
        return "";
    }
  }

  return <>{renderActivityParams()}</>
}
