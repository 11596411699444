import { EmailResendMutation } from "@Eikochain/graphql/hooks/mutations/resend-request-email";
import { useToast } from "@Eikochain/hooks/use-toast";
import { ResendEmailInput, ResendEmailStatus } from "@Eikochain/__generated__/graphql";
import { MoreHorizontalIcon, SendIcon, Trash2Icon } from "lucide-react";
import { useState } from "react";
import { useMutation } from "urql";
import { Button } from "../button";
import { Dialog } from "../dialog";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../dropdown-menu";
import DeleteSupplierInformationRequestDialogContent from "./delete-supplier-information-request-dialog-content";

interface SupplierInformationRequestActionsDropdownMenuProps {
    supplierInformationRequestId: string;
    refetch: () => void;
}

export default function SupplierInformationRequestActionsDropdownMenu({
    supplierInformationRequestId,
    refetch
}: SupplierInformationRequestActionsDropdownMenuProps) {
    const [deleteModal, setDeleteModal] = useState(false)
    const [_, executeMutation] = useMutation(EmailResendMutation);
    const { toast } = useToast();

    const resendEmails = async () => {
        const result = await executeMutation({
            input: {
                requestId: supplierInformationRequestId,
            } satisfies ResendEmailInput
        })

        const data: ResendEmailStatus = result.data.resendRequestEmail;
        if (data.success) {
            toast({ title: "Emails have been sent successfully" });
        } else {
            toast({ title: data.reason!, variant: "destructive" });
        }
    }

    return (
        <div onClick={e => e.stopPropagation()}>
            <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
                <DeleteSupplierInformationRequestDialogContent
                    supplierInformationRequestId={supplierInformationRequestId}
                    onFinish={() => { setDeleteModal(false); refetch(); }}
                />
            </Dialog>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="ml-auto">
                        <MoreHorizontalIcon />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="gap-4">
                    <DropdownMenuItem onClick={() => resendEmails()}>
                        <SendIcon />
                        Send follow up emails
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => setDeleteModal(true)}>
                        <Trash2Icon />
                        Delete
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}
