import { ICanvas, IMarketingPanel } from "@Eikochain/components/organisms/MarketingEditor/types";
import { currentPanelAtom, elementsForCurrentCanvasAtom } from "@Eikochain/state/atoms/CanvasContent";
import { canvasMenuSelectedStepIdAtom, canvasMenuStepsAtom } from "@Eikochain/state/atoms/CanvasMenuContent";
import { fontFamilyAtom, fontSizeAtom, fontColorAtom, logoUrlAtom, solidBackgroundColorAtom, gradientBackgroundColorAtom, backgroundColorTypeAtom } from "@Eikochain/state/atoms/CanvasMenuStyles";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";

const useUpdatePanelState = () => {
  const [panel, setPanel] = useAtom(currentPanelAtom);
  const selectedStepId = useAtomValue(canvasMenuSelectedStepIdAtom);
  const logoUrl = useAtomValue(logoUrlAtom);
  const menuSteps = useAtomValue(canvasMenuStepsAtom);
  const elements = useAtomValue(elementsForCurrentCanvasAtom);

  const fontFamily = useAtomValue(fontFamilyAtom);
  const fontSize = useAtomValue(fontSizeAtom);
  const fontColor = useAtomValue(fontColorAtom);
  const solidBackgroundColor = useAtomValue(solidBackgroundColorAtom);
  const gradientBackgroundColor = useAtomValue(gradientBackgroundColorAtom);
  const backgroundColorType = useAtomValue(backgroundColorTypeAtom);

  let newPanelState: IMarketingPanel;

  useEffect(() => {
    // When the content on a canvas changes, we want to record the state in the `panel`
    // Content includes logoUrl, menuSteps, canvases, and elements
    const canvases = panel?.canvases;
    // Note that we should always expect some panel state, since this will be set
    // in the `useSetJotaiStateForMarketingPanel` hook.

    if (selectedStepId && canvases) {
      // 1. Find if a canvas exists in `panel.canvases` for the step selected:
      const canvasForSelectedStepIndex = canvases?.findIndex(canvas => canvas.localId === selectedStepId.localId);
      // 2. If a canvas exists:
      if (canvasForSelectedStepIndex !== -1) {
        const canvasForSelectedStep = canvases[canvasForSelectedStepIndex];
        // 3. Update the canvas with new elements stored in `elements`:
        const updatedCanvasForSelectedStep = {
          ...canvasForSelectedStep,
          elements
        }
        // 4. Create a new object to store all the new canvas elements and set them:
        newPanelState = {
          ...panel,
          canvases: [
            ...canvases.slice(0, canvasForSelectedStepIndex),
            updatedCanvasForSelectedStep,
            ...canvases.slice(canvasForSelectedStepIndex + 1),
          ]
        };
      } else {
        // 5: If there isn't a canvas shown to exist for this step yet, create and add to `panel.canvases`:
        const canvasForSelectedStep: ICanvas = {
          // The localId of the canvas should be the same as the step's localId
          localId: selectedStepId.localId,
          serverId: null,
          elements
        }
        newPanelState = {
          ...panel,
          canvases: [
            ...canvases,
            canvasForSelectedStep
          ]
        };
      }
      setPanel(newPanelState);
    }
  }, [elements])
  
  useEffect(() => {
    newPanelState = {
      ...panel as IMarketingPanel,
      logoUrl: logoUrl
    }
    setPanel(newPanelState);
  }, [logoUrl]);

  useEffect(() => {
    newPanelState = {
      ...panel as IMarketingPanel,
      menuSteps: menuSteps
    }
    setPanel(newPanelState);
  }, [menuSteps]);

  useEffect(() => {
    newPanelState = {
      ...panel as IMarketingPanel,
      menuStyling: {
        fontFamily,
        fontSize,
        fontColor,
        solidBackgroundColor,
        gradientBackgroundColor,
        backgroundColorType
      }
    }
    setPanel(newPanelState);
  }, [fontFamily, fontSize, fontColor, solidBackgroundColor, gradientBackgroundColor, backgroundColorType]);
};
  
export default useUpdatePanelState;
