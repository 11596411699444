import { SupplierInformationRequest } from "@Eikochain/__generated__/graphql";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown } from "lucide-react";
import { Button } from "../button";
import SupplierInformationRequestActionsDropdownMenu from "./supplier-information-request-actions-dropdown-menu";
import SupplierInformationRequestStatusBadge from "./supplier-information-request-status-badge";

export const getColumns: ({ refetch }: { refetch: () => void }) => ColumnDef<SupplierInformationRequest>[] = ({ refetch }) => [
    {
        id: "supplierName",
        header: ({ column }) => {
            return (
                <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                    Supplier name
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => (
            <div className="flex flex-row items-center gap-4">
                <span>{row.original.supplier.name}</span>
            </div>
        ),
    },
    {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => (
            <SupplierInformationRequestStatusBadge status={row.getValue("status")} />
        ),
    },
    {
        accessorKey: "createdAt",
        header: "Sent at",
        cell: ({ row }) => (
            row.getValue("createdAt")
        ),
    },
    {
        id: "actions",
        header: "Actions",
        cell: ({ row }) => (
            <SupplierInformationRequestActionsDropdownMenu refetch={refetch} supplierInformationRequestId={row.original.id} />
        ),
        enableSorting: false,
        enableHiding: false,
    },
]

