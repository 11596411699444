import { Control } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../form";
import { Input } from "../../../input";
import SpendFieldCurrencyDropdown from "./spend-field-currency-dropdown";

interface SpendFieldProps {
  control: Control<any>;
}

export default function SpendField({
  control,
}: SpendFieldProps) {
  return (
    <FormField
      control={control}
      name="spend"
      render={({ field }) => (
        <FormItem>
          <FormLabel className="flex flex-row items-center gap-2">
            Spend
          </FormLabel>
          <div className="flex flex-row items-center w-full gap-4">
            <SpendFieldCurrencyDropdown
              control={control}
              currencies={[
                {
                  currencyCode: "USD",
                  currencyName: "US Dollar",
                },
                {
                  currencyCode: "EUR",
                  currencyName: "Euro",
                },
                {
                  currencyCode: "GBP",
                  currencyName: "Pound",
                },
              ]}
              className="flex-shrink-0"
            />
            <FormControl>
              <Input
                type="number" min="0" max="100000000" step="0.001" placeholder="e.g. 100000"
                className="w-full"
                {...field}
              />
            </FormControl>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
