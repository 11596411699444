import { Button } from "@Eikochain/components/ui/button";
import { UndoIcon } from "lucide-react";

const FT_MARKETING_EDITOR_CONTROL_UNDO_AND_REDO = `${import.meta.env.VITE_FT_MARKETING_EDITOR_CONTROL_UNDO_AND_REDO}`;

export default function MarketingEditorControlUndoButton () {
  return FT_MARKETING_EDITOR_CONTROL_UNDO_AND_REDO ? (
    <Button onClick={() => null} disabled icon={<UndoIcon />}>
      Undo
    </Button>
  ) : null;
};
