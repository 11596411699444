import { DeleteSupplierMutation } from "@Eikochain/graphql/hooks/useDeleteSupplier";
import { Trash2Icon } from "lucide-react";
import { toast } from "sonner";
import { useMutation } from "urql";
import { Button } from "./button";
import { DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "./dialog";

type DeleteSupplierDialogContentProps = {
  supplierId: string;
  onFinish: () => void;
}

export default function DeleteSupplierDialogContent({
  supplierId,
  onFinish
}: DeleteSupplierDialogContentProps) {
  const [mutationResult, executeMutation] = useMutation(DeleteSupplierMutation);
  function deleteFile() {
    executeMutation({ id: supplierId }).then((result) => {
      const { data, error } = result;
      if (error || data.error) {
        toast.error("There was an error deleting this supplier");
      } else {
        toast.success("Deleted supplier");
        onFinish && onFinish();
      }
    });
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Delete supplier?</DialogTitle>
        <DialogDescription>
          Confirming will irreversibly delete the supplier
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button
          variant="destructive"
          loading={mutationResult?.fetching}
          icon={<Trash2Icon />}
          onClick={deleteFile}
        >
          Delete
        </Button>
        <DialogClose asChild>
          <Button variant="secondary" loading={mutationResult?.fetching}>Cancel</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  )
}
