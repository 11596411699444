import useJumpToCanvas from "@Eikochain/hooks/MarketingEditor/useJumpToCanvas";
import { cn } from "@Eikochain/utils";
import MarketingCanvas from "./Canvas/MarketingCanvas";
import NavigationMenuDesktop from "./Navigation/NavigationMenuDesktop";
import NavigationMenuMobile from "./Navigation/NavigationMenuMobile";
import { IMarketingPanel, MarketingPanelLayout } from "./types";

export const MARKETING_CANVAS_HEIGHT = 505;

interface IMarketingPanelProps {
  instance?: IMarketingPanel;
  isEditing?: boolean;
  layout?: MarketingPanelLayout;
}

const MarketingPanel = ({
  instance,
  isEditing = false,
  layout = "desktop",
}: IMarketingPanelProps) => {
  useJumpToCanvas()
  return (
    <div className={cn(
      "PanelContainer relative flex rounded-lg overflow-hidden shadow-lg w-fit bg-white",
      `h-[${MARKETING_CANVAS_HEIGHT}px]`,
      layout === "mobile" ? "flex-col" : "flex-row"
    )}>
      {
        layout === "mobile"
        ? <NavigationMenuMobile isEditing={isEditing} />
        : <NavigationMenuDesktop isEditing={isEditing} />
      }
      <MarketingCanvas isEditing={isEditing} layout={layout} />
    </div>
  )
};

export default MarketingPanel;
