import Spacer from "@Eikochain/components/atoms/Spacer";
import Modal from "@Eikochain/components/molecules/Modal";
import { Button } from "@Eikochain/components/ui/button";
import type { InputRef } from "antd";
import { Form, Input } from "antd";
import { useEffect, useRef } from "react";

interface RenameMenuStepModalProps {
  defaultStepName: string;
  open?: boolean;
  onFinish?: (stepName: string) => void;
  onOk?: () => void;
  onCancel?: () => void;
}

const RenameMenuStepModal = ({ defaultStepName, open = false, onFinish, onOk, onCancel }: RenameMenuStepModalProps) => {
  const stepNameInputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  useEffect(() => {
    if (stepNameInputRef.current) {
      stepNameInputRef.current.focus({ cursor: "all" });
    }
  }, []);
  function onFinishFn(values: any) {
    if (onFinish) onFinish(values.stepName);
  }

  return (
    <Modal open={open} title="Rename step" hideFooter>
      <Form
        form={form}
        name="RenameMenuStep"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={(values: any) => onFinishFn(values)}
        autoComplete="off"
        labelAlign="left"
        labelWrap
        colon={false}
      >
        <Form.Item label="Step name" name="stepName" rules={[{ required: true, message: "Step name is required" }]}>
          <Input defaultValue={defaultStepName} ref={stepNameInputRef} />
        </Form.Item>
        <Spacer height={4} />
        <div className="flex justify-end">
          <Button type="submit">Apply</Button>
          <Spacer width={2} />
          <Button variant="destructive" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default RenameMenuStepModal;
