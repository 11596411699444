import { CalculateEmissionResultsForScopedActivitiesMutation } from "@Eikochain/graphql/hooks/mutations/useCalculateEmissionResultsForScopedActivities";
import useGetEmissionsBreakdownByScope from "@Eikochain/graphql/hooks/useGetEmissionsBreakdownByScope";
import useGetScopedActivitiesByOrganizationId from "@Eikochain/graphql/hooks/useGetScopedActivitiesByOrganizationId";
import { carbonActivitiesAtom } from "@Eikochain/state/atoms/CarbonActivitiesAtom";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { useMutation } from "urql";
import CalculateEmissionsButton from "./calculate-emissions-button";

export default function CalculateEmissionsButtonForScopes () {
  const [mutation, executeMutation] = useMutation(CalculateEmissionResultsForScopedActivitiesMutation);
  const [reCalculateEmissions, setReCalculateEmissions] = useState(false);
  const carbonActivities = useAtomValue(carbonActivitiesAtom);
  const [_, reexecuteEmissionsQuery] = useGetEmissionsBreakdownByScope();
  const [__, reexecuteActivitiesQuery] = useGetScopedActivitiesByOrganizationId();

  useEffect(() => {
    const everyActivityHasEmissionCalculation = carbonActivities.every(
      (activity) => activity.mostRecentEmissionCalculation !== null
    );
    if (everyActivityHasEmissionCalculation) {
      setReCalculateEmissions(false);
    } else {
      setReCalculateEmissions(true);
    }
  }, [carbonActivities]);

  const onCalculate = async () => {
    executeMutation().then((result) => {
      const { data, error } = result;
      if (error) {
        toast.error("Something went wrong! We'll look into it");
      } else {
        if (data?.calculateEmissionResultsForScopedActivities?.success) {
          toast.success("Emissions calculated");
          reexecuteEmissionsQuery({ requestPolicy: "network-only" });
          reexecuteActivitiesQuery({ requestPolicy: "network-only" });
        } else {
          toast.error("Something went wrong! We'll look into it");
        }
      }
    });
  };

  return (
    <CalculateEmissionsButton
      onCalculate={onCalculate}
      loading={mutation?.fetching}
      disabled={!reCalculateEmissions || mutation?.fetching ? true : false}
      canBeRecalculated={reCalculateEmissions}
    />
  );
};
