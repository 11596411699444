import { KeySquareIcon, PiggyBankIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../button";
import Logo from "../logo";

export default function NavigationBar() {
    const showFeatureNotification = false;
    return (
        <nav className="flex flex-col justify-center items-center fixed z-10 w-screen">
            {
                showFeatureNotification && (
                    <div className="flex justify-center items-center p-4 gap-2 bg-black w-full backdrop-blur-lg bg-opacity-80">
                        <span className="text-emerald-200">NEW</span>
                        <span className="text-white inline-flex">Scope 3 emissions tracking<span className="hidden sm:flex">&nbsp;with EikoIQ</span></span>
                    </div>
                )
            }
            <div className="flex flex-row items-center justify-center w-full lg:gap-8 lg:mt-6">
                <div className="w-full max-w-[1300px] bg-white lg:rounded-full lg:shadow-lg flex flex-row justify-between items-center p-4 md:px-12 gap-4 md:gap-24">
                    <div className="inline-flex items-center w-auto flex-shrink-0 flex-grow-0 gap-8">
                        <Link className="max-h-8 w-fit flex-shrink-0 flex-grow-0" to="/">
                            <Logo className="max-h-8 w-auto" />
                        </Link>
                    </div>
                    {/* <div className="hidden sm:flex flex-row items-center">
            <NavigationMenu>
              <NavigationMenuList>
                <IndustriesDropdownMenu />
                <UseCasesDropdownMenu />
              </NavigationMenuList>
            </NavigationMenu>
          </div> */}
                    <div className="flex flex-row gap-2 items-center">
                        {/*<Link to="/pricing/">
                            <Button variant="link" icon={<PiggyBankIcon />} className="md:text-lg">Pricing</Button>
                        </Link>*/}
                        <Link to="/app/products/" className="hidden sm:block">
                            <Button variant="link" icon={<KeySquareIcon />} className="md:text-lg">Login</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    )
}
