import { gql } from 'urql';

export const CreateSupplierMutation = gql`
  mutation CreateSupplier($input: CreateSupplierInput!) {
    createSupplier(input: $input) {
        success
        reason
    }
  }
`;
