import { DeleteCSVMutation } from "@Eikochain/graphql/hooks/useDeleteCSV";
import useGetProducts from "@Eikochain/graphql/hooks/useGetProducts";
import { Trash2Icon } from "lucide-react";
import { toast } from "sonner";
import { useMutation } from "urql";
import { Button } from "../button";
import { DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../dialog";

type DeleteUploadDialogContentProps = {
  fileId: string;
  onFinish: () => void;
}

export default function DeleteUploadDialogContent({
  fileId,
  onFinish
}: DeleteUploadDialogContentProps) {
  const [result, reexecuteQuery] = useGetProducts();
  const [mutationResult, executeMutation] = useMutation(DeleteCSVMutation);
  function deleteFile() {
    executeMutation({ id: fileId }).then((result) => {
      const { data, error } = result;
      if (error) {
        toast.error("There was an error deleting this CSV upload");
      } else {
        toast.success("Deleted CSV upload");
        onFinish && onFinish();
        reexecuteQuery({ requestPolicy: "network-only" });
      }
    });
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Delete CSV file and calculated emissions?</DialogTitle>
        <DialogDescription>
          Confirming will irreversibly delete the uploaded CSV file and any emissions that were calculated from it
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button
          variant="destructive"
          loading={mutationResult?.fetching}
          icon={<Trash2Icon />}
          onClick={deleteFile}
        >
          Delete
        </Button>
        <DialogClose asChild>
          <Button variant="secondary" loading={mutationResult?.fetching}>Cancel</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  )
}
