import { ReactNode, useEffect, useState } from "react";
import { Routes, useLocation } from "react-router-dom";
import NProgress from "nprogress";

interface CustomRoutesProps {
  children: ReactNode;
}

export default function CustomRoutes ({
  children
}: CustomRoutesProps) {
  // Creating control over route change events and able to show a
  // progress bar for a nicer UX
  const [prevLoc, setPrevLoc] = useState<string>("")
  const location = useLocation()

  useEffect(() => {
    setPrevLoc(location.pathname)
    NProgress.start();
    if(location.pathname===prevLoc){
      setPrevLoc('')
    }
 }, [location])

 useEffect(() => {
   NProgress.done();
 }, [prevLoc])

  return (
    <Routes>
      {children}
    </Routes>
  )
};
