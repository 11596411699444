import CustomPieChart, { PieChartProps } from "./custom-pie-chart";

const colorsForActivityType: {[key: string]: string} = {
  "Transport": "#bef264",
  "Packaging": "#86efad",
  "Process": "#2563eb",
  "Electricity": "#5eead5",
  "Fuel": "#6477f2",
  "Waste": "#f2df64",
  "Services": "#9864f2",
  "Goods": "#f29864"
}

export default function ActivityTypeBreakdownPieChart({
  data,
  dataTypeDisplayPluralised = "activities",
  isFetching,
  includeLegend,
}: PieChartProps) {
  const dataWithColors = data.map((d) => ({
    ...d,
    fill: colorsForActivityType[d.label]
  }))
  return (
    <CustomPieChart
      data={dataWithColors}
      dataTypeDisplayPluralised={dataTypeDisplayPluralised}
      isFetching={isFetching}
      includeLegend={includeLegend}
    />
  )
}
