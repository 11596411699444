import { gql, useQuery } from 'urql';

const GetEmissionsBreakdownByScopeQuery = gql`
  query {
    emissionsBreakdownByScope {
      name
      count
    }
  }
`;

const useGetEmissionsBreakdownByScope = () => {
  const result = useQuery({
    query: GetEmissionsBreakdownByScopeQuery,
  })
  return result;
}

export default useGetEmissionsBreakdownByScope;
