import { UIRenderProps } from '@pdfme/common';
import { StatisticSchema } from './types';
import {
    uiRender as parentUiRender,
} from '../text/uiRender'

export const uiRender = async (arg: UIRenderProps<StatisticSchema>) => {
    const { value, schema, rootElement, mode, onChange, ...rest } = arg;

    let text = schema.text;

    if (mode === 'form') {
        return;
    }

    // Add border to make it easier to see the element 
    if (rootElement) {
        rootElement.className += " bg-gray-200 border border-gray-400 rounded-sm";
    }

    await parentUiRender({
        value: schema.statisticType ? `Statistic ${schema.statisticType}` : text,
        schema,
        mode: 'viewer',
        rootElement,
        ...rest,
    });
};

