import useGetFactorForEmissionResult from "@Eikochain/graphql/hooks/useGetFactorForEmissionResult";
import { DatabaseIcon, VariableIcon } from "lucide-react";
import { DialogContent, DialogHeader, DialogTitle } from "../dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../tabs";
import FactorInfoTable from "./factor-info-table";
import FactorNameTile from "./factor-name-tile";
import FactorSourceTile from "./factor-source-tile";
import FactorValuesTable from "./factor-values-table";

interface InspectCalculationDialogProps {
  emissionResultId: string;
}

export default function InspectCalculationDialog({
  emissionResultId
}: InspectCalculationDialogProps) {
  const [emissionFactor] = useGetFactorForEmissionResult(emissionResultId)
  const result = emissionFactor?.data?.factorSourceForEmissionResult;
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Emission factor</DialogTitle>
      </DialogHeader>
      <Tabs defaultValue="parameters">
        <TabsList>
          <TabsTrigger value="parameters"><VariableIcon />Parameters</TabsTrigger>
          <TabsTrigger value="source"><DatabaseIcon />Source and Values</TabsTrigger>
          {/* <TabsTrigger value="calculation"><SigmaIcon />Calculation</TabsTrigger> */}
        </TabsList>
        <TabsContent value="parameters">
          <div className="mt-6 flex flex-col gap-6">
            <FactorNameTile
              name={result?.factor?.name}
              activityType={result?.factor?.activityType}
              transportMethod={result?.factor?.transportMethod}
            />
            <FactorInfoTable data={result} />
          </div>
        </TabsContent>
        <TabsContent value="source">
          <div className="mt-6 flex flex-col gap-6">
            <FactorSourceTile name={result?.provider?.name} />
            <FactorValuesTable data={result?.values} />
          </div>
        </TabsContent>
        {/* <TabsContent value="calculation">
          <div className="mt-6 flex flex-col gap-6">
            <CalculationTable data={result?.values} parameters={[]} />
          </div>
        </TabsContent> */}
      </Tabs>
    </DialogContent>
  )
}
