import { gql } from "urql";

export const DeleteCSVMutation = gql`
  mutation DeleteCSV($id: String!) {
    deleteCsv(id: $id) {
      ... on DeleteCSVSuccess {
        deleted
      }
      ... on DeleteCSVErrors {
        errors {
          fieldName
          errors
        }
      }
    }
  }
`;
