import * as React from "react"
import {
    ChevronsUpDown,
    KeyIcon,
    LogOutIcon,
    Minus,
    Plus,
    ShieldEllipsisIcon,
} from "lucide-react"

import {
    Avatar,
    AvatarFallback,
} from "@Eikochain/components/ui/avatar"
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
} from "@Eikochain/components/ui/breadcrumb"
import {
    Collapsible,
    CollapsibleContent,
    CollapsibleTrigger,
} from "@Eikochain/components/ui/collapsible"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@Eikochain/components/ui/dropdown-menu"
import { Separator } from "@Eikochain/components/ui/separator"
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarGroup,
    SidebarGroupLabel,
    SidebarHeader,
    SidebarInset,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarMenuSubButton,
    SidebarMenuSubItem,
    SidebarProvider,
    SidebarRail,
    SidebarTrigger,
} from "@Eikochain/components/ui/sidebar"
import Logo from "@Eikochain/components/ui/logo";
import { useAtomValue } from "jotai"
import { userAtom } from "@Eikochain/state/atoms/UserAtom"
import { NAV_OPTIONS } from "@Eikochain/constants"
import useLogout from "@Eikochain/hooks/useLogout"
import { toast } from "sonner"
import { Link, useLocation } from "react-router-dom"
import { BreakcrumbProps } from "@Eikochain/components/ui/workspace-control-bar";

export default function SideNavigation({ children, breadcrumbs }: { children: React.ReactNode, breadcrumbs?: BreakcrumbProps[] }) {
    const location = useLocation();
    const user = useAtomValue(userAtom);
    const logout = useLogout();

    const signOutClicked = () => {
        logout();
        toast.success("See you again soon!")
    }

    return (
        <SidebarProvider>
            <Sidebar>
                <SidebarHeader>
                    <SidebarMenu>
                        <SidebarMenuItem>
                            <SidebarMenuButton
                                size="lg"
                                className="hover:bg-sidebar"
                            >
                                <div className="w-full h-10 flex justify-center bg-sidebar">
                                    <Logo className="h-full w-auto" />
                                </div>
                            </SidebarMenuButton>
                        </SidebarMenuItem>
                    </SidebarMenu>
                </SidebarHeader>
                <SidebarContent>
                    {NAV_OPTIONS.map((item) => (
                        <SidebarGroup
                            key={item.name}
                        >
                            <SidebarGroupLabel>{item.name}</SidebarGroupLabel>
                            <SidebarMenu>
                                {item.children.map((subItem) => (
                                    <SidebarMenuItem key={subItem.name} className={location.pathname == subItem.href ? "bg-sidebar-accent text-sidebar-accent-foreground rounded-md" : "rounded-md"}>
                                        {!subItem.subItems && (<SidebarMenuButton asChild>
                                            <Link to={subItem.href} className="flex gap-2">
                                                < subItem.icon />
                                                <span className="text-md">{subItem.name}</span>
                                            </Link>
                                        </SidebarMenuButton>)}
                                        {subItem.subItems && (
                                            <Collapsible
                                                className="group/collapsible"
                                                defaultOpen={subItem.subItems.some((subItem) => location.pathname == subItem.href)}
                                            >
                                                <CollapsibleTrigger asChild>
                                                    <SidebarMenuButton>
                                                        <div className="flex gap-2 items-center">
                                                            < subItem.icon className="size-4" />
                                                            <span className="text-md">{subItem.name}</span>
                                                        </div>
                                                        <Plus className="ml-auto group-data-[state=open]/collapsible:hidden" />
                                                        <Minus className="ml-auto group-data-[state=closed]/collapsible:hidden" />
                                                    </SidebarMenuButton>
                                                </CollapsibleTrigger>
                                                <CollapsibleContent>
                                                    <SidebarMenuSub>
                                                        {subItem.subItems.map((subItem) => (
                                                            <SidebarMenuSubItem key={subItem.name} className={location.pathname == subItem.href ? "bg-sidebar-accent text-sidebar-accent-foreground rounded-md" : "rounded-md"}>
                                                                <SidebarMenuSubButton asChild>
                                                                    <Link to={subItem.href} className="flex gap-2">
                                                                        <span className="text-md px-2">{subItem.name}</span>
                                                                    </Link>
                                                                </SidebarMenuSubButton>
                                                            </SidebarMenuSubItem>
                                                        ))}
                                                    </SidebarMenuSub>
                                                </CollapsibleContent>
                                            </Collapsible>
                                        )}
                                    </SidebarMenuItem>
                                ))}
                            </SidebarMenu>
                        </SidebarGroup>
                    ))}
                </SidebarContent>
                <SidebarFooter>
                    <SidebarMenu>
                        <SidebarMenuItem>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <SidebarMenuButton
                                        size="lg"
                                        className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                                    >
                                        <Avatar className="h-8 w-8 rounded-lg">
                                            <AvatarFallback className="rounded-lg">
                                                {user?.email.charAt(0).toUpperCase() ?? "U"}
                                            </AvatarFallback>
                                        </Avatar>
                                        <div className="grid flex-1 text-left text-sm leading-tight">
                                            <span className="truncate font-semibold">
                                                {user?.organization.name}
                                            </span>
                                            <span className="truncate text-xs">
                                                {user?.email}
                                            </span>
                                        </div>
                                        <ChevronsUpDown className="ml-auto size-4" />
                                    </SidebarMenuButton>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                                    side="bottom"
                                    align="end"
                                    sideOffset={4}
                                >
                                    <DropdownMenuLabel className="p-0 font-normal">
                                        <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                                            <Avatar className="h-8 w-8 rounded-lg">
                                                <AvatarFallback className="rounded-lg">
                                                    {user?.email.charAt(0).toUpperCase() ?? "U"}
                                                </AvatarFallback>
                                            </Avatar>
                                            <div className="grid flex-1 text-left text-sm leading-tight">
                                                <span className="truncate font-semibold">
                                                    {user?.organization.name}
                                                </span>
                                                <span className="truncate text-xs">
                                                    {user?.email}
                                                </span>
                                            </div>
                                        </div>
                                    </DropdownMenuLabel>
                                    <DropdownMenuSeparator />
                                    <DropdownMenuItem asChild>
                                        <Link to={"/app/ChangePassword"}>
                                            <KeyIcon />
                                            Update password
                                        </Link>
                                    </DropdownMenuItem>
                                    {user?.isStaff && (
                                        <DropdownMenuItem asChild>
                                            <Link to={`${import.meta.env.VITE_DJANGO_API_URL}/admin/`}>
                                                <ShieldEllipsisIcon />
                                                Admin panel
                                            </Link>
                                        </DropdownMenuItem>
                                    )}
                                    <DropdownMenuItem onClick={signOutClicked}>
                                        <LogOutIcon />
                                        Log Out
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </SidebarMenuItem>
                    </SidebarMenu>
                </SidebarFooter>
                <SidebarRail />
            </Sidebar >
            <SidebarInset>
                <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
                    <div className="flex items-center gap-2 px-4">
                        <SidebarTrigger className="-ml-1" />
                        <Separator orientation="vertical" className="mr-2 h-4" />
                        <Breadcrumb>
                            <BreadcrumbList>
                                {breadcrumbs?.map((breadcrumb, index) => (
                                    <React.Fragment key={index}>
                                        <BreadcrumbItem>
                                            <BreadcrumbLink href={breadcrumb.url}>
                                                {breadcrumb.name}
                                            </BreadcrumbLink>
                                        </BreadcrumbItem>
                                        <BreadcrumbSeparator className="hidden md:block" />
                                    </React.Fragment>
                                ))}
                            </BreadcrumbList>
                        </Breadcrumb>
                    </div>
                </header>
                <div className="flex-grow flex flex-col px-4 gap-y-2">
                    {children}
                </div>
            </SidebarInset>
        </SidebarProvider >
    )
}

