import { gql, useQuery } from "urql";

const GetUploadedFileDetail = gql`
  query ($id: String!) {
    uploadById(id: $id) {
      id: djangoId
      csvFile
      status
      activities {
        djangoId
        name
        activityType
        transportMethod
        weight
        route {
          origin {
            region
            countryCode
          }
          destination {
            region
            countryCode
          }
          distance
        }
        mostRecentEmissionCalculation {
          co2e
        }
        client
        dateOfOrder
      }
    }
  }
`;

const useGetUploadedFileDetailById = (id: string) => {
  const result = useQuery({
    query: GetUploadedFileDetail,
    variables: {
      id,
    },
  });
  return result;
};

export default useGetUploadedFileDetailById;
