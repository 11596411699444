import { gql, useQuery } from "urql";

const GetScopedActivitiesByOrganizationIdQuery = gql`
  query {
    scopedActivitiesByOrganizationId {
      djangoId
      createdAt
      name
      position
      activityType
      scope {
        label
        value
      }
      transportMethod
      weight
      energy
      spend
      region
      currency {
        currencyCode
        currencyName
        symbol
      }
      process
      material
      disposalMethod
      fuelType
      serviceType
      goodsType
      route {
        origin {
          region
          countryCode
          lat
          lng
        }
        destination {
          region
          countryCode
          lat
          lng
        }
        distance
      }
      mostRecentEmissionCalculation {
        co2e
      }
      emissionCalculationRange {
        results {
          id
          co2e
          isOriginal
          subject
          subjectType
          source
          sourceDataset {
            name
            url
          }
          region
          year
        }
      }
    }
  }
`;

const useGetScopedActivitiesByOrganizationId = () => {
    const result = useQuery({
        query: GetScopedActivitiesByOrganizationIdQuery,
    });
    return result;
};

export default useGetScopedActivitiesByOrganizationId;
