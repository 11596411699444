import { ControllerRenderProps } from "react-hook-form";
import { FormControl, FormDescription, FormItem, FormLabel, FormMessage } from "./form";
import { Input } from "./input";

interface WeightFormItemProps {
  field: ControllerRenderProps<any, any>;
  description?: string;
}

export default function WeightFormItem({
  field,
  description
}: WeightFormItemProps) {
  return (
    <FormItem>
      <FormLabel>Weight (kg)</FormLabel>
      {
        description && <FormDescription>{description}</FormDescription>
      }
      <FormControl>
        <Input
          type="number" min="0" max="100000" step="0.001" placeholder="e.g. 5"
          {...field}
        />
      </FormControl>
      <FormMessage />
    </FormItem>
  )
}
