import { SupplierInformationRequest } from "@Eikochain/__generated__/graphql";
import { useNavigate } from "react-router-dom";
import DataTable from "../data-table";
import getColumns from "./columns";

interface SuppliersTableProps {
    data: SupplierInformationRequest[];
    isFetching: boolean;
    showRowCount?: boolean;
    hidePaginationButtons?: boolean;
    reexecuteQuery: () => void;
}

export default function SuppliersTable({
    data,
    isFetching,
    showRowCount = true,
    reexecuteQuery
}: SuppliersTableProps) {
    const navigate = useNavigate();
    return (
        <DataTable
            data={data}
            isFetching={isFetching}
            columns={getColumns({ reexecuteQuery })}
            showRowCount={showRowCount}
            showColumnToggler={false}
            hidePaginationButtons={true}
            onRowClick={(values) => {
                navigate(`/app/suppliers/view/${values.id}`)
            }}
        />
    )
}
