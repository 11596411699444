import { EmissionFactorInputParameter } from "@Eikochain/__generated__/graphql";
import { cn } from "@Eikochain/utils";
import { ChevronDownIcon } from "lucide-react";
import { Button } from "../button";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from "../dropdown-menu";

interface SwitcherFieldDropdownProps {
  className?: string;
  fields: EmissionFactorInputParameter[];
  selectedField?: EmissionFactorInputParameter;
  onFieldChange: (field: EmissionFactorInputParameter) => void;
}

export default function SwitcherFieldDropdown({
  className,
  fields,
  selectedField,
  onFieldChange,
}: SwitcherFieldDropdownProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="link" className={cn("ml-auto", className)}>
          Switch field type <ChevronDownIcon className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {fields.map((field, idx) => {
            return (
              <DropdownMenuCheckboxItem
                key={idx}
                className="capitalize"
                checked={field === selectedField}
                onCheckedChange={() => onFieldChange(field)}
              >
                {field.name}
              </DropdownMenuCheckboxItem>
            )
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
