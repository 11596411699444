import MarketingEditorControlCopyLinkButton from "./MarketingEditorControlCopyLinkButton";
import MarketingEditorControlGenerateQRButton from "./MarketingEditorControlGenerateQRButton";
import MarketingEditorControlLogoButton from "./MarketingEditorControlLogoButton";
import MarketingEditorControlMenuStyleButton from "./MarketingEditorControlMenuStyleButton";
import MarketingEditorControlPreviewButton from "./MarketingEditorControlPreviewButton";
import MarketingEditorControlRedoButton from "./MarketingEditorControlRedoButton";
import MarketingEditorControlUndoButton from "./MarketingEditorControlUndoButton";

export default function MarketingEditorControls () {
  return (
    <div className="flex flex-wrap gap-4">
      <MarketingEditorControlMenuStyleButton />
      <MarketingEditorControlLogoButton />
      <MarketingEditorControlPreviewButton />
      <MarketingEditorControlGenerateQRButton disabled={false} />
      <MarketingEditorControlCopyLinkButton />
      <MarketingEditorControlUndoButton />
      <MarketingEditorControlRedoButton />
    </div>
  );
};
