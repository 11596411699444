import Image from "@Eikochain/components/atoms/Image";
import { cn } from "@Eikochain/utils";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const FEATURES = [
  {
    title: "Life cycle assessments",
    description: "Comprehensive yet straightforward LCA's that deliver you results and actionable insights. All sources and calculation values provided.",
    url: "/lca-calculator.webp",
    alt: "Screenshot of the LCA calculator tool"
  },
  {
    title: "Company scope calculator",
    description: "Get a hold on your entire companies operations with fully-scoped and organised emissions.",
    url: "/company-calculator.webp",
    alt: "Screenshot of the company calculator tool"
  },
  {
    title: "Bulk uploads",
    description: "Bulk upload product orders to get emissions for every journey, for any transport method.",
    url: "/shipping-calculator.webp",
    alt: "Screenshot of the bulk uploads functionality"
  },
  {
    title: "Data portability",
    description: "Easily present stakeholders with structured, parseable activities and emissions data.",
    url: "/export-emissions.webp",
    alt: "Screenshot of the exporting data functionality"
  },
]

export default function FeaturesReelPlayer() {
  const [selectedFeature, setSelectedFeature] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedFeature(prevFeature => (prevFeature + 1) % FEATURES.length);
    }, 8000)
    return () => clearInterval(interval);
  }, [selectedFeature]);

  return (
    <div className="flex flex-col lg:flex-row mx-auto justify-center items-center">
      <div className="flex w-full lg:max-w-[600px] flex-row lg:flex-col gap-2 p-4 lg:p-8 py-10 overflow-x-scroll lg:overflow-x-hidden">
        {FEATURES.map((f, index) => (
          <div
            key={index}
            onClick={() => {setSelectedFeature(index)}}
            className={cn(
              "w-full flex flex-col gap-4 p-6 min-w-[300px] text-white hover:bg-emerald-600 transition ease-in-out rounded-xl cursor-pointer",
              selectedFeature === index ? "bg-emerald-700" : "bg-transparent opacity-60"
            )}
          >
            <h1>{f.title}</h1>
            {f.description}
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-4 p-4 lg:p-8 h-full">
        {
          FEATURES.map((f, index) => (
            <motion.div
              key={index}
              initial={{ y: "50%", opacity: 0, scale: 1 }}
              animate={{ y: 0, opacity: 1, scale: 1 }}
              transition={{ delay: 0.2, duration: 0.4 }}
              className={selectedFeature === index ? "flex" : "hidden"}
            >
              <div className="flex justify-center items-center p-4 rounded-lg bg-slate-100/40">
                <div className="flex justify-center items-center p-4 md:p-8 rounded-lg bg-slate-200">
                  <Image
                    src={f.url}
                    alt="Screenshot of the EikoIQ shipping calculator interface"
                    loading="lazy"
                    className={cn(
                      "rounded-lg w-full max-w-[900px] h-full",
                    )}
                  />
                </div>
              </div>
            </motion.div>
          ))
        }
      </div>
    </div>
  )
}
