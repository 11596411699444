
import HandshakeIcon from "@Eikochain/components/svg/handshake-icon";
import EmissionFactorsTableWithData from "@Eikochain/components/ui/emission-factors-table/with-data-wrapper";
import SupplierInformationResponsesTableWithData from "@Eikochain/components/ui/supplier-information-responses-table/with-data-wrapper";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@Eikochain/components/ui/tabs";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { VariableIcon } from "lucide-react";

export default function DataExplorer () {
  const breadcrumbs = [{
    name: "Data Explorer"
  }]

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="w-full p-4">
        <Tabs defaultValue="emission-activities">
          <TabsList>
            <TabsTrigger value="emission-activities"><VariableIcon />Emission activities</TabsTrigger>
            <TabsTrigger value="supplier"><HandshakeIcon />Supplier data</TabsTrigger>
          </TabsList>
          <TabsContent value="emission-activities">
            <EmissionFactorsTableWithData />
          </TabsContent>
          <TabsContent value="supplier">
            <SupplierInformationResponsesTableWithData />
          </TabsContent>
        </Tabs>
      </div>
    </BaseLayout>
  );
};
