import { Control, UseFormSetValue } from "react-hook-form";
import useScript from 'react-script-hook';
import AddressAutocompleteInput from "./address-autocomplete-input";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "./form";
import { Skeleton } from "./skeleton";

type OriginDestinationInputsProps = {
  control: Control<any>,
  setValue: UseFormSetValue<any>
}

export default function OriginDestinationInputs({
  control,
  setValue,
}: OriginDestinationInputsProps) {
  // Load external google maps places script here
  const [loading, error] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}&libraries=places`,
    checkForExisting: true,
  })

  return (
    <div className="flex flex-row gap-1">
      <FormField
        control={control}
        name="origin"
        render={({ field }) => (
          <FormItem className="w-1/2">
            <FormLabel>Origin</FormLabel>
            <FormControl>
              {loading || error ? (
                <Skeleton className="h-[60px] w-1/2" />
              ) : (
                <AddressAutocompleteInput
                  initialValue={field.value?.region ?? ""}
                  onSelect={value => setValue("origin", value)}
                />
              )}
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="destination"
        render={({ field }) => (
          <FormItem className="w-1/2">
            <FormLabel>Destination</FormLabel>
            <FormControl>
              {loading || error ? (
                <Skeleton className="h-[60px] w-1/2" />
              ) : (
                <AddressAutocompleteInput
                  initialValue={field.value?.region ?? ""}
                  onSelect={value => setValue("destination", value)}
                />
              )}
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  )
}
