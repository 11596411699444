import { Activity, EmissionFactorInputParameter, EmissionFactorSource } from "@Eikochain/__generated__/graphql";
import { useMemo } from "react";

export const useAcquireEmissionFactorSourceInputs = (
  sources: EmissionFactorSource[],
  instance?: Activity,
): [EmissionFactorInputParameter[], EmissionFactorInputParameter] => {
  // Hook that takes some input parameters, ensuring there are no useDuplicateSelectedElement, and
  // finds the default to display depending on the instance passed in
  // Returns an array of exactly three items, first item being the deduplicated list of parameters
  // the second being the identified default paramater to show, third being array of region strings
  const allParameters = sources.flatMap(
    (source) => source.parameters as EmissionFactorInputParameter[]
  ) ?? [];

  const deduplicatedParameters = useMemo(() => {
    // Within the deduplicated regions 
    return allParameters.length > 1 ? allParameters.reduce(
      (acc, char) => acc.find(a => a["name"] === char["name"]) ? acc : [...acc, char] as any, []
    ) : allParameters
  }, [allParameters])

  // If activity instance, we should select the default parameter(s) depending on fields entered
  const defaultParameter = useMemo(() => {
    const defaultParameterName = instance?.weight ? "weight" : (
      instance?.energy ? "energy" : (
        instance?.spend ? "spend" : (
          instance?.route?.distance ? "distance" : null
        )
      )
    )
    return defaultParameterName ?
      deduplicatedParameters.find((p) => p.name === defaultParameterName)!
      : deduplicatedParameters[0];
  }, [instance, deduplicatedParameters])
  return [deduplicatedParameters, defaultParameter]
}
