import { DeleteActivityMutation } from "@Eikochain/graphql/hooks/useDeleteActivity";
import { Trash2Icon } from "lucide-react";
import { toast } from "sonner";
import { useMutation } from "urql";
import { Button } from "./button";
import { DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "./dialog";

type DeleteActivityDialogContentProps = {
  activityId: string;
  onFinish: () => void;
}

export default function DeleteActivityDialogContent({
  activityId,
  onFinish
}: DeleteActivityDialogContentProps) {
  const [mutationResult, executeMutation] = useMutation(DeleteActivityMutation);
  function deleteFile() {
    executeMutation({ id: activityId }).then((result) => {
      const { data, error } = result;
      if (error || data.error) {
        toast.error("There was an error deleting this activity");
      } else {
        toast.success("Deleted activity");
        onFinish && onFinish();
      }
    });
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Delete activity?</DialogTitle>
        <DialogDescription>
          Confirming will irreversibly delete the activity and any emissions that were calculated from it
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button
          variant="destructive"
          loading={mutationResult?.fetching}
          icon={<Trash2Icon />}
          onClick={deleteFile}
        >
          Delete
        </Button>
        <DialogClose asChild>
          <Button variant="secondary" loading={mutationResult?.fetching}>Cancel</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  )
}
