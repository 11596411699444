import { gql } from "urql";

export const CreateCustomerLeadMutation = gql`
  mutation CreateCustomerLeadMutation(
    $input: CreateCustomerLeadInput!
  ) {
    createCustomerLead(input: $input) {
      ... on CreateCustomerLeadSuccess {
        sent
      }
      ... on CreateCustomerLeadErrors {
        error
      }
    }
  }
`;
