import { gql } from 'urql';

export const CreateShippingCalculationsFromCsvMutation = gql`
  mutation bulkUploadActivitiesFromCsv(
    $input: BulkUploadActivitiesFromCsvInput!
  ) {
    bulkUploadActivitiesFromCsv(input: $input) {
      ... on BulkUploadActivitiesFromCsvSuccess {
        success
        uploadId
      }
      ... on BulkUploadActivitiesFromCsvErrors {
        errors
      }
    }
  }
`;
