import { EmissionFactorInputParameter, EmissionFactorSource } from "@Eikochain/__generated__/graphql";

export const useAcquireRegionsFromSources = (
  sources: EmissionFactorSource[],
  selectedParameter?: EmissionFactorInputParameter,
): [string[]] => {
  // Hook that takes in some sources, and then finds unique regions from the data source regions.
  // The `selectedParameter` param can be used to filter these sources by their name.
  // Examples of these names be like `weight`, `energy` or `spend`
  let filteredSources = sources;
  if (selectedParameter) {
    filteredSources = filteredSources.filter(
      (source) => source.parameters?.some(parameter => parameter.name === selectedParameter.name)
    )
  }
  const allRegions = filteredSources.map(source => source.dataSource?.region) ?? [];
  const deduplicatedRegions = allRegions ? [...new Set(allRegions)] as string[] : [];

  return [deduplicatedRegions]
}
