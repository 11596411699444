import { Activity, EmissionFactor, EmissionFactorMaterial, EmissionFactorMaterialTag, EmissionFactorSource } from "@Eikochain/__generated__/graphql";
import useCalculateDistanceBetweenPoints from "@Eikochain/graphql/hooks/useCalculateDistanceBetweenPoints";
import useEmissionFactorsByActivityType from "@Eikochain/graphql/hooks/useEmissionFactorsByActivityType";
import { useEffect, useMemo, useState } from "react";
import { Control, SetFieldValue, UseFormResetField, useWatch } from "react-hook-form";
import OriginDestinationInputs from "../../origin-destination-inputs";
import ActivityNameField from "../fields/activity-name-field";
import ActivityTypeField from "../fields/activity-type-field";
import DerivedDistanceField from "../fields/derived-distance-field";
import DisposalMethodField from "../fields/disposal-method-field";
import FuelTypeField from "../fields/fuel-type-field";
import GoodsTypeField from "../fields/goods-type-fields";
import MaterialField from "../fields/material-field";
import ProcessField from "../fields/process-field";
import ScopeField from "../fields/scope-field";
import ServiceTypeField from "../fields/service-type-field";
import TransportMethodField from "../fields/transport-method-field";
import { ActivityTypesAsValueLabels } from "../utils";

interface ActivityAndEmissionFactorFormProps {
  control: Control<any>;
  setValue: SetFieldValue<any>;
  resetField: UseFormResetField<any>;
  activityTypes: ActivityTypesAsValueLabels[];
  instance?: Activity;
  setSourcesAvailable: (sources: EmissionFactorSource[]) => void;
  displayScopeField?: boolean;
}

export default function ActivityAndEmissionFactorForm({
  control,
  setValue,
  resetField,
  activityTypes,
  instance,
  setSourcesAvailable,
  displayScopeField = false,
}: ActivityAndEmissionFactorFormProps) {
  const [processes, setProcesses] = useState<string[]>(instance?.material ? [instance?.material] : []);
  const [materialsCategories, setMaterialCategories] = useState<EmissionFactorMaterialTag[]>([]);
  const activityType = useWatch({control, name: "activityType"})
  const transportMethod = useWatch({control, name: "transportMethod"})
  const process = useWatch({control, name: "process"})
  const material = useWatch({control, name: "material"})
  const origin = useWatch({control, name: "origin"})
  const destination = useWatch({control, name: "destination"})
  const distance = useWatch({control, name: "distance"})
  const fuelType = useWatch({control, name: "fuelType"})
  const serviceType = useWatch({control, name: "serviceType"})
  const goodsType = useWatch({control, name: "goodsType"})
  const disposalMethod = useWatch({control, name: "disposalMethod"})
  const [emissionFactors] = useEmissionFactorsByActivityType(activityType)

  useEffect(() => {
    [
      "transportMethod", "material", "process", "origin", "destination",
      "distance", "fuelType", "serviceType", "goodsType", "disposalMethod",
      "weight", "energy", "spend", "region"
    ].map(f => resetField(f));
  }, [activityType])

  useEffect(() => {
    // If the emissionFactors or the qualifying form data (materials, process, transport, etc)
    // changes then we should update setSourcesAvailable with the sources from the matching
    // emission factor
    if (emissionFactors?.data) {
      const matchingEmissionFactors = emissionFactors?.data?.emissionFactorsByActivityType?.emissionFactors?.filter(
        (ef: EmissionFactor) => (
          ef.activityFields.material == material &&
          ef.activityFields.fuelType == fuelType &&
          ef.activityFields.disposalMethod == disposalMethod &&
          ef.activityFields.process == process &&
          ef.activityFields.transportMethod == transportMethod &&
          ef.activityFields.serviceType == serviceType &&
          ef.activityFields.goodsType == goodsType
        )
      )

      setSourcesAvailable(
        matchingEmissionFactors?.flatMap(
          (matchingEmissionFactor: EmissionFactor) => matchingEmissionFactor.sources
        ) ?? []
      )
    }
  }, [
    emissionFactors.data,
    activityType,
    fuelType,
    disposalMethod,
    transportMethod,
    material,
    process,
    serviceType,
    goodsType
  ])

  const categoriesMaterialsProcesses = useMemo(() => {
    return emissionFactors?.data?.emissionFactorsByActivityType?.categoriesMaterialsProcesses
  }, [emissionFactors?.data])

  useEffect(() => {
    setMaterialCategories(categoriesMaterialsProcesses);
  }, [categoriesMaterialsProcesses])

  useEffect(() => {
    // Based on the material selected, we want to update the processOptions are the default
    if (emissionFactors?.data && material) {
      const processChoices = categoriesMaterialsProcesses?.flatMap(
        (tag: EmissionFactorMaterialTag) => tag.materials?.filter(m => m.name === material)
      )?.map((m: EmissionFactorMaterial) => m.processes).flat();

      setProcesses(processChoices ?? []);
      // If the currently selected process exists in the `processes` array, keep it, otherwise
      // set the field value to be the first in the `processes` array
      const currentProcessInProcesses = processChoices.find((item: string) => item === process);
      if (!currentProcessInProcesses) setValue("process", processChoices?.[0]);
    }
  }, [material]);

  const fuelTypes: {label: string, value: string}[] = emissionFactors?.data?.emissionFactorsByActivityType?.emissionFactors?.map((ef: EmissionFactor) => ({
    value: ef.activityFields.fuelType,
    label: ef.activityFields.fuelType
  })) ?? []

  const serviceTypes: {label: string, value: string}[] = emissionFactors?.data?.emissionFactorsByActivityType?.emissionFactors
    ?.filter((ef: EmissionFactor) => ef.activityFields.serviceType !== null)
    ?.map((ef: EmissionFactor) => ({
      value: ef.activityFields.serviceType,
      label: ef.activityFields.serviceType
    })) ?? []

  const goodsTypes: {label: string, value: string}[] = emissionFactors?.data?.emissionFactorsByActivityType?.emissionFactors
    ?.filter((ef: EmissionFactor) => ef.activityFields.goodsType !== null)
    ?.map((ef: EmissionFactor) => ({
      value: ef.activityFields.goodsType,
      label: ef.activityFields.goodsType
    })) ?? []

  const [distanceResult] = useCalculateDistanceBetweenPoints(
    origin?.lat,
    origin?.lng,
    destination?.lat,
    destination?.lng,
  );
  useEffect(() => {
    if (origin && destination) {
      const distanceValue = distanceResult?.data?.calculateDistanceBetweenPoints?.distance;
      setValue("distance", distanceValue ? Math.ceil(distanceValue) : undefined)
    }
  }, [distanceResult?.data, origin, destination]);

  return (
    <>
      <ActivityNameField control={control} />
      {displayScopeField && <ScopeField control={control} disabled={!!instance?.scope} />}
      <ActivityTypeField
        control={control}
        disabled={!!instance?.activityType}
        activityTypes={activityTypes}
      />
      {activityType === "transport" && (
        <>
          <TransportMethodField control={control} />
          <OriginDestinationInputs control={control} setValue={setValue} />
          <DerivedDistanceField distance={distance} />
        </>
      )}
      {
        ["process", "packaging", "waste"].includes(activityType) && (
          <MaterialField control={control} setValue={setValue} materialsCategories={materialsCategories} />
        )
      }
      {activityType === "process" && (
        <ProcessField control={control} processes={processes} disabled={!material} />
      )}
      {activityType === "fuel" && (
        <FuelTypeField control={control} fuelTypes={fuelTypes} />
      )}
      {activityType === "services" && (
        <ServiceTypeField control={control} setValue={setValue} serviceTypes={serviceTypes} />
      )}
      {activityType === "goods" && (
        <GoodsTypeField control={control} setValue={setValue} goodsTypes={goodsTypes} />
      )}
      {activityType === "waste" && <DisposalMethodField control={control} />}
    </>
  )
}
