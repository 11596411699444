import { elementsForCurrentCanvasAtom } from "@Eikochain/state/atoms/CanvasContent";
import { cn } from "@Eikochain/utils";
import { useAtomValue } from "jotai";
import { memo } from "react";
import CanvasElement from "./CanvasElement";
import CanvasElementAsDraggable from "./CanvasElementAsDraggable";

interface IsMobile {
  isMobile?: boolean;
}

interface ICanvasElementsArea extends IsMobile {
  isEditing: boolean;
}

const CanvasElementsArea = ({
  isEditing,
  isMobile = false,
}: ICanvasElementsArea) => {
  const elements = useAtomValue(elementsForCurrentCanvasAtom);

  return (
    <div className="absolute overflow-hidden w-full h-full">
      {elements?.map((element) => (
        <div className={cn(
          "pointer-events-none",
          isMobile ? "relative" : "absolute"
        )} key={element.localId}>
          {
            isEditing ? (
              <CanvasElementAsDraggable element={element} />
            ) : (
              <CanvasElement element={element} isMobile={isMobile} />
            )
          }
        </div>
      ))}
    </div>
  )
};

export default memo(CanvasElementsArea);
