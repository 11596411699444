
import EmissionFactorsUploadForm from "@Eikochain/components/ui/emission-factors-upload-form";
import Logo from "@Eikochain/components/ui/logo";

export default function UploadEmissionFactorsByDocument() {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="flex flex-col gap-12 items-center max-w-[800px] p-16">
        <div className="flex flex-col items-center gap-2 max-w-[180px]">
          <Logo />
          <h3 className="text-indigo-600 text-center">EMISSIONS UPLOAD PORTAL</h3>
        </div>
        <div className="text-center">
          <p className="text-balance">
            Use this form to upload emission factors via a CSV upload. You can map the headers from your csv to the ones we expect
          </p>
        </div>
        <div className="w-full">
          <EmissionFactorsUploadForm />
        </div>
      </div>
    </div>
  )
}
