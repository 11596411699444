import { gql, useQuery } from 'urql';

const GetHighestEmittingActivitiesQuery = gql`
  query {
    highestEmittingActivities {
      djangoId
      name
      activityType
      transportMethod
      weight
      energy
      spend
      region
      process
      material
      disposalMethod
      fuelType
      serviceType
      goodsType
      route {
        origin {
          region
          countryCode
        }
        destination {
          region
          countryCode
        }
        distance
      }
      mostRecentEmissionCalculation {
        co2e
      }
      client
      dateOfOrder
    }
  }
`;

const useGetHighestEmittingActivities = () => {
  const result = useQuery({
    query: GetHighestEmittingActivitiesQuery,
  })
  return result;
}

export default useGetHighestEmittingActivities;
