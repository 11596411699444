import ResizableMarker, { MarkerPosition, allMarkerPositions } from "@Eikochain/components/atoms/ResizableMarker";
import View from "@Eikochain/components/atoms/View";
import useEditSelectedElement from "@Eikochain/hooks/MarketingEditor/useEditSelectedElement";
import { selectedElementIdAtom } from "@Eikochain/state/atoms/CanvasContent";
import { defaultBoxShadow, whiteColor } from "@Eikochain/themes/constants";
import { DragEndEvent, useDndMonitor, useDraggable } from "@dnd-kit/core";
import { CSS, Coordinates } from "@dnd-kit/utilities";
import { useAtomValue } from "jotai";
import { CSSProperties, MouseEvent, memo, useEffect, useMemo, useRef, useState } from "react";
import { ICanvasElement } from "../types";
import renderContentItemFromType from "./RenderItem";

interface CanvasElementAsDraggableProps {
  element: ICanvasElement;
}

const CanvasElementAsDraggable = ({
  element,
}: CanvasElementAsDraggableProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const resizingMarkerPositionSelected = useRef<MarkerPosition | null>(null);
  const [{x, y}, setCoordinates] = useState<Coordinates>({x: element.x, y: element.y});
  const [{width, height}, setSize] = useState({width: element.width, height: element.height});

  const selectedElement = useAtomValue(selectedElementIdAtom);
  const editFn = useEditSelectedElement();
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
  } = useDraggable({
    id: element.localId,
  });

  function onMouseDownResizableMarker (position: MarkerPosition) {
    resizingMarkerPositionSelected.current = position;
  }

  useDndMonitor({
    onDragEnd(event: DragEndEvent) {
      if (event.active.id === element.localId) {
        // Set coordinate to current + the change
        const newCoordinates = {x: x + event.delta.x, y: y + event.delta.y};
        setCoordinates(newCoordinates);
        editFn({ newX: newCoordinates.x, newY: newCoordinates.y});
      }
    },
  });

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper && document.defaultView) {
      const resizingMarkers = allMarkerPositions.map(markerPosition => wrapper.querySelector(`.${markerPosition}`));

      const handleMouseDown: EventListener = (event: Event) => {
        const mouseEvent = event as unknown as MouseEvent<HTMLElement>;
        mouseEvent.preventDefault();
        const startWidth = parseInt((document.defaultView as Window).getComputedStyle(wrapper).width, 10);
        const startHeight = parseInt((document.defaultView as Window).getComputedStyle(wrapper).height, 10);
        const startX = mouseEvent.pageX;
        const startY = mouseEvent.pageY;

        const handleMouseMove: EventListener = (event: Event) => {
          const mouseEvenet = event as unknown as MouseEvent<HTMLElement>;
          const deltaX = mouseEvenet.pageX - startX;
          const deltaY = mouseEvenet.pageY - startY;
          let newWidth = startWidth;
          let newHeight = startHeight;
          let newX = x;
          let newY = y;

          // Snap to a 12px grid
          const snapX = Math.round(deltaX / 12) * 12;
          const snapY = Math.round(deltaY / 12) * 12;

          if (resizingMarkerPositionSelected.current?.startsWith("top")) {
            newHeight -= snapY;
            newY = (newY + snapY >= 0) ? newY + snapY : 0;
          } else if (resizingMarkerPositionSelected.current?.startsWith("bottom")) {
            newHeight += snapY;
          }

          if (resizingMarkerPositionSelected.current?.endsWith("left")) {
            newWidth -= snapX;
            newX = (newX + snapX >= 0) ? newX + snapX : 0;
          } else if (resizingMarkerPositionSelected.current?.endsWith("right")) {
            newWidth += snapX;
          }

          setSize({ width: newWidth, height: newHeight })
          setCoordinates({ x: newX, y: newY });
          // TODO: Potentially refactor this in future so we aren't updating Jotai
          // state on every mouse movement
          editFn({ newX, newY, newWidth, newHeight });
        };

        const handleMouseUp: EventListener = () => {
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
      };

      resizingMarkers.forEach(
        resizingMarker => resizingMarker?.addEventListener('mousedown', handleMouseDown)
      )

      return () => {
        resizingMarkers.forEach(
          resizingMarker => resizingMarker?.removeEventListener('mousedown', handleMouseDown)
        )
      };
    }
  }, [x, y, width, height]);

  const style = {
    display: "inline-flex",
    position: "relative",
    background: whiteColor,
    boxShadow: isDragging ? defaultBoxShadow : "none",
    transform: CSS.Transform.toString(transform),
    transition: "200ms box-shadow ease-in-out",
    pointerEvents: "all",
    cursor: "grab"
  } as CSSProperties;

  const MemoElementContainer = useMemo(() => {
    // We memo this component so that all the changes to Jotai state don't force a
    // rerender for an element, except for the specified dependencies
    return renderContentItemFromType(element.type, element.content)
  }, [element.type, element.content])

  const MemoResizableMarkers = useMemo(() => {
    // We memo this component so that all the changes to Jotai state don't force a
    // rerender for an element, except for the specified dependencies
    return selectedElement === element.localId && (
      <div className="absolute top-0 w-full h-full flex justify-between items-center">
        {allMarkerPositions.map((markerPosition) => 
          <ResizableMarker key={markerPosition} position={markerPosition} onMouseDown={onMouseDownResizableMarker} />
        )}
      </div>
    )
  }, [element.localId, selectedElement])

  return (
    <View
      ref={setNodeRef}
      style={
        {
          ...style,
          top: y,
          left: x,
          width,
          height
        } as CSSProperties
      }
      {...listeners}
      {...attributes}
    >
      <div
        ref={wrapperRef}
        style={{
          position: "absolute",
          width,
          height,
          border: "1px solid black"
        }}
      >
        {MemoElementContainer}
        {MemoResizableMarkers}
      </div>
    </View>
  );
};

export default memo(CanvasElementAsDraggable);
