import { DeleteProductMutation } from "@Eikochain/graphql/hooks/useDeleteProduct";
import useGetProducts from "@Eikochain/graphql/hooks/useGetProducts";
import { Trash2Icon } from "lucide-react";
import { toast } from "sonner";
import { useMutation } from "urql";
import { Button } from "../button";
import { DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../dialog";

type DeleteProductDialogContent = {
  productId: string;
  onFinish: () => void;
}

export default function DeleteProductDialogContent({
  productId,
  onFinish
}: DeleteProductDialogContent) {
  const [result, reexecuteQuery] = useGetProducts();
  const [mutationResult, executeMutation] = useMutation(DeleteProductMutation)
  function deleteProduct() {
    executeMutation({ id: productId }).then((result) => {
      const { data, error } = result;
      if (error) {
        toast.error("There was an error removing this product");
      } else {
        toast.success("Removed product");
        reexecuteQuery({ requestPolicy: "network-only" });
        onFinish()
      }
    });
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Delete product?</DialogTitle>
        <DialogDescription>
          Confirming will irreversibly delete the product and any activities and emissions associated with it
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <Button
          variant="destructive"
          loading={mutationResult?.fetching}
          icon={<Trash2Icon />}
          onClick={deleteProduct}
        >
          Delete
        </Button>
        <DialogClose asChild>
          <Button variant="secondary" loading={mutationResult?.fetching}>Cancel</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  )
}
