import Image from "@Eikochain/components/atoms/Image/Image";
import Logo from "@Eikochain/components/ui/logo";
import { logoUrlAtom } from "@Eikochain/state/atoms/CanvasMenuStyles";
import { useAtomValue } from "jotai";

interface PanelLogoProps {
  maxWidth?: number;
}

const PanelLogo = ({
  maxWidth = 300
}) => {
  const logoUrl = useAtomValue(logoUrlAtom)
  return (
    <div style={{ padding: 12, maxWidth }}>
      {
        !logoUrl
        ? <Logo />
        : <Image src={logoUrl} width="100%" />
      }
    </div>
  )
};

export default PanelLogo;
