import Spacer from "@Eikochain/components/atoms/Spacer";
import { MarketingPanelMeta } from "@Eikochain/components/organisms/MarketingEditor/types";
import useSetJotaiStateForMarketingPanel from "@Eikochain/hooks/MarketingEditor/useSetJotaiStateForMarketingPanel";
import { Form } from "antd";
import omitDeep from "omit-deep-lodash";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "urql";
import { CreateOrUpdatePanelByIdMutation } from "../../graphql/hooks/useCreateOrUpdatePanelById";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import MarketingEditor from "./MarketingEditor/MarketingEditor";

interface CreateOrUpdateMarketingEditorFormProps {
  instance?: MarketingPanelMeta;
}

const CreateOrUpdateMarketingEditorForm = ({ instance }: CreateOrUpdateMarketingEditorFormProps) => {
  // Use hook to set the Jotai state for the current marketing editor
  useSetJotaiStateForMarketingPanel(instance?.panel);
  const [form] = Form.useForm();

  const [result, executeMutation] = useMutation(CreateOrUpdatePanelByIdMutation);
  const onFinish = (values: any) => {
    const valuesWithId = omitDeep({ id: instance?.id, ...values }, "__typename");
    executeMutation({ input: valuesWithId }).then((result) => {
      const { data, error } = result;
      if (error) {
        console.log(error);
      } else {
        console.log("success");
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (instance) form.setFieldValue("name", instance.name);
  }, [instance]);

  return (
    <Form
      form={form}
      name="CreateOrUpdateMarketingEditorForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      colon={false}
    >
      <Form.Item label="Name" name="name" rules={[{ required: true, message: "Please add a title" }]}>
        <Input placeholder="Enter a canvas name here" />
      </Form.Item>
      <Form.Item
        label="Canvas display"
        name="panel"
        rules={[{ required: true, message: "Add content to your canvas before saving!" }]}
      >
        <MarketingEditor onChange={(panelValue) => form.setFieldValue("panel", panelValue)} />
      </Form.Item>
      <Spacer height={4} />
      <Form.Item>
        <div className="flex justify-between">
          <div className="flex">
            <Button type="submit">Save canvas</Button>
          </div>
          <div className="flex">
            <Spacer width={1} />
            <Link to="/app/supply-chains">
              <Button variant="destructive">
                Cancel progress
              </Button>
            </Link>
          </div>
        </div>
      </Form.Item>
    </Form>
  );
};

export default CreateOrUpdateMarketingEditorForm;
