import { Button } from "@Eikochain/components/ui/button";
import { Paintbrush2Icon } from "lucide-react";
import { useState } from "react";
import CanvasMenuStyleModal from "../Modals/CanvasMenuStyleModal/CanvasMenuStyleModal";

const MarketingEditorControlMenuStyleButton = () => {
  const [openMenuStyleModal, setOpenMenuStyleModal] = useState(false);
  return (
    <>
      <Button onClick={() => setOpenMenuStyleModal(true)} icon={<Paintbrush2Icon />}>
        Edit menu style
      </Button>
      <CanvasMenuStyleModal
        open={openMenuStyleModal}
        onCancel={() => setOpenMenuStyleModal(false)}
        onOk={() => setOpenMenuStyleModal(false)}
      />
    </>
  );
};

export default MarketingEditorControlMenuStyleButton;
