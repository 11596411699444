import { ScopeType } from "@Eikochain/__generated__/graphql";
import { cn } from "@Eikochain/utils";
import { Badge } from "./badge";

const scopeStyles: any = {
  "scope_1": "bg-green-100 text-green-900",
  "scope_2": "bg-blue-100 text-blue-900",
  "scope_3": "bg-yellow-100 text-yellow-900",
}

interface ScopeBadgeProps {
  scope: ScopeType
}

export default function ScopeBadge({
  scope
}: ScopeBadgeProps) {
  return (
    <Badge className={cn("font-semibold whitespace-nowrap", scopeStyles[scope.value])}>{scope.label}</Badge>
  )
}
