import Logo from "../logo";

export default function Footer() {
  return (
    <footer>
      <div className="flex flex-row justify-between items-center mx-auto py-24 px-8 max-w-[1000px]">
        <div className="flex h-12">
          <Logo />
        </div>
        <span>Copyright {new Date().getFullYear()}</span>
      </div>
    </footer>
  )
}
