import { useAtom } from "jotai";
import { elementsForCurrentCanvasAtom, selectedElementIdAtom } from "@Eikochain/state/atoms/CanvasContent";
import { ICanvasElement } from "@Eikochain/components/organisms/MarketingEditor/types";

const useDuplicateSelectedElement = () => {
  const [elements, setElements] = useAtom(elementsForCurrentCanvasAtom);
  const [
    selectedElement,
    setSelectedElement
  ] = useAtom(selectedElementIdAtom);

  function execute(variables = {}) {
    const elementIndex = elements.findIndex(el => el.localId === selectedElement);
    const element = elements[elementIndex];
    // Always expecting `elements` to have a length since we're duplicating an existing element
    // but this is more of a sanity check
    const localId = elements?.length
      ? Math.max(...elements.map(item => item.localId)) + 1
      : 1;
    const elementToDuplicate: ICanvasElement = {
      ...element,
      serverId: null,
      localId,
      // Add to the x and y so the duplicated element doesn't completely cover the
      // element it's duplicating. Add 12 because 12 is the grid size, so it should jump
      // by one cell horizontally and vertically
      x: element.x + 12,
      y: element.y + 12,
    }
    setElements([
      ...elements,
      elementToDuplicate,
    ]);
    setSelectedElement(localId);
  }

  return execute;
};

export default useDuplicateSelectedElement;
