import { gql, } from 'urql';

export const GetInformationRequestTemplatesQuery = gql`
    query {
        supplierInformationRequestTemplates {
            id
            name
            steps {
                stepNo
                title
                description
                measurement
                inputType
            }
        }
    }
`;

