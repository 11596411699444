import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { currentPanelAtom } from './../../state/atoms/CanvasContent';
import { elementsForCurrentCanvasAtom, selectedElementIdAtom } from "@Eikochain/state/atoms/CanvasContent";
import { ICanvasElement } from "@Eikochain/components/organisms/MarketingEditor/types";

const useCreateElement = () => {
  const panel = useAtomValue(currentPanelAtom);
  const [elements, setElements] = useAtom(elementsForCurrentCanvasAtom);
  const setSelectedElement = useSetAtom(selectedElementIdAtom);

  function execute(newElementAttributes: any) {
    // Capture the localId across all elements (including those marked as deleted), and increment it by 1.
    const fromAllInstances = panel?.canvases?.flatMap((canvas) => canvas.elements?.map(element => element.localId));
    const localId = fromAllInstances?.length
      ? Math.max(...fromAllInstances) + 1
      : 1;
    const createdElement: ICanvasElement = {
      serverId: null, // Hasn't been committed to the server here yet.
      localId,
      content: newElementAttributes.content,
      type: newElementAttributes.type,
      width: newElementAttributes.width || 100,
      height: newElementAttributes.height || 100,
      x: newElementAttributes.x || 0,
      y: newElementAttributes.y || 0
    };
    setElements([
      ...elements,
      createdElement,
    ]);
    setSelectedElement(localId);
  }

  return execute;
};

export default useCreateElement;
