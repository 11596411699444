import { CreateSupplierMutation } from "@Eikochain/graphql/hooks/mutations/create-supplier"
import { CreateSupplierInput, CreateSupplierStatus } from "@Eikochain/__generated__/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { PlusIcon, Trash } from "lucide-react"
import { FC } from "react"
import { Control, Controller, useFieldArray, UseFieldArrayReturn, useForm } from "react-hook-form"
import { toast } from "sonner"
import { useMutation } from "urql"
import { z } from "zod"
import { Button } from "../button"
import { Form, FormField } from "../form"
import { Input } from "../input"

interface AddSupplierFormProps {
    onSuccess: () => void;
}

const addSupplierFormSchema = z.object({
    supplierName: z.string().min(1, "Supplier name required"),
    contacts: z.array(z.object({
        name: z.string().min(1, "Name required"),
        email: z.string().email("Must be a valid email").min(1, "Email required"),
    })).min(1)
})

type FormType = z.infer<typeof addSupplierFormSchema>


const ContactField: FC<{ control: Control<FormType, any>, index: number, fieldArray: UseFieldArrayReturn<FormType, "contacts"> }> = ({ control, index, fieldArray }) => (
    <div className="flex gap-2 w-full">
        <div className="grid grid-cols-2 gap-2 flex-grow">
            <Controller
                control={control}
                name={`contacts.${index}.name`}
                render={({ field, fieldState }) => (
                    <div>
                        <Input className={fieldState.error && "border-red-500"} placeholder="e.g. John Carr" {...field} />
                        <p className="text-sm text-red-500">{fieldState.error?.message}</p>
                    </div>
                )}
            />
            <Controller
                control={control}
                name={`contacts.${index}.email`}
                render={({ field, fieldState }) => (
                    <div>
                        <Input className={fieldState.error && "border-red-500"} placeholder="e.g. john@acme.org" {...field} />
                        <p className="text-sm text-red-500">{fieldState.error?.message}</p>
                    </div>
                )}
            />
        </div>
        <div>
            <Button
                onClick={() => fieldArray.remove(index)}
                variant="destructive"
                type="button"
                className="aspect-square p-2"
            >
                <Trash className="w-4 h-4" />
            </Button>
        </div>
    </div>
)


export default function AddSupplierForm({
    onSuccess
}: AddSupplierFormProps) {
    const [mutationResult, executeMutation] = useMutation(CreateSupplierMutation);
    const form = useForm<FormType>({
        resolver: zodResolver(addSupplierFormSchema),
        defaultValues: {
            supplierName: "",
            contacts: [{
                name: "",
                email: ""
            }]
        }
    })

    const contactsFieldArray = useFieldArray({ name: "contacts", control: form.control });

    const onSubmit = async (values: FormType) => {
        const result = await executeMutation({
            input: {
                supplierName: values.supplierName,
                supplierContacts: values.contacts
            } satisfies CreateSupplierInput
        })

        const data: CreateSupplierStatus = result.data?.createSupplier

        if (data.success) {
            toast.success(`Added ${values.supplierName} as a supplier`)
            onSuccess()
            return
        }

        toast.error(data.reason ?? "An error occurred")
    }

    return (
        <Form {...form}>
            <form
                className="space-y-6 flex flex-col gap-2"
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <FormField
                    control={form.control}
                    name="supplierName"
                    render={({ field, fieldState }) => (
                        <div className="pt-4">
                            <p className="text-lg font-semibold">Supplier company name</p>
                            <Input className={fieldState.error && "border-red-500"} placeholder="e.g. Acme Ltd" {...field} />
                            <p className="text-sm text-red-500">{fieldState.error?.message}</p>
                        </div>
                    )}
                />
                <div>
                    <p className="text-lg font-bold">Contacts</p>
                    <Controller
                        control={form.control}
                        name="contacts"
                        render={({ field }) => (
                            <div className="flex flex-col gap-2 w-full overflow-y-auto min-h-[100px] max-h-[200px] p-1">
                                {field.value.map((_, index) => <ContactField key={index} control={form.control} index={index} fieldArray={contactsFieldArray} />)}
                                <p className="text-sm text-red-500">{field.value.length < 1 && "At least one contact required"}</p>
                            </div>
                        )}
                    />
                </div>
                <div className="flex flex-row gap-2 justify-center w-full">
                    <Button
                        onClick={() => contactsFieldArray.append({ name: "", email: "" })}
                        variant="outline"
                        type="button"
                        className="gap-2 flex"
                    >
                        <PlusIcon className="w-4 h-4" />
                        <span>Add contact</span>
                    </Button>
                    <Button type="submit" className="w-1/3" loading={mutationResult?.fetching}>Create supplier</Button>
                </div>
            </form>
        </Form >
    )
}
