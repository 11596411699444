import { scopeOptions } from "@Eikochain/types";
import { Control } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../select";

interface ScopeFieldProps {
  control: Control<any>;
  disabled: boolean;
}

export default function ScopeField({
  control,
  disabled = false,
}: ScopeFieldProps) {
  return (
    <FormField
      control={control}
      name="scope"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Scope</FormLabel>
          <FormDescription>
            Scopes are used to categorise activities and see where reductions can be made.&nbsp;
            <Link to="https://www.nationalgrid.com/stories/energy-explained/what-are-scope-1-2-3-carbon-emissions" target="_blank" className="text-emerald-600 inline-flex">
              Click here to learn more
            </Link>
          </FormDescription>
          <Select
            value={field.value}
            onValueChange={field.onChange}
            disabled={disabled}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder="Scope" />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {scopeOptions.map(so => (
                <SelectItem key={so.value} value={so.value}>{so.label}</SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
