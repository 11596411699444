import { gql, } from 'urql';

export const GetSupplierByIdQuery = gql`
  query($id: String!) {
    supplierById(id: $id) {
      id
      name
    }
  }
`;

