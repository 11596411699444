import { gql, useQuery } from 'urql';

const GetTotalCompanyEmissionsQuery = gql`
  query {
    totalCompanyEmissions
  }
`;

const useGetTotalCompanyEmissions = () => {
  const result = useQuery({
    query: GetTotalCompanyEmissionsQuery,
  })
  return result;
}

export default useGetTotalCompanyEmissions;
