import { gql, useMutation } from 'urql';

export const BulkCreateProductsMutation = gql`
  mutation BulkCreateProducts($input: BulkCreateProductsInput!) {
    bulkCreateProducts(input: $input) {
      ... on BulkCreateProductsSuccess {
        success
      }
      ... on BulkCreateProductsErrors {
        errors
      }
    }
  }
`;
