import useGetSupplierInformationResponses from "@Eikochain/graphql/hooks/useGetSupplierInformationResponses";
import SupplierInformationResponsesTable from ".";

export default function SupplierInformationResponsesTableWithData({ small }: { small?: boolean }) {
    const [result] = useGetSupplierInformationResponses();
    return (
        <SupplierInformationResponsesTable
            small={small}
            data={result?.data?.supplierInformationResponses ?? []}
            isFetching={result.fetching}
            showRowCount={true}
        />
    )
}
