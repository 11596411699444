import { Badge } from "../ui/badge";

export type Statuses = "success" | "pending" | "failure";

interface StatusBadgeProps {
  status: Statuses
}

export default function StatusBadge({ status }: StatusBadgeProps) {
  const statusStyling = (status: Statuses) => {
    const styles = {
      success: "bg-green-50 text-green-600 hover:bg-green-100",
      pending: "bg-yellow-50 text-yellow-600 hover:bg-yellow-100",
      failure: "bg-red-50 text-red-600 hover:bg-red-100",
    };
    return styles[status];
  };

  return (
    <Badge className={statusStyling(status)}>{status}</Badge>
  )
}
