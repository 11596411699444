
import AddSupplierInformationRequestButton from "@Eikochain/components/ui/add-supplier-information-request-button";
import SupplierInformationRequestsTable from "@Eikochain/components/ui/suppliers-information-requests-table";
import { useGetSupplierInformationRequests } from "@Eikochain/graphql/hooks/useGetSupplierInformationRequests";
import BaseLayout from "@Eikochain/layouts/BaseLayout";

export default function SupplierInformationRequests() {
    const [result, refetch] = useGetSupplierInformationRequests();
    const breadcrumbs = [
        {
            name: "Suppliers",
            url: "/app/suppliers/"
        },
        {
            name: "Supplier information requests"
        }
    ]

    return (
        <BaseLayout breadcrumbs={breadcrumbs}>
            <div className="flex flex-row gap-4 justify-end w-full py-4">
                <AddSupplierInformationRequestButton />
            </div>
            <div className="w-full px-4 py-16">
                <SupplierInformationRequestsTable
                    refetch={() => refetch({ requestPolicy: 'network-only' })}
                    data={result?.data?.supplierInformationRequests ?? []} isFetching={result.fetching}
                />
            </div>
        </BaseLayout>
    )
}
