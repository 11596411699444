import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@Eikochain/components/ui/tooltip";
import { ActivityTypeOption } from "@Eikochain/types";
import { cn } from "@Eikochain/utils";
import { AlertTriangle, PlusCircleIcon } from "lucide-react";
import CreateOrUpdateActivityModal from "../create-update-activity-dialog";
import { Dialog, DialogTrigger } from "../dialog";

interface ICarbonActivityStepSuggested {
  productId: string;
  activityTypeLabel?: string;
  activityType: ActivityTypeOption;
  onActivityCreated: () => void;
  alertType: "required" | "suggested";
}

export default function ActivityStepAlert ({
  productId,
  activityTypeLabel,
  activityType,
  onActivityCreated,
  alertType
}: ICarbonActivityStepSuggested) {
  return (
    <div className={cn(
      "w-full flex rounded-2xl p-2 lg:p-4 shadow-md",
      alertType === "required" && "bg-red-50 border-red-200",
      alertType === "suggested" && "bg-yellow-50 border-yellow-200",
    )}>
      <AlertTriangle className={cn(
        alertType === "required" && "text-red-500",
        alertType === "suggested" && "text-yellow-500",
      )} />
      <p className="flex flex-row justify-start pl-4 text-sm md:text-base text-black w-full">
        {alertType === "suggested" ? "Suggested" : "Required"}: at least one {activityTypeLabel ?? activityType} activity
      </p>
      <Dialog>
        <DialogTrigger>
          <div className="flex flex-row justify-end">
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <PlusCircleIcon />
                </TooltipTrigger>
                <TooltipContent>Add activity details</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </DialogTrigger>
        <CreateOrUpdateActivityModal
          productId={productId}
          defaultActivityType={activityType}
          onFinish={onActivityCreated}
        />
      </Dialog>
    </div>
  );
};
