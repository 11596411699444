import { gql } from 'urql';

export const CreateSupplierInformationResponseMutation = gql`
  mutation CreateSupplierInformationResponse($input: CreateSupplierInformationResponseInput!) {
    createSupplierInformationResponse(input: $input) {
        success
        reason
    }
  }
`;
