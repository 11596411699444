import useGetScopedActivitiesByOrganizationId from "@Eikochain/graphql/hooks/useGetScopedActivitiesByOrganizationId";
import { PlusIcon } from "lucide-react";
import { useState } from "react";
import H3 from "../atoms/H3";
import Paragraph from "../atoms/P";
import Spacer from "../atoms/Spacer";
import ActivityStepsForOrganization from "./activity-steps/activity-steps-for-organization";
import { Button } from "./button";
import CreateOrUpdateActivityModal from "./create-update-activity-dialog";
import { Dialog, DialogTrigger } from "./dialog";

export default function ScopedActivityStepsSection () {
  const [openModal, setOpenModal] = useState(false);
  const [_, executeQuery] = useGetScopedActivitiesByOrganizationId();
  return (
    <div className="flex flex-col gap-2 min-h-full">
      <div className="flex flex-row justify-between gap-4 w-full">
        <div className="flex flex-col w-full">
          <H3>Activities</H3>
          <Paragraph className="hidden md:flex">
            Add your emissions data here such as fuel used, logistics data or product information
          </Paragraph>
        </div>
        <div className="flex items-center gap-4">
          <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogTrigger asChild>
              <Button icon={<PlusIcon />} variant="fun">
                Add activity
              </Button>
            </DialogTrigger>
            <CreateOrUpdateActivityModal
              activityTypes="company"
              onFinish={() => {
                setOpenModal(false);
                executeQuery()
              }}
            />
          </Dialog>
        </div>
      </div>
      <Spacer height={2} />
      <ActivityStepsForOrganization />
    </div>
  );
};
