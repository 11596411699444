import { PlusIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "./button";

interface AddSupplierInformationRequestButtonProps {
    supplierId?: string;
}

export default function AddSupplierInformationRequestButton({ supplierId }: AddSupplierInformationRequestButtonProps) {
    return (
        <Link to={`/app/suppliers/requests/create/${supplierId ?? ""}`} >
            <Button variant="fun" icon={<PlusIcon />}>
                Request data from a supplier
            </Button>
        </Link >
    )
}
