import { getAuthToken } from "@Eikochain/auth";
import PasswordAuthenticationForm from "@Eikochain/components/ui/password-authentication-form";
import CoreLayout from "@Eikochain/layouts/CoreLayout";
import { userAtom } from "@Eikochain/state/atoms/UserAtom";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Authenticate() {
    const navigate = useNavigate();
    const [user, setUser] = useAtom(userAtom);

    useEffect(() => {
        const authToken = getAuthToken();

        if (user && !authToken) {
            setUser(null);
        }

        if (user && authToken) {
            navigate("/app/products/");
        }

        // TODO: Issue is that the useAtom hook returns null at the beggining so we cannot reliably check whether or not
        // both user and authToken are null.
    }, [user])

    return (
        <CoreLayout title="Sign in to the EikoIQ app">
            <div className="h-screen w-screen flex">
                <div className="hidden lg:block lg:w-1/3 bg-indigo-300 h-full" />
                <div className="w-full lg:w-2/3 flex flex-col gap-6 p-2 justify-center items-center">
                    <h1>Sign in to EikoIQ</h1>
                    <PasswordAuthenticationForm />
                </div>
            </div>
        </CoreLayout>
    );
};
