import { gql, useQuery } from 'urql';

const GetSupplierInformationResponsesQuery = gql`
  query {
    supplierInformationResponses {
      id
      email
      name
      steps {
        response
        stepNo
        title
        description
        measurement
      }
      supplierInformationRequest {
        id
        requestType
        additionalInformation
        supplier { 
            id
            name
        }
      }
    }
  }
`;

const useGetSupplierInformationResponses = () => {
    const result = useQuery({
        query: GetSupplierInformationResponsesQuery,
    })
    return result;
}

export default useGetSupplierInformationResponses;
