import { ProductToIntegrate } from "@Eikochain/__generated__/graphql"
import DataTable from "../data-table"
import columns from "./columns";

interface ProductsTableProps {
  data: ProductToIntegrate[];
  isFetching: boolean;
  onRowChange: (selectedRows: any[]) => void
}

export default function ImportProductsFromIntegrationTable({
  data,
  isFetching,
  onRowChange,
}: ProductsTableProps) {
  return (
    <DataTable
      data={data}
      isFetching={isFetching}
      columns={columns}
      showRowCount={false}
      showColumnToggler={false}
      searchBy="name"
      onRowChange={onRowChange}
      enableRowSelection={row => !row.original.id}
    />
  )
}
