import { Button } from "@Eikochain/components/ui/button";
import { MonitorSmartphoneIcon } from "lucide-react";
import { useState } from "react";
import PreviewMarketingPanelModal from "../Modals/PreviewMarketingPanelModal";

const MarketingEditorControlPreviewButton = () => {
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const previewOpen = () => {
    setPreviewModalOpen(true);
  };
  const previewClose = () => {
    setPreviewModalOpen(false);
  };
  return (
    <>
      <Button onClick={previewOpen} icon={<MonitorSmartphoneIcon />}>
        Preview
      </Button>
      <PreviewMarketingPanelModal open={previewModalOpen} onClose={previewClose} />
    </>
  );
};

export default MarketingEditorControlPreviewButton;
