import { gql, useQuery } from 'urql';

const GetFactorForEmissionResultQuery = gql`
  query ($id: String!) {
    factorSourceForEmissionResult(id: $id) {
      provider {
        name
      }
      factor {
        name
        activityType
        transportMethod
        activityFields {
          activityType
          transportMethod
          material
          process
          fuelType
          serviceType
          goodsType
          disposalMethod
          distanceGte
          distanceLte
          distanceGt
          distanceLt
        }
      }
      values {
        calculationUnitType {
          label
          value
          units
        }
        co2eTotal
        co2
        ch4
        n2o
      }
    }
  }
`;

const useGetFactorForEmissionResult = (emissionResultId: string) => {
  const result = useQuery({
    query: GetFactorForEmissionResultQuery,
    variables: {
      id: emissionResultId
    }
  })
  return result;
}

export default useGetFactorForEmissionResult;
