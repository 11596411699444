import { Control } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../select";

interface DisposalMethodFieldProps {
  control: Control<any>;
}

export default function DisposalMethodField({
  control
}: DisposalMethodFieldProps) {
  return (
    <FormField
      control={control}
      name="disposalMethod"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Method of disposal</FormLabel>
          <Select
            value={field.value}
            onValueChange={field.onChange}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder="The method of disposal" />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              <SelectItem value="Recycled">Recycled</SelectItem>
              <SelectItem value="Landfill">Landfill</SelectItem>
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
