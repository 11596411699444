import { useAtom } from "jotai";
import { canvasMenuStepsAtom } from "@Eikochain/state/atoms/CanvasMenuContent";
import { currentPanelAtom } from "@Eikochain/state/atoms/CanvasContent";

const useDeleteMenuStep = () => {
  const [panel, setPanel] = useAtom(currentPanelAtom);
  const [steps, setSteps] = useAtom(canvasMenuStepsAtom);

  function execute(stepLocalId: number) {
    const canvases = panel?.canvases;
    // Early return if canvases is undefined (but an empty array is ok)
    if (!canvases) return;
    // Marks the step as deleted, but doesn't remove it from the array
    // Also marks the corresponding canvas as deleted if it exists
    const stepIndex = steps.findIndex(step => step.localId === stepLocalId);
    const step = steps[stepIndex];
    const correspondingCanvasIndex = canvases.findIndex(canvas => canvas.localId === step.localId);
    setSteps([
      ...steps.slice(0, stepIndex),
      ...steps.slice(stepIndex + 1),
    ]);
    setPanel({
      ...panel,
      canvases: [
        ...canvases.slice(0, correspondingCanvasIndex),
        ...canvases.slice(correspondingCanvasIndex + 1),
      ]
    })
  }

  return execute;
};
  
export default useDeleteMenuStep;
