import Image from "@Eikochain/components/atoms/Image";
import Marquee from "../marquee";

const dataProviders = [
  {
    name: "EPA",
    alt: "Environmental Protection Agency (EPA) logo",
    url: "/epa-logo.webp"
  },
  {
    name: "BEIS",
    alt: "Department for Business, Energy and Industrial Strategy (BEIS) logo",
    url: "/beis-logo.webp"
  },
  {
    name: "Ecoinvent",
    alt: "Ecoinvent logo",
    url: "/ecoinvent-logo.webp"
  },
  {
    name: "Climate Trace",
    alt: "Climate Trace logo",
    url: "/climate-trace-logo.webp"
  },
  {
    name: "Denkstatt",
    alt: "Denkstatt logo",
    url: "/denkstatt-logo.webp"
  },
]

export default function DataProviderLogos({}) {
  return (
    <div className="flex flex-col mx-auto justify-center w-full pt-16 pb-16">
      <span className="font-lg text-slate-500 tracking-wide font-semibold text-center">DATA SOURCED RELIABLY FROM</span>
      <div className="relative flex h-full w-full flex-col items-center justify-center overflow-hidden py-20">
        <Marquee pauseOnHover className="[--duration:20s]">
          {dataProviders.map((provider) => (
            <Image key={provider.name} className="object-scale-down" alt={provider.alt} src={provider.url} width={200} height={100} />
          ))}
        </Marquee>
        <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
        <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
      </div>
    </div>
  )
}
