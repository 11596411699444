import { gql } from "urql";

export const UpdatePasswordForUserMutation = gql`
  mutation UpdatePasswordForUser(
    $oldPassword: String!,
    $newPassword: String!,
    $newPasswordConfirmed: String!
  ) {
    updatePasswordForUser(
      oldPassword: $oldPassword,
      newPassword: $newPassword,
      newPasswordConfirmed: $newPasswordConfirmed
    ) {
      ... on UpdatePasswordForUserSuccess {
        success
      }
      ... on UpdatePasswordForUserErrors {
        error
      }
    }
  }
`;
