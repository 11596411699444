import { styled } from "goober";
import { HTMLProps } from "react";

interface ViewProps extends HTMLProps<HTMLElement> {
  flex?: boolean;
  direction?: "row" | "column";
  justifyContent?: string;
  alignItems?: string;
  width?: number;
  height?: number;
}

const View = styled("div")<ViewProps>`
  display: ${({ flex }) => (flex ? "flex" : "block")};
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justifyContent || "normal"};
  align-items: ${(props) => props.alignItems || "normal"};
`;

export default View;
