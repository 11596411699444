import { gql } from "urql";

export const CreateOrUpdateActivityMutation = gql`
  mutation CreateOrUpdateActivity($input: CreateOrUpdateActivityInput!) {
    createOrUpdateActivity(input: $input) {
      ... on CreateOrUpdateActivitySuccess {
        activity {
          name
          djangoId
        }
      }
      ... on CreateOrUpdateActivityErrors {
        fieldErrors {
          fieldName
          errors
        }
      }
    }
  }
`;
