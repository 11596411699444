import { CreateExportForActivitiesMutation } from "@Eikochain/graphql/hooks/mutations/useCreateCsvExportForShippingCalculator"
import { yupResolver } from "@hookform/resolvers/yup"
import { InfoIcon } from "lucide-react"
import { useForm } from "react-hook-form"
import { toast } from "sonner"
import { useMutation } from "urql"
import { InferType } from "yup"
import { Button } from "../button"
import { Checkbox } from "../checkbox"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../form"
import { RadioButtonGroup, RadioGroupItemButton } from "../radio-group"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../tooltip"
import { reportHeaders } from "./export-data-headers"
import { exportDataFormSchema } from "./schema"

export interface ExportDataFormProps {
  csvUploadId?: string;
  activities?: string[];
}

export default function ExportDataForm({
  csvUploadId,
  activities
}: ExportDataFormProps) {
  const [mutationResult, executeMutation] = useMutation(CreateExportForActivitiesMutation);
  const form = useForm({
    resolver: yupResolver(exportDataFormSchema),
    defaultValues: {
      exportFormat: "csv",
      headers: reportHeaders.map(i => i.value)
    }
  })

  function onSubmit (values: InferType<typeof exportDataFormSchema>) {
    executeMutation({
      id: csvUploadId,
      format: values.exportFormat,
      headers: values.headers,
      activities,
    }).then((result) => {
      const { data, error } = result;
      if (error) {
        toast.error("Something went wrong! We'll look into it");
      } else {
        if (data?.createExportForActivities?.url) {
          toast.success("Ready to export");
          window.open(data.createExportForActivities.url, '_blank');
        } else {
          toast.error("Something went wrong! We'll look into it");
        }
      }
    });
  };

  return (
    <Form {...form}>
      <form
        className="space-y-6"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="headers"
          render={() => (
            <FormItem>
              <FormLabel>
                Headers
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <InfoIcon />
                    </TooltipTrigger>
                    <TooltipContent className="max-w-[300px]">
                      These are the fields that we'll display in your exported file. Note these aren't editable (yet!)
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </FormLabel>
              <div className="flex flex-row flex-wrap gap-4">
                {reportHeaders.map((item) => (
                  <FormField
                    key={item.value}
                    control={form.control}
                    name="headers"
                    render={({ field }) => {
                      return (
                        <FormItem
                          key={item.value}
                          className="flex flex-row items-center space-x-2 space-y-0"
                        >
                          <FormControl>
                            <Checkbox
                              checked={field.value?.includes(item.value)}
                              onCheckedChange={(checked) => {
                                return checked
                                  ? field.onChange([...field.value, item.value])
                                  : field.onChange(
                                      field.value?.filter(
                                        (value) => value !== item.value
                                      )
                                    )
                              }}
                            />
                          </FormControl>
                          <FormLabel className="font-normal p-0">
                            {item.label}
                          </FormLabel>
                        </FormItem>
                      )
                    }}
                  />
                ))}
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="exportFormat"
          render={({ field }) => (
            <FormItem>
              <FormLabel>File format</FormLabel>
              <RadioButtonGroup {...field} value={field.value} onValueChange={field.onChange}>
                <RadioGroupItemButton
                  value="csv"
                  label="CSV (.csv)"
                />
                <RadioGroupItemButton
                  value="xlsx"
                  label="Excel (.xlsx)"
                />
              </RadioButtonGroup>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-row gap-2 justify-end">
          <Button type="submit" loading={mutationResult?.fetching}>Export</Button>
        </div>
      </form>
    </Form>
  )
}
