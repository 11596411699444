import { RetrieveEmissionResultsMutation } from "@Eikochain/graphql/hooks/mutations/useRetrieveEmissionResults";
import useGetActivitiesByProductId from "@Eikochain/graphql/hooks/useGetActivitiesByProductId";
import useGetEmissionsBreakdownByProductId from "@Eikochain/graphql/hooks/useGetEmissionsBreakdown";
import { carbonActivitiesAtom } from "@Eikochain/state/atoms/CarbonActivitiesAtom";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { useMutation } from "urql";
import CalculateEmissionsButton from "./calculate-emissions-button";

interface CalculateEmissionsButtonProps {
  productId: string;
}

export default function CalculateEmissionsButtonForProduct ({ productId }: CalculateEmissionsButtonProps) {
  const [mutation, executeMutation] = useMutation(RetrieveEmissionResultsMutation);
  const [reCalculateEmissions, setReCalculateEmissions] = useState(false);
  const carbonActivities = useAtomValue(carbonActivitiesAtom);
  const [_, reexecuteEmissionsQuery] = useGetEmissionsBreakdownByProductId(productId);
  const [__, reexecuteActivitiesQuery] = useGetActivitiesByProductId(productId);

  useEffect(() => {
    const everyActivityHasEmissionCalculation = carbonActivities.every(
      (activity) => activity.mostRecentEmissionCalculation !== null
    );
    if (everyActivityHasEmissionCalculation) {
      setReCalculateEmissions(false);
    } else {
      setReCalculateEmissions(true);
    }
  }, [carbonActivities]);

  const activitiesCountForType = (type: string) => {
    return carbonActivities.filter((carbonActivity) => carbonActivity.activityType === type).length;
  };
  const transportActivitiesCount = activitiesCountForType("Transport");
  const packagingActivitiesCount = activitiesCountForType("Packaging");
  const processActivitiesCount = activitiesCountForType("Process");
  const notAllRequiredActivitiesCreated = [
    transportActivitiesCount,
    packagingActivitiesCount,
    processActivitiesCount,
  ].some((activity) => activity === 0);

  const onCalculate = async () => {
    executeMutation({ productId }).then((result) => {
      const { data, error } = result;
      if (error) {
        toast.error("Something went wrong! We'll look into it");
      } else {
        if (data?.retrieveEmissionResults?.success) {
          toast.success("Emissions calculated");
          reexecuteEmissionsQuery({ requestPolicy: "network-only" });
          reexecuteActivitiesQuery({ requestPolicy: "network-only" });
        } else {
          toast.error("Something went wrong! We'll look into it");
        }
      }
    });
  };

  return (
    <CalculateEmissionsButton
      onCalculate={onCalculate}
      loading={mutation?.fetching}
      disabled={!reCalculateEmissions || mutation?.fetching ? true : notAllRequiredActivitiesCreated}
      canBeRecalculated={reCalculateEmissions}
      notAllRequirementsMet={notAllRequiredActivitiesCreated}
    />
  );
};
