import { gql, useQuery } from 'urql';

const GetPanelsQuery = gql`
  query {
    panels {
      djangoId
      name
      dateCreated
    }
  }
`;

const useGetPanels = () => {
  const result = useQuery({
    query: GetPanelsQuery,
  })
  return result;
}

export default useGetPanels;
