import { EmissionFactor, EmissionFactorSource } from "@Eikochain/__generated__/graphql";
import { Badge } from "@Eikochain/components/ui/badge";
import { Button } from "@Eikochain/components/ui/button";
import useGetEmissionFactorById from "@Eikochain/graphql/hooks/useGetEmissionFactorById";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { Link, useParams } from "react-router-dom";

export default function DataExplorerEmissionActivity () {
  const { id } = useParams<{ id: string }>();
  const [result] = useGetEmissionFactorById(id as string);
  const breadcrumbs = [{
    name: "Data Explorer",
    url: "/app/data-explorer"
  }, {
    name: `${result?.data?.emissionFactorById?.name} (Emission Activity)`,
  }]
  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="p-4">
        <div className="flex flex-col gap-4 mt-12">
          <div><Badge>Emission Activity</Badge></div>
          <div className="w-full flex flex-row justify-between items-start">
            <h1 className="text-3xl">{result?.data?.emissionFactorById?.name}</h1>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-32">
          <h2>Data sources</h2>
          <p>We may have multiple data sources for the same activity. You can view them all and their values by clicking on one below.</p>
          <div className="flex flex-row flex-wrap gap-4">
          {
            result?.data?.emissionFactorById?.sources?.length ? result?.data?.emissionFactorById?.sources.map(
              (ef: EmissionFactorSource) => ef.dataSource ? (
                <Link to={`/app/data-explorer/emission-source/${ef.id}`}>
                  <Button variant="secondary">{ef.dataSource.name}</Button>
                </Link>
              ) : null
            ) : <p>None to show</p>
          }
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-32">
          <h2>Similar emission factors</h2>
          <p>We use these emission factors to show comparisons so that you have an idea of how your activities compare</p>
          <div className="flex flex-row flex-wrap gap-4">
            {
              result?.data?.emissionFactorById?.similarEfs.length ? result?.data?.emissionFactorById?.similarEfs.map(
                (ef: EmissionFactor) => (
                  <Link to={`/app/data-explorer/emission-activity/${ef.id}`}>
                    <Button variant="secondary">{ef.name}</Button>
                  </Link>
              )) : <p>None to show</p>
            }
          </div>
        </div>
      </div>
    </BaseLayout>
  )
};
