import { useState } from "react";
import { Button, Dropdown, Input } from "antd";
import Panel from "rc-color-picker/lib/Panel";
import "rc-color-picker/dist/rc-color-picker.css";

interface InternalColorState {
  color: string;
  hue: number;
}

interface ColorPickerInputProps {
  color: string;
  onChange: (color: string) => void;
}

export default function ColorPickerInput({
  color,
  onChange
}: ColorPickerInputProps) {
  function getInternalColorWithHue (col: string): InternalColorState {
    return {
      color: col,
      hue: 1,
    }
  }
  function formatColorWithoutHexPrefix (newColor: string) {
    return newColor.replace("#", "")
  }

  const [internalColor, setInternalColor] = useState<InternalColorState>(getInternalColorWithHue(color));
  const handleChange = (newColor: string) => {
    const newInternalColor = getInternalColorWithHue(newColor)
    setInternalColor(newInternalColor);
    if (onChange) onChange(newColor);
  };

  const Overlay = () => (
    <Panel
      color={internalColor}
      enableAlpha={false}
      onChange={(col: InternalColorState) => handleChange(col.color)}
    />
  );

  return (
    <>
      <Input
        value={internalColor?.color ? formatColorWithoutHexPrefix(internalColor.color) : ""}
        maxLength={6}
        prefix="#"
        onChange={(e) => handleChange("#" + e.target.value)}
        suffix={
          <Dropdown trigger={["click"]} dropdownRender={Overlay}>
            <Button style={{ background: internalColor?.color }}> </Button>
          </Dropdown>
        }
      />
    </>
  );
}
