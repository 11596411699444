import { arrayMove } from '@dnd-kit/sortable';
import { useAtom } from "jotai";
import { canvasMenuStepsAtom } from "@Eikochain/state/atoms/CanvasMenuContent";
import { ICanvasMenuStep } from '@Eikochain/components/organisms/MarketingEditor/types';

const useReorderMenuSteps = () => {
  const [steps, setSteps] = useAtom(canvasMenuStepsAtom);

  function execute(stepLocalId: number, direction: "up" | "down") {
    const ofStep = (step: ICanvasMenuStep) => step.localId === stepLocalId;
    const oldIndex = steps.findIndex(ofStep);
    let newIndex;
    if (direction === "up") {
      newIndex = oldIndex - 1
    }
    if (direction === "down") {
      newIndex = oldIndex + 1
    }
    // Update order and ensure new position is recorded
    let newStepsOrder = arrayMove(steps, oldIndex, newIndex as number)
    newStepsOrder = newStepsOrder.map((step, index) => ({
      ...step,
      position: index
    }))
    setSteps(newStepsOrder)
  }

  return execute;
};

export default useReorderMenuSteps;
