import { gql } from 'urql';

export const UploadEmissionFactorsDocumentMutation = gql`
  mutation UploadEmissionFactorsDocument($input: UploadEmissionFactorsDocumentInput!) {
    uploadEmissionFactorsDocument(input: $input) {
      ... on UploadEmissionFactorsDocumentSuccess {
        count
        errors
      }
      ... on UploadEmissionFactorsDocumentErrors {
        errors
      }
    }
  }
`;
