import { gql, useQuery } from "urql";

const GetActivitiesByProductIdQuery = gql`
  query ($id: String!) {
    activitiesByProductId(id: $id) {
      product {
        djangoId
      }
      djangoId
      name
      position
      activityType
      transportMethod
      weight
      energy
      spend
      region
      currency {
        currencyCode
        currencyName
        symbol
      }
      process
      material
      route {
        origin {
          region
          countryCode
          lat
          lng
        }
        destination {
          region
          countryCode
          lat
          lng
        }
        distance
      }
      mostRecentEmissionCalculation {
        co2e
      }
      emissionCalculationRange {
        results {
          id
          co2e
          isOriginal
          subject
          subjectType
          source
          sourceDataset {
            name
            url
          }
          region
          year
        }
      }
    }
  }
`;

const useGetActivitiesByProductId = (id: string) => {
  const result = useQuery({
    query: GetActivitiesByProductIdQuery,
    variables: {
      id,
    },
  });
  return result;
};

export default useGetActivitiesByProductId;
