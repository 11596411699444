import { currentPanelAtom, elementsForCurrentCanvasAtom, selectedElementIdAtom } from "@Eikochain/state/atoms/CanvasContent";
import { canvasMenuSelectedStepIdAtom } from "@Eikochain/state/atoms/CanvasMenuContent";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";

const useJumpToCanvas = () => {
  const selectedStepId = useAtomValue(canvasMenuSelectedStepIdAtom)
  const panel = useAtomValue(currentPanelAtom);
  const setElements = useSetAtom(elementsForCurrentCanvasAtom);
  const setSelectedElement = useSetAtom(selectedElementIdAtom)

  useEffect(() => {
    // When the selected step ID changes, we want to jump to the appropriate canvas for the step.
    // This is done by finding the corresponding canvas and setting the elements in local state
    // to the same elements on the canvas.
    if (selectedStepId) {
      // 1. Find if a canvas instance exists in `canvases` for the step selected:
      const canvasForSelectedStep = panel?.canvases.find(canvas => canvas.localId === selectedStepId.localId);
      if (canvasForSelectedStep) {
        // 2. If it does exist, set `elements` to the matching canvas:
        setElements(canvasForSelectedStep.elements)
      } else {
        // 3. If it doesn't:
        setElements([]);
      }
      setSelectedElement(null);
    }
  }, [selectedStepId])
};
  
export default useJumpToCanvas;
