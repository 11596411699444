export const elementTypes = [
  {
    value: "text",
    label: "Text"
  },
  {
    value: "imageSingle",
    label: "Image"
  },
  {
    value: "video",
    label: "Video"
  },
]
