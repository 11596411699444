import { propPanel as parentPropPanel } from '../text/propPanel';
import { PropPanel, PropPanelWidgetProps } from '@pdfme/common';
import { StatisticSchema, statisticTypesValues } from './types';

export const propPanel: PropPanel<StatisticSchema> = {
    schema: (propPanelProps: Omit<PropPanelWidgetProps, 'rootElement'>) => {
        if (typeof parentPropPanel.schema !== 'function') {
            throw Error('Oops, is text schema no longer a function?');
        }

        return {
            ...parentPropPanel.schema(propPanelProps),
            statisticType: {
                title: 'Statistic Type',
                type: 'string',
                widget: 'select',
                default: statisticTypesValues[0].value,
                props: {
                    options: [...statisticTypesValues],
                },
                span: 12
            },
        };
    },
    widgets: { ...parentPropPanel.widgets },
    defaultSchema: {
        ...parentPropPanel.defaultSchema,
        readOnly: false,
        statisticType: statisticTypesValues[0].value,
        type: 'select',
        text: 'Select a statistic type',
        width: 50,
        height: 15,
    },
};

