import { gql } from 'urql';

export const UpdateSupplierInformationRequestTemplateMutation = gql`
  mutation UpdateSupplierInformationRequestTemplate($input: UpdateSupplierInformationRequestTemplateInput!) {
    updateSupplierInformationRequestTemplate(input: $input) {
        success
        reason
      }
    }
`
