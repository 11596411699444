import { styled } from "goober";
import View from "../View";

interface GenerateGridLinesProps {
  cellSize: number;
  width?: number;
  height?: number;
}

const GenerateGridLinesBackground = styled(View)<GenerateGridLinesProps>`
  width:  ${({ width }) => width ? `${width}px` : "100%"};
  height: ${({ height }) => height ? `${height}px` : "100%"};
  background-size:  ${({ cellSize }) => `${cellSize}px ${cellSize}px`};
  pointer-events: all;
  transition: opacity 0.4s ease;
  opacity: 1;
  background-image: ${({ cellSize }) => `repeating-linear-gradient( 0deg, transparent, transparent calc(${cellSize}px - 1px), #ddd calc(${cellSize}px - 1px), #ddd ${cellSize}px ), repeating-linear-gradient( -90deg, transparent, transparent calc(${cellSize}px - 1px), #ddd calc(${cellSize}px - 1px), #ddd ${cellSize}px )`};
`;

const GenerateGridLines = ({
  cellSize,
  width,
  height,
}: GenerateGridLinesProps): JSX.Element => {
  // Creates a grid background with cells of given `cellSize` in px
  // and a width and height, also in px.
  return (
    <GenerateGridLinesBackground cellSize={cellSize} width={width} height={height} />
  );
};

export default GenerateGridLines;
