import { useAtomValue, useSetAtom } from "jotai";
import {
  canvasMenuSelectedStepIdAtom,
  canvasMenuStepsAtom
} from "@Eikochain/state/atoms/CanvasMenuContent";
import { useEffect } from "react";
import usePrevious from "../usePrevious";
import { ICanvasMenuStep } from "@Eikochain/components/organisms/MarketingEditor/types";

const useUpdateSelectedStepId = () => {
  const steps = useAtomValue(canvasMenuStepsAtom);
  const setSelectedStepId = useSetAtom(canvasMenuSelectedStepIdAtom);
  const previousStepsLength = usePrevious(steps?.length ?? 0) as number;

  useEffect(() => {
    if (!steps.length) {
      setSelectedStepId(null)
    } else {
      if (steps.length < previousStepsLength) {
        // If a user deletes a step, we want to select the first step
        const firstStep = steps[0] as ICanvasMenuStep;
        setSelectedStepId({ serverId: firstStep.serverId, localId: firstStep.localId })
      }
      if (steps.length > previousStepsLength) {
        // If a user creates or duplicates a step, it will go to the end of the array
        const lastStep = steps[steps.length - 1] as ICanvasMenuStep;
        setSelectedStepId({ serverId: lastStep.serverId, localId: lastStep.localId })
      }
    }
  }, [steps])
};
  
export default useUpdateSelectedStepId;
