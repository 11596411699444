import * as RadioGroupPrimitive from "@radix-ui/react-radio-group"
import { Circle } from "lucide-react"
import * as React from "react"

import { cn } from "@Eikochain/utils"
import { Button } from "./button"
import { Label } from "./label"

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("flex flex-row flex-wrap gap-2", className)}
      {...props}
      ref={ref}
    />
  )
})
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioButtonGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, children, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("flex flex-row flex-wrap gap-4", className)}
      {...props}
      ref={ref}
    >
      {children}
    </RadioGroupPrimitive.Root>
  )
})
RadioButtonGroup.displayName = "RadioButtonGroup"

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        "aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center checked:bg-green-600">
        <Circle className="h-2.5 w-2.5 fill-current text-current checked:bg-green-600" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export interface RadioGroupItemButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  value: string;
  disabled?: boolean;
}

const RadioGroupItemButton = React.forwardRef<HTMLButtonElement, RadioGroupItemButtonProps>(
  ({ className, label, value, disabled, ...props }, ref) => {
  return (
    <Label htmlFor={value} className="">
      <RadioGroupItem value={value} id={value} disabled={disabled} className="peer sr-only" />
      <Button
        variant="secondary"
        disabled={disabled}
        className="pointer-events-none peer-data-[state=checked]:bg-emerald-700 peer-data-[state=checked]:text-white"
        ref={ref}
        type="button"
      >
        {label}
      </Button>
    </Label>
  )
})
RadioGroupItemButton.displayName = "RadioGroupItemButton"

export { RadioButtonGroup, RadioGroup, RadioGroupItem, RadioGroupItemButton }
