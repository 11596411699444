import { EmissionFactorInputParameter, EmissionFactorSource } from "@Eikochain/__generated__/graphql";
import { useAcquireRegionsFromSources } from "@Eikochain/hooks/useAcquireRegionsFromSources";
import RegionField, { RegionFieldProps } from "./region-field";

interface RegionFieldFromSourcesWrapperProps extends Omit<RegionFieldProps, "regions"> {
  selectedParameter?: EmissionFactorInputParameter;
  sources: EmissionFactorSource[]
}

export default function RegionFieldFromSourcesWrapper({
  control,
  selectedParameter,
  sources,
}: RegionFieldFromSourcesWrapperProps) {
  const [deduplicatedRegions] = useAcquireRegionsFromSources(sources, selectedParameter)
  return sources?.length ? <RegionField control={control} regions={deduplicatedRegions} /> : null
}
