import useCsvUploads from "@Eikochain/graphql/hooks/useGetCsvUploads";
import UploadedFilesTable from "../ui/uploaded-files-table";

export default function UploadedFileList() {
  const [result] = useCsvUploads();

  return (
    <UploadedFilesTable data={result?.data?.csvUploads ?? []} isFetching={result.fetching} />
  );
}
