import { Activity } from "@Eikochain/__generated__/graphql";
import omitDeep from "omit-deep-lodash";
import { toast } from "sonner";
import { AnyVariables, UseMutationExecute } from "urql";

export default function onSubmit (
  values: any,
  executionHandler: UseMutationExecute<any, AnyVariables>,
  onSuccess: () => void,
  productId?: string,
  instance?: Activity,
): void {
  const data = {
    route: values.activityType === "transport"
      ? {
          origin: omitDeep(values.origin, "__typename"),
          destination: omitDeep(values.destination, "__typename"),
          distance: values.distance,
        } : values.activityType === "fuel" ? {
          distance: values.distance,
        } : {},
    name: values.name,
    transportMethod: values.activityType === "fuel"
      ? "Road Freight"
      : values.activityType === "transport"
        ? values.transportMethod
        : null,
    activityType: values.activityType,
    scope: productId ? null : values.scope,
    process: values.activityType === "process" ? values.process : null,
    material: ["process", "packaging", "waste"].includes(values.activityType) ? values.material : null,
    weight: parseFloat(values.weight),
    energy: parseFloat(values.energy),
    spend: parseFloat(values.spend),
    region: values.region,
    currency: {
      currencyCode: values.currency.currencyCode
    },
    fuelType: values.activityType === "fuel" ? values.fuelType : null,
    serviceType: values.activityType === "services" ? values.serviceType : null,
    goodsType: values.activityType === "goods" ? values.goodsType : null,
    disposalMethod: values.activityType === "waste" ? values.disposalMethod : null,
  };

  executionHandler({
    input: {
      id: instance?.djangoId,
      productId,
      activity: data,
    },
  }).then((result) => {
    const { error } = result;
    if (error) {
      toast.error("An error occured");
    } else {
      if (instance) {
        toast.success("Updated activity");
      } else {
        toast.success("Created activity");
      }
      onSuccess();
    }
  });
};
