import { Activity } from "@Eikochain/__generated__/graphql";
import ActivityStep from "@Eikochain/components/ui/activity-step/activity-step";
import DashedConnector from "@Eikochain/components/ui/dashed-connector";
import { UseQueryExecute } from "urql";

type ActivityStepsProps = {
  data: Activity[];
  executeQuery: UseQueryExecute;
};

export default function ActivitySteps ({
  data,
  executeQuery
}: ActivityStepsProps) {
  return (
    <>
      {data?.map((activity: Activity, index: number) => (
        <div key={index} className="flex flex-col gap-3 items-center w-full">
          <ActivityStep
            activity={activity}
            productId={activity.product?.djangoId}
            onCloneActivitySuccess={() => executeQuery({ requestPolicy: "network-only" })}
          />
          <div className="flex flex-col items-center w-full mb-2 h-4">
            {index === data.length - 1 ? null : <DashedConnector />}
          </div>
        </div>
      ))}
    </>
  );
};
