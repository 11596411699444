import { gql, useQuery } from "urql";

const EmissionFactorsByActivityTypeQuery = gql`
  query ($activityType: String!) {
    emissionFactorsByActivityType(activityType: $activityType) {
      emissionFactors {
        name
        activityFields {
          transportMethod
          process
          material
          activityType
          fuelType
          serviceType
          goodsType
          disposalMethod
        }
        sources {
          parameters {
            name
            unitName
            defaultUnit {
              name
              value
            }
            unitChoices {
              name
              value
            }
          }
          dataSource {
            region
          }
        }
      }
      categoriesMaterialsProcesses {
        name
        materials {
          name
          processes
        }
      }
    }
  }
`;

const useEmissionFactorsByActivityType = (activityType: string) => {
  const result = useQuery({
    query: EmissionFactorsByActivityTypeQuery,
    variables: {
      activityType,
    },
  });
  return result;
};

export default useEmissionFactorsByActivityType;
