import { atom } from 'jotai';
import { BackgroundColorType } from '@Eikochain/components/organisms/MarketingEditor/types';
import { whiteColor } from '@Eikochain/themes/constants';

export const logoUrlAtom = atom<string | null>(null);
export const fontFamilyAtom = atom('Arial');
export const fontSizeAtom = atom(16);
export const fontColorAtom = atom(whiteColor);
export const solidBackgroundColorAtom = atom<string>('#FFFFFF');
export const gradientBackgroundColorAtom = atom<string>('linear-gradient(77deg, rgb(15, 53, 62) 0%, rgb(69, 79, 74) 81%, rgb(80, 85, 81) 100%)');
export const backgroundColorTypeAtom = atom<BackgroundColorType>("gradient");
