import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";

const Head = ({ children }: {children: ReactNode}) => {
  return (
    <Helmet>{children}</Helmet>
  )
};

export default Head;
