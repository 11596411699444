import { FileDigitIcon, SheetIcon } from "lucide-react";
import { Tooltip, TooltipProvider, TooltipTrigger } from "./tooltip";

const mapReportTypeToIcon = (reportType: string) => {
  switch (reportType) {
    case "csv":
      return <FileDigitIcon />
    case "xlsx":
      return <SheetIcon />
    default:
      return <SheetIcon />
  }
};

interface ReportTypeIconProps {
  reportType: "csv" | "xlsx";
}

export default function ReportTypeIcon({
  reportType,
}: ReportTypeIconProps) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <div>{mapReportTypeToIcon(reportType)}</div>
        </TooltipTrigger>
        {/* <TooltipContent>
          {!transportMethod ? activityType : `${activityType} via ${transportMethod}`}
        </TooltipContent> */}
      </Tooltip>
    </TooltipProvider>
  )
}
