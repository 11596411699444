import { gql, useQuery } from 'urql';

const GetHighestEmittingRoutesQuery = gql`
  query {
    highestEmittingRoutes {
      djangoId
      name
      activityType
      transportMethod
      weight
      route {
        origin {
          region
          countryCode
        }
        destination {
          region
          countryCode
        }
        distance
      }
      mostRecentEmissionCalculation {
        co2e
      }
      client
      dateOfOrder
    }
  }
`;

const useGetHighestEmittingRoutes = () => {
  const result = useQuery({
    query: GetHighestEmittingRoutesQuery,
  })
  return result;
}

export default useGetHighestEmittingRoutes;
