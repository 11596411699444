import { Control } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Input } from "../../input";

interface EnergyFieldProps {
  control: Control<any>
}

export default function EnergyField({
  control
}: EnergyFieldProps) {
  return (
    <FormField
      control={control}
      name="energy"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Energy (kWh)</FormLabel>
          <FormControl>
            <Input
              type="number" min="0" max="100000" step="0.001" placeholder="e.g. 5"
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
