import { Product } from "@Eikochain/__generated__/graphql";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, EditIcon, MoreHorizontalIcon, Trash2Icon } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../button";
import { Dialog } from "../dialog";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../dropdown-menu";
import DeleteProductDialogContent from "./delete-product-dialog-content";

const columns = (withEmissions: boolean, withActions: boolean, withUrl: boolean): ColumnDef<Product>[] => {
  let baseColumns: ColumnDef<Product>[] = [
    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            Name
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => <div>{row.getValue("name")}</div>,
    },
  ]
  const urlColumn: ColumnDef<Product> = {
    accessorKey: "url",
    header: "URL",
    cell: ({ row }) => <div>{row.getValue("url")}</div>,
  }
  const categoryColumn: ColumnDef<Product> = {
    accessorKey: "category",
    header: "Category",
    cell: ({ row }) => <div>{row.getValue("category")}</div>,
  }
  const actionsColumn: ColumnDef<Product> = {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => {
      const [deleteModalVisible, setDeleteModalVisible] = useState(false)
      return (
        <>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                <MoreHorizontalIcon />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="gap-4">
              <DropdownMenuItem asChild>
                <Link to={"/app/products/detail/" + row.original.djangoId}>
                  <EditIcon />
                  Edit
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setDeleteModalVisible(true)}>
                <Trash2Icon />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Dialog open={deleteModalVisible} onOpenChange={setDeleteModalVisible}>
            <DeleteProductDialogContent productId={row.original.djangoId} onFinish={() => setDeleteModalVisible(false)} />
          </Dialog>
        </>
      );
    },
  }
  const emissionsColumn: ColumnDef<Product> = {
    id: "totalEmissions",
    header: () => <div>CO2<sub>e</sub> (kg)</div>,
    cell: ({ row }) => <div className="font-mono">{row.original.emissionsBreakdown?.find((e) => e.category === "total")?.emissions?.results?.[0].co2e}</div>,
  }
  if (withUrl) {
    baseColumns = [...baseColumns, urlColumn, categoryColumn];
  }
  if (withEmissions) {
    baseColumns = [...baseColumns, emissionsColumn];
  }
  if (withActions) {
    baseColumns = [...baseColumns, actionsColumn];
  }
  return baseColumns;
}

export default columns;
