import { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

interface EditorProps {
  initialValue: string;
  onChange: (content: string) => void;
}

const Editor = ({
  initialValue,
  onChange
}: EditorProps) => {
	const editor = useRef(null);
	const [content, setContent] = useState("");

  if (initialValue !== content) {
    setContent(initialValue);
  }

	const config = useMemo(() => ({
    readonly: false,
    placeholder: "Type here...",
    statusbar: false
  }), [])

	return (
		<JoditEditor
			ref={editor}
			value={content}
			config={config}
			onBlur={newContent => setContent(newContent)}
			onChange={newContent => onChange(newContent)}
		/>
	);
};

export default Editor;
