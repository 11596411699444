import { useGetSupplierInformationRequestsById } from "@Eikochain/graphql/hooks/useGetSupplierInformationRequests";
import SupplierInformationRequestsTable from ".";

interface SupplierInformationRequestsTableWithDataProps {
    bySupplierId: string;
    refetch: () => void;
}

export default function SupplierInformationRequestsTableWithData({
    bySupplierId,
    refetch
}: SupplierInformationRequestsTableWithDataProps) {
    const [result] = useGetSupplierInformationRequestsById(bySupplierId);
    return (
        <SupplierInformationRequestsTable
            data={result.data?.supplierInformationRequests ?? []}
            isFetching={result.fetching}
            refetch={refetch}
            showRowCount={true}
        />
    )
}
