import { DndContext, DragStartEvent, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { createSnapModifier, restrictToParentElement } from "@dnd-kit/modifiers";
import { selectedElementIdAtom } from "@Eikochain/state/atoms/CanvasContent";
import { useAtom, useAtomValue } from "jotai";
import { ReactElement, memo, useEffect, useMemo } from "react";
import CanvasElementsArea from "../Canvas/CanvasElementsArea";
import { MarketingPanelLayout } from "../types";

interface ICanvasElementsHOC {
  isEditing: boolean;
  layout: MarketingPanelLayout;
  gridSize: number;
}

const CanvasElementsHOC = ({
  isEditing = false,
  layout = "desktop",
  gridSize,
}: ICanvasElementsHOC) => {
  // Decides whether to wrap the canvas elements with the DndContext
  // so elements can be drag/dropped. Will only wrap in the case that edit mode
  // is switched on.
  const [selectedElement, setSelectedElement] = useAtom(selectedElementIdAtom);
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);
  const snapToGrid = useMemo(() => createSnapModifier(gridSize), [gridSize]);

  function onDragStart(event: DragStartEvent) {
    if (event.active.id !== selectedElement) {
      setSelectedElement(event.active.id as number)
    }
  }

  const DndContextWrapper = ({ children }: { children: ReactElement }) => {
    if (isEditing) {
      return (
        <DndContext
          onDragStart={onDragStart}
          modifiers={[snapToGrid, restrictToParentElement]}
          sensors={sensors}
        >
          {children}
        </DndContext>
      )
    } else {
      return children
    }
  }

  return (
    <DndContextWrapper>
      <CanvasElementsArea isEditing={isEditing} isMobile={layout === "mobile"} />
    </DndContextWrapper>
  )
};

export default memo(CanvasElementsHOC);
