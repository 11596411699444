import { Activity } from "@Eikochain/__generated__/graphql";
import useGetEmissionsBreakdownByScope from "@Eikochain/graphql/hooks/useGetEmissionsBreakdownByScope";
import { carbonActivitiesAtom } from "@Eikochain/state/atoms/CarbonActivitiesAtom";
import { ActivityTypeOption } from "@Eikochain/types";
import { useAtom } from "jotai";
import { CopyIcon, EditIcon, MoreHorizontalIcon, Trash2Icon } from "lucide-react";
import { useState } from "react";
import { Button } from "../button";
import CreateOrUpdateActivityModal from "../create-update-activity-dialog";
import DeleteActivityDialogContent from "../delete-activity-dialog-content";
import { Dialog } from "../dialog";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../dropdown-menu";

interface ActivityStepActionsDropdownProps {
  activity: Activity;
  onCloneActivity: () => void;
}

export default function ActivityStepActionsDropdown({
  activity,
  onCloneActivity
}: ActivityStepActionsDropdownProps) {
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const productId = activity.product?.djangoId;
  const [carbonActivities, setCarbonActivities] = useAtom(carbonActivitiesAtom);
  const [_, reexecuteEmissionsQuery] = useGetEmissionsBreakdownByScope();
  return (
    <>
      <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
        <DeleteActivityDialogContent
          activityId={activity.djangoId}
          onFinish={() => {
            const updatedActivities = carbonActivities.filter(item => item.djangoId !== activity.djangoId);
            setDeleteModal(false);
            setCarbonActivities(updatedActivities)
            reexecuteEmissionsQuery()
          }}
        />
      </Dialog>
      <Dialog open={editModalOpen} onOpenChange={setEditModalOpen}>
        <CreateOrUpdateActivityModal
          activityTypes={productId ? "product" : "company"}
          instance={activity}
          productId={productId}
          defaultActivityType={activity.activityType as ActivityTypeOption}
          onFinish={() => setEditModalOpen(false)}
        />
      </Dialog>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="ml-auto">
            <MoreHorizontalIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="gap-4">
          <DropdownMenuItem onClick={() => setEditModalOpen(true)}>
            <EditIcon />
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem onClick={onCloneActivity}>
            <CopyIcon />
            Copy
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setDeleteModal(true)}>
            <Trash2Icon />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
