import { useAtom, useSetAtom } from "jotai";
import {
  canvasMenuStepsAtom
} from "@Eikochain/state/atoms/CanvasMenuContent";
import { ICanvasMenuStep } from "@Eikochain/components/organisms/MarketingEditor/types";

const useDuplicateMenuStep = () => {
  // This hook only duplicates the menu step and does not duplicate the canvas
  const [steps, setSteps] = useAtom(canvasMenuStepsAtom);

  function execute(stepLocalId: number) {
    // Gets the step and copies the `title` attribute from it, but
    // creates a new localId and position to be slotted in the array
    const getStep = steps.find(step => step.localId === stepLocalId);
    if (getStep) {
      const title = getStep.title;
      const localId = Math.max(...steps.map(step => step.localId)) + 1;
      const position = (steps[steps.length - 1] as unknown as ICanvasMenuStep).position + 1;
      const serverId = null;
      const duplicatedStep = {
        serverId,
        localId,
        title,
        position
      }
      setSteps([...steps, duplicatedStep])
    }
  }

  return execute;
};

export default useDuplicateMenuStep;
