import { Control } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Input } from "../../input";

interface ActivityNameFieldProps {
  control: Control<any>
}

export default function ActivityNameField({
  control
}: ActivityNameFieldProps) {
  return (
    <FormField
      control={control}
      name="name"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Activity name</FormLabel>
          <FormControl>
            <Input
              placeholder="e.g. Cotton Production, Packaging, Warehouse Electricity, Product Transport"
              {...field}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
