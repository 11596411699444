import AddSupplierForm from "../add-supplier-form";
import { DialogContent, DialogHeader, DialogTitle } from "../dialog";

interface AddSupplierDialogContentProps {
  triggerClose: () => void
}

export default function AddSupplierDialogContent({
  triggerClose
}: AddSupplierDialogContentProps) {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Add a new supplier</DialogTitle>
      </DialogHeader>
      <AddSupplierForm onSuccess={triggerClose} />
    </DialogContent>
  )
}
