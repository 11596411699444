import { Activity, EmissionFactorInputParameter, EmissionFactorSource } from "@Eikochain/__generated__/graphql";
import { useAcquireEmissionFactorSourceInputs } from "@Eikochain/hooks/useAcquireEmissionFactorSourceInputs";
import { useEffect, useState } from "react";
import { Control } from "react-hook-form";
import RegionFieldFromSourcesWrapper from "../fields/region-field/region-field-from-sources-wrapper";
import SwitcherField from "../switcher-field";

interface SourcesAndParametersFormProps {
  activity?: Activity;
  control: Control<any>;
  sources: EmissionFactorSource[];
  setSelectedParameter: (value: EmissionFactorInputParameter) => void;
}

export default function SourcesAndParametersForm({
  activity,
  control,
  sources,
  setSelectedParameter,
}: SourcesAndParametersFormProps) {
  const [
    deduplicatedParameters, initialParameter
  ] = useAcquireEmissionFactorSourceInputs(sources, activity)

  const [defaultParameter, setDefaultParameter] = useState<EmissionFactorInputParameter>()
  useEffect(() => {
    setDefaultParameter(initialParameter)
    setSelectedParameter(initialParameter)
  }, [initialParameter])

  return (
    <>
      {defaultParameter && deduplicatedParameters.length ? (
        <SwitcherField
          control={control}
          fields={deduplicatedParameters}
          selectedField={defaultParameter}
          setSelectedParameter={
            (p: EmissionFactorInputParameter) => {setDefaultParameter(p); setSelectedParameter(p)}
          }
        />
      ) : null}
      <RegionFieldFromSourcesWrapper control={control} selectedParameter={defaultParameter} sources={sources} />
    </>
  )
}
