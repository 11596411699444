import { Activity } from "@Eikochain/__generated__/graphql";
import useGetScopedActivitiesByOrganizationId from "@Eikochain/graphql/hooks/useGetScopedActivitiesByOrganizationId";
import { carbonActivitiesAtom } from "@Eikochain/state/atoms/CarbonActivitiesAtom";
import { ScopeOption } from "@Eikochain/types";
import { useAtom } from "jotai";
import { ChevronDownIcon, MicroscopeIcon } from "lucide-react";
import { useEffect, useState } from "react";
import ActivitiesTable from "../activities-table";
import { Button } from "../button";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from "../dropdown-menu";

const scopes = [
  {
    value: "all",
    label: "All scopes"
  },
  {
    value: "scope_1",
    label: "Scope 1"
  },
  {
    value: "scope_2",
    label: "Scope 2"
  },
  {
    value: "scope_3",
    label: "Scope 3"
  }
]

export default function ActivityStepsForOrganization() {
  const [selectedScope, setSelectedScope] = useState<ScopeOption>()
  const [carbonActivities, setCarbonActivities] = useAtom(carbonActivitiesAtom);
  const [result] = useGetScopedActivitiesByOrganizationId();

  useEffect(() => {
    let data = result?.data?.scopedActivitiesByOrganizationId;
    if (selectedScope) {
      data = data.filter((activity: Activity) => activity.scope?.value === selectedScope)
    }
    setCarbonActivities(data ?? []);
  }, [result?.data, selectedScope]);

  const ScopeFilterDropdownMenu = (): JSX.Element => (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="ml-auto" icon={<MicroscopeIcon />}>
          {scopes.find((s) => s.value === selectedScope)?.label ?? "Filter by scope"} <ChevronDownIcon className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {scopes.map((scope) => (
          <DropdownMenuCheckboxItem
            key={scope.value}
            checked={scope.value === selectedScope}
            onCheckedChange={(value) => {
              if (value) {
                if (scope.value === "all") {
                  setSelectedScope(undefined)
                } else {
                  setSelectedScope(scope.value as ScopeOption)
                }
              };
            }}
          >
            {scope.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )

  return (
    <div className="flex flex-col justify-stretch w-full min-h-full">
      <ActivitiesTable
        data={carbonActivities}
        isFetching={false}
        hideShippingCalculatorSpecificColumns
        isEditable
        isSelectable
        displayScope
        exportAvailable
        additionalFilters={[<ScopeFilterDropdownMenu />]}
      />
    </div>
  )
}
