import { gql, useQuery } from 'urql';

const GetPanelByIdQuery = gql`
  query($id: String!) {
    panelById(id: $id) {
      id: djangoId
      name
      menuStyling: styling {
        fontFamily
        fontSize
        fontColor
        gradientBackgroundColor
        solidBackgroundColor
        backgroundColorType
      }
      canvases {
        serverId: djangoId
        elements {
          serverId: djangoId
          type
          content
          x
          y
          width
          height
        }
      }
      menuSteps: steps {
        serverId: djangoId
        title
        position
      }
    }
  }
`;

const useGetPanelById = (id: string) => {
  const result = useQuery({
    query: GetPanelByIdQuery,
    variables: {
      id
    }
  })
  return result;
}

export default useGetPanelById;
