import ReportsTable from "@Eikochain/components/ui/reports-table";
import useGetReports from "@Eikochain/graphql/hooks/useGetReports";
import BaseLayout from "@Eikochain/layouts/BaseLayout";

export default function ReportingPage() {
    const [result] = useGetReports();
    const breadcrumbs = [{
        name: "Reporting"
    }]

    return (
        <BaseLayout
            breadcrumbs={breadcrumbs}
        >
            <div className="w-full py-2">
                <ReportsTable
                    data={result.data?.reports ?? []} isFetching={result?.fetching}
                />
            </div>
        </BaseLayout>
    )
}
