import { gql, useQuery } from 'urql';

const GetEmissionsBreakdownByProductIdQuery = gql`
  query($id: String!) {
    productById(id: $id) {
      id: djangoId
      emissionsBreakdown {
        category
        emissions {
          results {
            co2e
          }
        }
      }
    }
  }
`;

const useGetEmissionsBreakdownByProductId = (productId: string) => {
  const result = useQuery({
    query: GetEmissionsBreakdownByProductIdQuery,
    variables: {
      id: productId
    },
    requestPolicy: "network-only"
  })
  return result;
}

export default useGetEmissionsBreakdownByProductId;
