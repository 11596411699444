interface FactorSourceTileProps {
  name: string;
}

export default function FactorSourceTile({
  name
}: FactorSourceTileProps) {
  return (
    <div className="flex flex-row items-center gap-6 p-6 rounded-xl border-2 border-gray-200">
      <div className="flex flex-col">
        <h5>Calculation provided by</h5>
        <h3>{name}</h3>
      </div>
    </div>
  )
}
