import { gql, useQuery } from 'urql';

export const GetSupplierInformationRequestByIdQuery = gql`
  query($id: String!) {
    supplierInformationRequestById(id: $id) {
      id
      status
      createdAt
      supplier {
        name
      }
      supplierContact {
        name
        email
        supplier {
          name
        }
      }
      organization {
        name
      }
      user {
        email
      }
      additionalInformation
      requireProof
      requestType
      steps {
          stepNo
          title
          description
          inputType
          measurement
      }
      responses {
        createdAt
        name
        email
        noProofReason
        document {
          location
        }
        steps {
            stepNo
            response 
            title
            description
            measurement
            inputType
        }
      }
    }
  }
`;

const useGetSupplierInformationRequestById = (id: string) => {
    const result = useQuery({
        query: GetSupplierInformationRequestByIdQuery,
        variables: {
            id
        }
    })
    return result;
}

export default useGetSupplierInformationRequestById;
