import CreateOrUpdateProductForm from "@Eikochain/components/ui/create-update-product-form";
import useGetProductById from "@Eikochain/graphql/hooks/useGetProductById";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { useParams } from "react-router-dom";

const ProductDetail = () => {
  const { id: productId } = useParams<{ id: string }>();
  const breadcrumbs = [{
    name: "Edit product"
  }]
  const [result] = useGetProductById(productId as string);

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="p-4">
        {
          result?.data && <CreateOrUpdateProductForm instance={result.data.productById} />
        }
      </div>
    </BaseLayout>
  )
};

export default ProductDetail;
