export default function MarketingCanvasNoStepSelected() {
  return (
    <div className="flex items-center justify-center h-full w-full p-8">
      <div className="max-w-[400px]">
        <div className="flex flex-col bg-blue-50 text-blue-500 p-4 rounded-md">
          <h3>Add or select a step</h3>
          <p>Steps can be added or selected from the left-hand side. For each step, you'll be able to add your content here.</p>
        </div>
      </div>
    </div>
  );
}
