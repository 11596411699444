import { gql, useQuery } from "urql";

const GetEmissionFactorsAndSources = gql`
  query {
    emissionFactorsAndSources {
      id
      name
      activityType
      transportMethod
      sources {
        dataSource {
          name
          region
          year
          url
        }
      }
    }
  }
`;

const useGetEmissionFactorsAndSources = () => {
  const result = useQuery({
    query: GetEmissionFactorsAndSources
  });
  return result;
};

export default useGetEmissionFactorsAndSources;
