import { Button } from "@Eikochain/components/ui/button";
import { canvasHistoryAtom, canvasHistoryCurrentIndexAtom } from "@Eikochain/state/atoms/CanvasHistory";
import { useAtom, useAtomValue } from "jotai";
import { RedoIcon } from "lucide-react";

const FT_MARKETING_EDITOR_CONTROL_UNDO_AND_REDO = `${import.meta.env.VITE_FT_MARKETING_EDITOR_CONTROL_UNDO_AND_REDO}`;

const MarketingEditorControlRedoButton = () => {
  const actionHistory = useAtomValue(canvasHistoryAtom);
  const [canvasHistoryCurrentIndex, setCanvasHistoryCurrentIndex] = useAtom(canvasHistoryCurrentIndexAtom);
  const isDisabled = !(actionHistory.length && canvasHistoryCurrentIndex < actionHistory.length);
  const updateRedoClicked = () => {
    if (!isDisabled) {
      setCanvasHistoryCurrentIndex(canvasHistoryCurrentIndex + 1);
    }
  };
  return FT_MARKETING_EDITOR_CONTROL_UNDO_AND_REDO ? (
    <Button onClick={updateRedoClicked} disabled={isDisabled} icon={<RedoIcon />}>
      Redo
    </Button>
  ) : null;
};

export default MarketingEditorControlRedoButton;
