import { TextSchema } from '../text/types';

export const statisticTypesValues: {
    label: string,
    value: StatisticSchema["statisticType"]
}[] = [
        { label: 'Type A', value: 'A' },
        { label: 'Type B', value: 'B' },
        { label: 'Type C', value: 'C' },
    ];

export interface StatisticSchema extends TextSchema {
    text: string;
    statisticType: "A" | "B" | "C";
}
