import { whiteColor } from "@Eikochain/themes/constants";
import { CSSProperties, useMemo } from "react";
import { ICanvasElement } from "../types";
import renderContentItemFromType from "./RenderItem";

interface CanvasElementProps {
  element: ICanvasElement;
  isMobile?: boolean;
}

export default function CanvasElement({
  element,
  isMobile = false,
}: CanvasElementProps) {
  const style = useMemo(() => {
    // If element type is text and we're looking at mobile, we don't want the excess
    // height of a box to be visible, so we just use the fit-content value
    const height = isMobile && element.type === "text"
      ? "fit-content"
      : element.height;
    return {
      display: "inline-flex",
      position: "relative",
      background: whiteColor,
      top: isMobile ? "auto" : element.y,
      left: isMobile ? "auto" : element.x,
      width: isMobile ? "100%" : element.width,
      height,
    } as CSSProperties
  }, [isMobile, element.y, element.x, element.width, element.height]);

  return (
    <div style={style}>
      {renderContentItemFromType(element.type, element.content)}
    </div>
  );
}
