import { Activity } from "@Eikochain/__generated__/graphql";
import DataTable from "../data-table";
import columns from "./columns";

interface ActivitiesTableProps {
  data: Activity[];
  isFetching: boolean;
  showColumnToggler?: boolean;
  showRowCount?: boolean;
  hidePaginationButtons?: boolean;
  hideShippingCalculatorSpecificColumns?: boolean;
  isEditable?: boolean;
  isSelectable?: boolean;
  displayScope?: boolean;
  exportAvailable?: boolean;
  additionalFilters?: JSX.Element[]
  searchBy?: string;
}

export default function ActivitiesTable({
  data,
  isFetching,
  showColumnToggler,
  showRowCount,
  hidePaginationButtons,
  hideShippingCalculatorSpecificColumns = false,
  isEditable = false,
  isSelectable = false,
  displayScope = false,
  exportAvailable = false,
  additionalFilters,
  searchBy,
}: ActivitiesTableProps) {
  return (
    <DataTable
      data={data}
      isFetching={isFetching}
      showColumnToggler={showColumnToggler}
      showRowCount={showRowCount}
      hidePaginationButtons={hidePaginationButtons}
      columns={columns(
        hideShippingCalculatorSpecificColumns, isEditable, isSelectable, displayScope
      )}
      exportAvailable={exportAvailable}
      additionalFilters={additionalFilters}
      searchBy={searchBy}
    />
  )
}
