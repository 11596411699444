import { memo } from "react";

interface VideoPlayerProps {
  src: string;
}

const VideoPlayer = ({
  src
}: VideoPlayerProps) => {
  return (
    <iframe
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        pointerEvents: "none"
      }}
    ></iframe>
  )
};

export default memo(VideoPlayer);
