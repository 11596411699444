import { CableIcon, MoreHorizontalIcon, Trash2Icon } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../button";
import DeleteSupplierDialogContent from "../delete-supplier-dialog-content";
import { Dialog } from "../dialog";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../dropdown-menu";

interface SupplierActionsDropdownMenuProps {
    supplierId: string;
    reexecuteQuery: () => void;
}

export default function SupplierActionsDropdownMenu({
    supplierId,
    reexecuteQuery
}: SupplierActionsDropdownMenuProps) {
    const [deleteModal, setDeleteModal] = useState(false)
    return (
        <div onClick={e => e.stopPropagation()}>
            <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
                <DeleteSupplierDialogContent
                    supplierId={supplierId}
                    onFinish={() => {
                        reexecuteQuery()
                        setDeleteModal(false)
                    }}
                />
            </Dialog>
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="outline" className="ml-auto">
                        <MoreHorizontalIcon />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="gap-4">
                    <Link to={`/app/suppliers/requests/create/${supplierId}`}>
                        <DropdownMenuItem>
                            <CableIcon />
                            Create supplier data request
                        </DropdownMenuItem>
                    </Link>
                    <DropdownMenuItem onClick={() => setDeleteModal(true)}>
                        <Trash2Icon />
                        Delete
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    )
}
