import { Product } from "@Eikochain/__generated__/graphql";
import DataTable from "../data-table";
import columns from "./columns";

interface ProductsTableProps {
  data: Product[];
  isFetching: boolean;
  showColumnToggler?: boolean;
  showRowCount?: boolean;
  hidePaginationButtons?: boolean;
  withEmissions?: boolean;
  withActions?: boolean;
  withUrl?: boolean;
}

export default function ProductsTable({
  data,
  isFetching,
  showColumnToggler,
  showRowCount = true,
  hidePaginationButtons,
  withEmissions = false,
  withActions = true,
  withUrl = true,
}: ProductsTableProps) {
  return (
    <DataTable
      data={data}
      isFetching={isFetching}
      columns={columns(withEmissions, withActions, withUrl)}
      showRowCount={showRowCount}
      showColumnToggler={showColumnToggler}
      hidePaginationButtons={hidePaginationButtons}
    />
  )
}
