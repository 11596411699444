import { Control } from "react-hook-form";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../select";

interface FuelTypeFieldProps {
  control: Control<any>;
  fuelTypes: {label: string, value: string}[]
}

export default function FuelTypeField({
  control,
  fuelTypes
}: FuelTypeFieldProps) {
  return (
    <FormField
      control={control}
      name="fuelType"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Type of fuel</FormLabel>
          <FormDescription>If you use electric vehicles, please select the Electricity activity type instead to continue</FormDescription>
          <Select
            value={field.value}
            onValueChange={field.onChange}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder="The type of fuel" />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {fuelTypes.map(ft => (
                <SelectItem key={ft.value} value={ft.value}>{ft.label}</SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
