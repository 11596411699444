import { DownloadIcon } from "lucide-react";
import { Button } from "../button";
import { Dialog, DialogTrigger } from "../dialog";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../tooltip";
import ExportDataDialogContent, { ExportDataDialogContentProps } from "./export-data-dialog-content";

interface ExportDataButtonProps extends ExportDataDialogContentProps {
  customText?: string;
  disabled?: boolean;
  disabledText?: string;
}

export default function ExportDataButton({
  customText,
  disabled = false,
  disabledText,
  csvUploadId,
  activities
}: ExportDataButtonProps) {
  return (
    <Dialog>
      <DialogTrigger>
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <Button icon={<DownloadIcon />} disabled={disabled} variant="secondary">
                {customText ?? "Export emissions data"}
              </Button>
            </TooltipTrigger>
            <TooltipContent>{disabled ? disabledText : ""}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </DialogTrigger>
      <ExportDataDialogContent csvUploadId={csvUploadId} activities={activities} />
    </Dialog>
  )
}
