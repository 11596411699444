import { Button } from "@Eikochain/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@Eikochain/components/ui/dialog";
import { ScopePDF } from "@Eikochain/components/ui/pdfReports/scopePDF";
import ScopedActivityMetrics from "@Eikochain/components/ui/scoped-activity-metrics";
import ScopedActivityStepsSection from "@Eikochain/components/ui/scoped-activity-steps-section";
import useGetEmissionsBreakdownByScope from "@Eikochain/graphql/hooks/useGetEmissionsBreakdownByScope";
import useGetScopedActivitiesByOrganizationId from "@Eikochain/graphql/hooks/useGetScopedActivitiesByOrganizationId";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { DownloadIcon } from "lucide-react";

const PdfReport = () => {
    const [activityData] = useGetScopedActivitiesByOrganizationId();
    const [emissionsBreakdownData] = useGetEmissionsBreakdownByScope();

    if (activityData.fetching || emissionsBreakdownData.fetching) {
        return <div>Loading...</div>
    }

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button className="max-w-fit" disabled={activityData.data?.scopedActivitiesByOrganizationId?.length == 0 || emissionsBreakdownData.data?.emissionsBreakdownByScope?.length == 0}>
                    Generate PDF Report
                </Button>
            </DialogTrigger>
            <DialogContent className="h-[95vh] flex flex-col">
                <div className="flex-grow flex flex-col gap-2">
                    <PDFViewer className="flex-grow rounded-lg">
                        <ScopePDF
                            activities={activityData.data?.scopedActivitiesByOrganizationId}
                            emissionsBreakdown={emissionsBreakdownData.data?.emissionsBreakdownByScope}
                        />
                    </PDFViewer>
                    <PDFDownloadLink
                        document={<ScopePDF
                            activities={activityData.data?.scopedActivitiesByOrganizationId}
                            emissionsBreakdown={emissionsBreakdownData.data?.emissionsBreakdownByScope}
                        />}
                        fileName="scope-report.pdf"
                    >
                        <Button className="flex gap-2">
                            <DownloadIcon className="w-6 h-6" />
                            Download PDF
                        </Button>
                    </PDFDownloadLink>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default function CompanyCalculator() {
    const breadcrumbs = [{
        name: "Scope Emissions Calculator"
    }]
    return (
        <BaseLayout breadcrumbs={breadcrumbs}>
            <div className="mt-6 flex flex-col p-4 gap-8 h-full">
                <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-6 md:gap-12">
                        <PdfReport />
                        <ScopedActivityMetrics />
                        <ScopedActivityStepsSection />
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
};
