import { Button } from "@Eikochain/components/ui/button";
import { Dialog, DialogTrigger } from "@Eikochain/components/ui/dialog";
import { CopyIcon } from "lucide-react";
import { useState } from "react";
import CopyLinkModal from "../Modals/CopyLinkModal";

export default function MarketingEditorControlCopyLinkButton ({ disabled = false }) {
  const [open, setOpen] = useState(false);
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button disabled={disabled} icon={<CopyIcon />}>
          Copy link
        </Button>
      </DialogTrigger>
      <CopyLinkModal id="e2eyeyygwq" />
    </Dialog>
  );
};
