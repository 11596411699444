import { cn } from "@Eikochain/utils";
import { useState } from "react";
import { Button } from "../button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../dialog";
import InterestCallbackForm from "../interest-callback-form";

interface InterestCallbackButtonProps {
  variant?: "huge";
  className?: string;
  disabled?: boolean;
  customText?: string;
}

export default function InterestCallbackButton({
  variant,
  className,
  disabled = false,
  customText
}: InterestCallbackButtonProps) {
  const [openInterestDialog, setOpenInterestDialog] = useState(false)
  return (
    <Dialog open={openInterestDialog} onOpenChange={setOpenInterestDialog}>
      <DialogTrigger asChild>
        <Button
          disabled={disabled}
          variant="fun"
          className={cn(
            "w-full md:max-w-[300px] from-indigo-500 to-indigo-700",
            variant === "huge" ? "p-8 text-2xl px-24 rounded-xl" : "",
            className
          )}
        >
          {customText ?? "Talk to us"}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>Tell us a bit about you</DialogTitle>
        <DialogDescription>We'll get back to you in under a day</DialogDescription>
        <InterestCallbackForm onSuccess={() => setOpenInterestDialog(false)} />
      </DialogContent>
    </Dialog>
  )
}
