import { EmissionCalculationRange, EmissionsByActivityType } from "@Eikochain/__generated__/graphql";
import useGetEmissionsBreakdownByProductId from "@Eikochain/graphql/hooks/useGetEmissionsBreakdown";
import { carbonActivitiesAtom } from "@Eikochain/state/atoms/CarbonActivitiesAtom";
import { getToDecimalPlaces } from "@Eikochain/utils";
import { useAtomValue } from "jotai";
import { CalculatorIcon, GaugeIcon, GlobeIcon, PackageIcon, ZapIcon } from "lucide-react";
import { useEffect, useState } from "react";
import CarbonCountMetric from "./carbon-count-metric";

interface ICarbonActivityMetrics {
  productId: string;
}

type EmissionCalculationRangeOrUndef = EmissionCalculationRange | undefined;

export default function CarbonActivityMetrics ({ productId }: ICarbonActivityMetrics) {
  const [totalMetrics, setTotalMetrics] = useState<EmissionCalculationRangeOrUndef>();
  const [processMetrics, setProcessMetrics] = useState<EmissionCalculationRangeOrUndef>();
  const [transportMetrics, setTransportMetrics] = useState<EmissionCalculationRangeOrUndef>();
  const [packagingMetrics, setPackagingMetrics] = useState<EmissionCalculationRangeOrUndef>();
  const [electricityMetrics, setElectricityMetrics] = useState<EmissionCalculationRangeOrUndef>();
  const [result, reexecuteQuery] = useGetEmissionsBreakdownByProductId(productId);
  const carbonActivities = useAtomValue(carbonActivitiesAtom);

  useEffect(() => {
    const emissionsBreakdown = result?.data?.productById?.emissionsBreakdown;
    function getEmissionsBreakdownForCategory(category: string) {
      return emissionsBreakdown?.find((item: EmissionsByActivityType) => item.category === category)?.emissions;
    }
    setTotalMetrics(getEmissionsBreakdownForCategory("total"));
    setProcessMetrics(getEmissionsBreakdownForCategory("process"));
    setTransportMetrics(getEmissionsBreakdownForCategory("transport"));
    setPackagingMetrics(getEmissionsBreakdownForCategory("packaging"));
    setElectricityMetrics(getEmissionsBreakdownForCategory("electricity"));
  }, [result?.data]);

  useEffect(() => {
    reexecuteQuery()
  }, [carbonActivities?.length])

  const activitiesCountForType = (type: string) => {
    return carbonActivities.filter((carbonActivity) => carbonActivity.activityType === type).length;
  };
  const transportActivitiesCount = activitiesCountForType("Transport");
  const packagingActivitiesCount = activitiesCountForType("Packaging");
  const processActivitiesCount = activitiesCountForType("Process");
  const electricityActivitiesCount = activitiesCountForType("Electricity");

  const notAllRequiredActivitiesCreated = [
    transportActivitiesCount,
    packagingActivitiesCount,
    processActivitiesCount,
  ].some((activity) => activity === 0);

  return (
    <div className="flex flex-wrap gap-4">
      <CarbonCountMetric
        icon={<PackageIcon />}
        title="Packaging"
        showError={!packagingActivitiesCount}
        metricValue={packagingMetrics ? getToDecimalPlaces(packagingMetrics?.results?.[0]?.co2e as number, 2).toString() : "--"}
      />
      <CarbonCountMetric
        icon={<GlobeIcon />}
        title="Transport"
        showError={!transportActivitiesCount}
        metricValue={transportMetrics ? getToDecimalPlaces(transportMetrics?.results?.[0]?.co2e as number, 2).toString() : "--"}
      />
      <CarbonCountMetric
        icon={<GaugeIcon />}
        title="Materials"
        showError={!processActivitiesCount}
        metricValue={processMetrics ? getToDecimalPlaces(processMetrics?.results?.[0]?.co2e as number, 2).toString() : "--"}
      />
      <CarbonCountMetric
        icon={<ZapIcon />}
        title="Electricity"
        showWarning={!electricityActivitiesCount}
        metricValue={electricityMetrics ? getToDecimalPlaces(electricityMetrics?.results?.[0]?.co2e as number, 2).toString() : "--"}
      />
      <CarbonCountMetric
        icon={<CalculatorIcon />}
        title="Total"
        showError={notAllRequiredActivitiesCreated}
        metricValue={totalMetrics ? getToDecimalPlaces(totalMetrics?.results?.[0].co2e as number, 2).toString() : "--"}
      />
    </div>
  );
};
