import { Activity } from "@Eikochain/__generated__/graphql";
import H5 from "@Eikochain/components/atoms/H5";
import Paragraph from "@Eikochain/components/atoms/P";
import { ColumnDef } from "@tanstack/react-table";
import { AlertOctagonIcon, ArrowUpDown, CalculatorIcon } from "lucide-react";
import ActivityParametersOverview from "../activity-step/activity-parameters-overview";
import ActivityStepActionsDropdown from "../activity-step/activity-step-actions-dropdown";
import ActivityUnitsOverview from "../activity-step/activity-units-overview";
import ActivityTypeIcon from "../activity-type-icon";
import { Button } from "../button";
import { Checkbox } from "../checkbox";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../dialog";
import EmissionsComparisonTableByActivityWithData from "../emissions-comparison-table/with-data-wrapper";
import RouteFlags from "../route-flags";
import ScopeBadge from "../scope-badge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../tooltip";

const columns = (
  hideShippingCalculatorSpecificColumns: boolean,
  isEditable: boolean,
  isSelectable: boolean,
  displayScope: boolean,
): ColumnDef<Activity>[] => {
  const selectColumn: ColumnDef<Activity> = {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllRowsSelected() ||
          (table.getIsSomeRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger>
            <Checkbox
              checked={row.getIsSelected()}
              onCheckedChange={(value) => row.toggleSelected(!!value)}
              disabled={!row.getCanSelect()}
              aria-label="Select row"
            />
          </TooltipTrigger>
          <TooltipContent>
            {row.getCanSelect() ? "Select" : "Cannot be selected"}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ),
    enableSorting: false,
    enableHiding: false,
  }
  const scopeColumn: ColumnDef<Activity> = {
    id: "scope",
    accessorFn: (originalRow) => originalRow.scope,
    header: "Scope",
    cell: ({ row }) => row.getValue("scope") ? <ScopeBadge scope={row.getValue("scope")} /> : null,
  }
  let activityColumns: ColumnDef<Activity>[] = [
    {
      accessorKey: "name",
      header: ({ column }) => (
        <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="flex flex-col">
          <div>{row.getValue("name")}</div>
          <Paragraph><ActivityUnitsOverview activity={row.original} /></Paragraph>
        </div>
      ),
    },
    {
      accessorKey: "activityType",
      header: "Type",
      cell: ({ row }) => (
        <div className="flex flex-row gap-4 items-center">
          <ActivityTypeIcon activityType={row.original.activityType} transportMethod={row.original.transportMethod!} />
          <div className="hidden lg:flex flex-col">
            <H5>{row.original.activityType}</H5>
            <Paragraph><ActivityParametersOverview activity={row.original} /></Paragraph>
          </div>
        </div>
      ),
    },
  ]
  const shippingCalculatorSpecificColumns: ColumnDef<Activity>[] = [
    {
      accessorKey: "weight",
      header: ({ column }) => {
        return (
          <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            Weight (kg)
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => <div className="lowercase">{row.getValue("weight")}</div>,
    },
    {
      accessorKey: "dateOfOrder",
      header: ({ column }) => {
        return (
          <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            Order date
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => <div className="lowercase">{row.getValue("dateOfOrder")}</div>,
    },
    {
      accessorKey: "client",
      header: () => <div>Client</div>,
      cell: ({ row }) => <div className="lowercase">{row.getValue("client")}</div>,
    },
  ]
  const routeAndEmissionsColumns: ColumnDef<Activity>[] = [
    {
      accessorKey: "route",
      header: () => <div>Route</div>,
      cell: ({ row }) => row.getValue("route") ? <RouteFlags route={row.getValue("route")} /> : null,
    },
    {
      id: "co2e",
      accessorFn: (originalRow) => originalRow.mostRecentEmissionCalculation?.co2e,
      header: ({ column }) => {
        return (
          <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            CO2<sub>e</sub> (kg)
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div className="font-mono">
          {row.getValue("co2e") ?? (
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <AlertOctagonIcon className="text-red-500" />
                </TooltipTrigger>
                <TooltipContent>Your emissions are not up to date. Please re-calculate.</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        return (
          <div className="flex flex-row gap-2">
            {
              row.getValue("co2e") !== null ? (
                <Dialog>
                  <DialogTrigger>
                    <TooltipProvider>
                      <Tooltip delayDuration={0}>
                        <TooltipTrigger asChild>
                          <CalculatorIcon />
                        </TooltipTrigger>
                        <TooltipContent>View emissions breakdown</TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </DialogTrigger>
                  <DialogContent className="md:max-w-[900px]">
                    <DialogHeader>
                      <DialogTitle>Emissions breakdown</DialogTitle>
                    </DialogHeader>
                    <div className="overflow-x-auto">
                      <EmissionsComparisonTableByActivityWithData activityId={row.original.djangoId} />
                    </div>
                  </DialogContent>
                </Dialog>
              ) : null
            }
            {isEditable ? (
              <ActivityStepActionsDropdown
                activity={row.original}
                onCloneActivity={() => null}
              />
            ) : null}
          </div>
        );
      },
    }
  ]
  if (!hideShippingCalculatorSpecificColumns) {
    activityColumns = [...activityColumns, ...shippingCalculatorSpecificColumns]
  }
  if (displayScope) {
    activityColumns = [scopeColumn, ...activityColumns]
  }
  if (isSelectable) {
    activityColumns = [selectColumn, ...activityColumns]
  }
  return [...activityColumns, ...routeAndEmissionsColumns]
};

export default columns;
