import { EmissionFactorActivityFields, EmissionFactorSource } from "@Eikochain/__generated__/graphql"
import { InfoIcon } from "lucide-react"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../table"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../tooltip"

interface FactorInfoTableProps {
  data: EmissionFactorSource
}

export default function FactorInfoTable({
  data
}: FactorInfoTableProps) {
  const fields: {label: string, value: keyof EmissionFactorActivityFields}[] = [
    {
      label: "Activity type",
      value: "activityType"
    },
    {
      label: "Disposal method",
      value: "disposalMethod"
    },
    {
      label: "Fuel type",
      value: "fuelType"
    },
    {
      label: "Service type",
      value: "serviceType"
    },
    {
      label: "Good type",
      value: "goodsType"
    },
    {
      label: "Material",
      value: "material"
    },
    {
      label: "Process",
      value: "process"
    },
    {
      label: "Transport method",
      value: "transportMethod"
    },
    {
      label: "Distance (km) more than or equal to",
      value: "distanceGte"
    },
    {
      label: "Distance (km) less than or equal to",
      value: "distanceLte"
    },
    {
      label: "Distance (km) greater than",
      value: "distanceGt"
    },
    {
      label: "Distance (km) less than",
      value: "distanceLt"
    },
  ]
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>
            <div className="flex items-center gap-2">
              Parameter
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <InfoIcon />
                  </TooltipTrigger>
                  <TooltipContent>
                    Parameters are how we match your activity to an emission factor
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </TableHead>
          <TableHead>
            <div className="flex items-center gap-2">
              Value
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <InfoIcon />
                  </TooltipTrigger>
                  <TooltipContent>
                    These are the internal values we use, just for transparency
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {fields.filter(f => data.factor.activityFields[f.value]).map((f) => (
          <TableRow key={f.value}>
            <TableCell>{f.label}</TableCell>
            <TableCell>{data.factor.activityFields[f.value] ?? "--"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
