import CreateOrUpdateProductForm from "@Eikochain/components/ui/create-update-product-form";
import BaseLayout from "@Eikochain/layouts/BaseLayout";

export default function AddProduct () {
  const breadcrumbs = [{
    name: "Add or Import a new product"
  }]
  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="p-4">
        <CreateOrUpdateProductForm />
      </div>
    </BaseLayout>
  )
};
