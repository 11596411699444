import { fontColorAtom, fontFamilyAtom, fontSizeAtom } from "@Eikochain/state/atoms/CanvasMenuStyles";
import { Dropdown, MenuProps } from 'antd';
import { useAtomValue } from "jotai";
import { ChevronDownIcon, ChevronUpIcon, CircleEllipsisIcon, CopyIcon, EditIcon, Trash2Icon } from "lucide-react";
import { CSSProperties, useState } from "react";
import RenameMenuStepModal from "../Modals/RenameMenuStepModal";
import StepContainer from "./step-container";

interface CanvasMenuStepProps {
  data: any;
  isFirst: boolean;
  isLast: boolean;
  isSelected: boolean;
  isEditing: boolean;
  onClick: (stepLocalId: number, stepServerId: string) => void;
  onRename: (stepLocalId: number, newStepName: string) => void;
  onDuplicate: (stepLocalId: number) => void;
  onDelete: (stepLocalId: number) => void;
  onReorder: (stepLocalId: number, direction: "up" | "down") => void;
}

const CanvasMenuStep = ({
  data,
  isFirst,
  isLast,
  isSelected = false,
  isEditing = false,
  onClick,
  onRename,
  onDuplicate,
  onDelete,
  onReorder,
}: CanvasMenuStepProps) => {
  const [renamingModalOpen, setRenamingModalOpen] = useState(false);
  const fontColor = useAtomValue(fontColorAtom);
  const fontFamily = useAtomValue(fontFamilyAtom);
  const fontSize = useAtomValue(fontSizeAtom);

  const fontStyle = {
    color: fontColor,
    fontFamily,
    fontSize,
    whiteSpace: "nowrap"
  } as CSSProperties;

  const renameMenuItem: MenuProps['onClick'] = (e) => {
    setRenamingModalOpen(true);
  };

  const duplicateMenuItem: MenuProps['onClick'] = (e) => {
    onDuplicate(data.localId)
  };

  const deleteMenuItem: MenuProps['onClick'] = (e) => {
    onDelete(data.localId)
  };

  const menuItems: MenuProps['items'] = [
    {
      label: 'Rename',
      key: '1',
      icon: <EditIcon />,
      onClick: renameMenuItem,
    },
    {
      label: 'Duplicate',
      key: '2',
      icon: <CopyIcon />,
      onClick: duplicateMenuItem,
    },
    {
      label: 'Delete',
      key: '3',
      icon: <Trash2Icon />,
      danger: true,
      onClick: deleteMenuItem,
    },
  ];
  
  const menuProps = {
    items: menuItems
  };

  return (
    <div>
      <StepContainer
        isSelected={isSelected}
        onClick={() => onClick(data.localId, data.serverId)}
      >
        {
          isEditing && (
            <div className="p-4">
              <Dropdown menu={menuProps} trigger={['click']}>
                <CircleEllipsisIcon style={{ color: fontColor }} />
              </Dropdown>
            </div>
          )
        }
        <div className="p-4 w-full overflow-hidden text-ellipsis" style={{
          color: fontColor
        }}>
          <span style={fontStyle}>{data.title}</span>
        </div>
        {
          isEditing && (
            <div className="p-2">
              <div className="flex flex-col">
                <ChevronUpIcon
                  style={{ color: fontColor, opacity: isFirst ? 0.5 : 1 }}
                  onClick={() => onReorder(data.localId, "up")}
                />
                <ChevronDownIcon
                  style={{ color: fontColor, opacity: isLast ? 0.5 : 1 }}
                  onClick={() => onReorder(data.localId, "down")}
                />
              </div>
            </div>
          )
        }
      </StepContainer>
      {
        isEditing && (
          <RenameMenuStepModal
            defaultStepName={data.title}
            open={renamingModalOpen}
            onFinish={(stepName) => {onRename(data.localId, stepName); setRenamingModalOpen(false)}}
            onCancel={() => setRenamingModalOpen(false)}
          />
        )
      }
    </div>
  )
}

export default CanvasMenuStep;
