
import CreateOrUpdateMarketingEditorForm from "@Eikochain/components/organisms/CreateOrUpdateMarketingEditorForm";
import BaseLayout from "@Eikochain/layouts/BaseLayout";

const AddMarketingPanels = () => {
  const breadcrumbs = [{
    name: "Add a new marketing canvas"
  }]
  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="p-4">
        <CreateOrUpdateMarketingEditorForm />
      </div>
    </BaseLayout>
  )
};

export default AddMarketingPanels;
