import AddSupplierInformationRequestButton from "@Eikochain/components/ui/add-supplier-information-request-button";
import SupplierInformationRequestsTableWithData from "@Eikochain/components/ui/suppliers-information-requests-table/with-data-wrapper";
import { GetSupplierByIdQuery } from "@Eikochain/graphql/hooks/useGetSupplierById";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { useParams } from "react-router-dom";
import { useQuery } from "urql";

export default function SupplierDetail() {
    const { id } = useParams<{ id: string }>();

    const [result, refetch] = useQuery({
        query: GetSupplierByIdQuery,
        variables: { id },
        pause: id === undefined,
    });

    const supplierById: { name?: string, id?: string } = result?.data?.supplierById;
    const breadcrumbs = [
        {
            name: "Suppliers",
            url: "/app/suppliers/"
        },
        {
            name: `${supplierById?.name}`,
        },
    ]

    return (
        <BaseLayout breadcrumbs={breadcrumbs}>
            <div className="w-full p-4">
                <div className="flex flex-col gap-4 mt-12">
                    <div className="w-full flex flex-row items-center justify-between">
                        <h1 className="text-3xl">{supplierById?.name}</h1>
                        <AddSupplierInformationRequestButton supplierId={supplierById?.id} />
                    </div>
                    <div className="w-full py-8">
                        <h3 className="text-lg pb-6">Data requests to this supplier</h3>
                        {supplierById?.id && <SupplierInformationRequestsTableWithData
                            refetch={() => refetch({ requestPolicy: 'network-only' })}
                            bySupplierId={supplierById.id}
                        />}
                    </div>
                </div>
            </div>
        </BaseLayout>
    )
}
