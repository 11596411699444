import * as yup from "yup";

export const formSchema = (requiresScope: boolean) => yup.object().shape({
  name: yup.string().required("Enter a name"),
  activityType: yup.string().oneOf(
    ["process", "transport", "packaging", "electricity", "fuel", "waste", "services", "goods"],
    "Activity type is required"
  ).required("Activity type is required"),
  scope: yup.string().oneOf(
    ["scope_1", "scope_2", "scope_3"],
    "Scope is required"
  ).when(
    // This isn't an actual field, but a dummy used so we canconditionally require the scope
    // depending on the truthyness of `requiresScope`
    "$requiresScope", {
      is: () => requiresScope,
      then: (schema) => schema.required("Scope is required"),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  transportMethod: yup.string().when(
    'activityType', {
      is: (val: string) => val === "transport",
      then: (schema) => schema.required("Transport method is required"),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  fuelType: yup.string().when(
    'activityType', {
      is: (val: string) => val === "fuel",
      then: (schema) => schema.required("Fuel type is required"),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  serviceType: yup.string().when(
    'activityType', {
      is: (val: string) => val === "services",
      then: (schema) => schema.required("Service type is required"),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  goodsType: yup.string().when(
    'activityType', {
      is: (val: string) => val === "goods",
      then: (schema) => schema.required("Goods type is required"),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  disposalMethod: yup.string().when(
    'activityType', {
      is: (val: string) => val === "waste",
      then: (schema) => schema.required("Disposal method is required"),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  origin: yup.object().shape({
    lat: yup.number(),
    lng: yup.number(),
    region: yup.string(),
    countryCode: yup.string(),
  }).default(undefined).when(
    'activityType', {
      is: (val: string) => val === "transport",
      then: (schema) => schema.required("Origin required"),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  destination: yup.object().shape({
    lat: yup.number(),
    lng: yup.number(),
    region: yup.string(),
    countryCode: yup.string(),
  }).default(undefined).when(
    'activityType', {
      is: (val: string) => val === "transport",
      then: (schema) => schema.required("Destination required"),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  distance: yup.number().when(
    'activityType', {
      is: (val: string) => ["transport", "fuel"].includes(val),
      then: (schema) => schema.required("Distance required"),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  material: yup.string().when(
    'activityType', {
      is: (val: string) => ["packaging", "process", "waste"].includes(val),
      then: (schema) => schema.required("Material is required"),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  process: yup.string().when(
    'activityType', {
      is: (val: string) => val === "process",
      then: (schema) => schema.required("Process is required"),
      otherwise: (schema) => schema.nullable(),
    }
  ),
  energy: yup.number().nullable().min(
    0.01, "Miniumum of 0.01 required"
  ).transform((value) => Number.isNaN(value) ? null : value ).nullable().positive(
    "Must be positive"
  ).when(
    "$inputParameters", ([inputParameters], schema) => {
      if (inputParameters === "energy") {
        return schema.required("Energy is required")
      }
      return schema
    }
  ),
  weight: yup.number().nullable().min(
    0.01, "Miniumum of 0.01 required"
  ).transform((value) => Number.isNaN(value) ? null : value ).nullable().positive(
    "Must be positive"
  ).when(
    "$inputParameters", ([inputParameters], schema) => {
      if (inputParameters === "weight") {
        return schema.required("Weight is required")
      }
      return schema
    }
  ),
  spend: yup.number().nullable().min(
    0.01, "Miniumum of 0.01 required"
  ).transform((value) => Number.isNaN(value) ? null : value ).nullable().positive(
    "Must be positive"
  ).when(
    "$inputParameters", ([inputParameters], schema) => {
      if (inputParameters === "spend") {
        return schema.required("Spend is required")
      }
      return schema
    }
  ),
  currency: yup.object().shape({
    currencyCode: yup.string(),
    currencyName: yup.string(),
  }).default({
    currencyCode: "GBP",
    currencyName: "Pound"
  }).when(
    '$inputParameters', ([inputParameters], schema) => {
      if (inputParameters === "spend") {
        return schema.required("Currency is required")
      }
      return schema
    }
  ),
  region: yup.string().optional().nullable()
});
