import * as yup from "yup";
 
export type SchemaInputs = {
  email: string;
  name: string;
  company: string;
  agreeEmail: boolean;
};

const agreeEmailMessage = "This box has to be checked so we can reach out!";

export const formSchema = yup.object({
  email: yup.string().email("Valid email required").required("Required"),
  name: yup.string().required("Required"),
  company: yup.string().required("Required"),
  agreeEmail: yup.boolean().default(true)
    .required(agreeEmailMessage)
    .oneOf([true], agreeEmailMessage)
})
