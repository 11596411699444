import CustomPieChart, { PieChartProps } from "./custom-pie-chart";

const colorsForTransportMethod: {[key: string]: string} = {
  "Air Freight": "#67e8f9",
  "Rail Freight": "#93c4fd",
  "Road Freight": "#c4b5fd",
  "Sea Freight": "#f0abfc",
}

export default function TransportMethodBreakdownPieChart({
  data,
  isFetching,
  includeLegend,
}: PieChartProps) {
  const dataWithColors = data.map((d) => ({
    ...d,
    fill: colorsForTransportMethod[d.label]
  }))

  return (
    <CustomPieChart
      data={dataWithColors}
      dataTypeDisplayPluralised="activities"
      isFetching={isFetching}
      includeLegend={includeLegend}
    />
  )
}
