import { gql, useQuery } from 'urql';

const GetTransportMethodBreakdownQuery = gql`
  query {
    transportMethodBreakdown {
      name
      count
    }
  }
`;

const useGetTransportMethodBreakdown = () => {
  const result = useQuery({
    query: GetTransportMethodBreakdownQuery,
  })
  return result;
}

export default useGetTransportMethodBreakdown;
