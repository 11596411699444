export type ActivityTypesForInterface = "company" | "product"

export interface ActivityTypesAsValueLabels {
  value: string;
  label: string;
}

export function getActivityTypesForInterface(
  i: ActivityTypesForInterface
): ActivityTypesAsValueLabels[] {
  let baseActivityTypes = [
    {
      value: "transport",
      label: "Transport"
    },
    {
      value: "electricity",
      label: "Electricity"
    },
    {
      value: "process",
      label: "Material"
    },
  ]
  if (i === "company") {
    return [...baseActivityTypes,
      {
        value: "fuel",
        label: "Fuel"
      },
      {
        value: "waste",
        label: "Waste"
      },
      {
        value: "services",
        label: "Services"
      },
      {
        value: "goods",
        label: "Goods"
      },
    ]
  }
  return [...baseActivityTypes, 
    {
      value: "packaging",
      label: "Packaging"
    },
  ]
}
