import { gql, useQuery } from 'urql';

const GetAvailableIntegrationTypesQuery = gql`
  query {
    availableIntegrationTypes {
      id: djangoId
      name
      logo
    }
  }
`;

const useGetAvailableIntegrationTypes = () => {
  const result = useQuery({
    query: GetAvailableIntegrationTypesQuery
  })
  return result;
}

export default useGetAvailableIntegrationTypes;
