import { gql } from 'urql';

export const CreateSupplierInformationRequestMutation = gql`
  mutation CreateSupplierInformationRequest($input: CreateSupplierInformationRequestInput!) {
    createSupplierInformationRequest(input: $input) {
        success
        reason
        id
      }
    }
`;
