import { Button } from "@Eikochain/components/ui/button";
import ProductsTable from "@Eikochain/components/ui/products-table";
import useGetProducts from "@Eikochain/graphql/hooks/useGetProducts";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { PlusIcon } from "lucide-react";
import { Link } from "react-router-dom";

export default function ProductsList() {
  const [result] = useGetProducts();
  const breadcrumbs = [{
    name: "Products"
  }]

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="w-full">
        {
          result.data?.products && (
            <ProductsTable
              data={result.data.products} isFetching={result.data?.isFetching}
            />
          )
        }
        <Link to="/app/products/add/">
          <Button icon={<PlusIcon />}>Add or import a new product</Button>
        </Link>
      </div>
    </BaseLayout>
  );
}
