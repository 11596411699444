import { gql, useQuery } from "urql";

const GetEmissionCalculationRangeByActivityIdQuery = gql`
  query ($id: String!) {
    emissionCalculationRangeByActivityId(id: $id) {
      results {
        id
        co2e
        isOriginal
        subject
        subjectType
        source
        sourceDataset {
          name
          url
        }
        region
        year
      }
    }
  }
`;

const useGetEmissionCalculationRangeByActivityId = (id: string) => {
  const result = useQuery({
    query: GetEmissionCalculationRangeByActivityIdQuery,
    variables: {
      id,
    },
  });
  return result;
};

export default useGetEmissionCalculationRangeByActivityId;
