import { Control } from "react-hook-form";
import { FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { RadioButtonGroup, RadioGroupItemButton } from "../../radio-group";
import { ActivityTypesAsValueLabels } from "../utils";

interface ActivityTypeFieldProps {
  control: Control<any>;
  disabled: boolean;
  activityTypes: ActivityTypesAsValueLabels[];
}

export default function ActivityTypeField({
  control,
  disabled = false,
  activityTypes,
}: ActivityTypeFieldProps) {
  return (
    <FormField
      control={control}
      name="activityType"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Activity type</FormLabel>
          <RadioButtonGroup {...field} value={field.value} onValueChange={field.onChange}>
            {activityTypes.map(availableActivityType => (
              <RadioGroupItemButton
                value={availableActivityType.value}
                label={availableActivityType.label}
                disabled={disabled}
              />
            ))}
          </RadioButtonGroup>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
