import { useAtom } from "jotai";
import { elementsForCurrentCanvasAtom, selectedElementIdAtom } from "@Eikochain/state/atoms/CanvasContent";
import { ICanvasElement } from "@Eikochain/components/organisms/MarketingEditor/types";

const useDeleteSelectedElement = () => {
  const [elements, setElements] = useAtom(elementsForCurrentCanvasAtom);
  const [selectedElement, setSelectedElement] = useAtom(selectedElementIdAtom);

  function execute(variables = {}) {
    const elementIndex = elements.findIndex(el => el.localId === selectedElement);
    setElements([
      ...elements.slice(0, elementIndex),
      ...elements.slice(elementIndex + 1),
    ]);
    setSelectedElement(null);
  }

  return execute;
};

export default useDeleteSelectedElement;
