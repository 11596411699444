import { AuthenticateUserMutation } from "@Eikochain/graphql/hooks/useAuthenticateUser"
import useLogin from "@Eikochain/hooks/useLogin"
import { yupResolver } from "@hookform/resolvers/yup"
import { KeyIcon } from "lucide-react"
import { useForm } from "react-hook-form"
import { useMutation } from "urql"
import { Button } from "../button"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../form"
import { Input } from "../input"
import { PasswordInput } from "../password-input"
import { passwordAuthenticationFormSchema } from "./schema"

export default function PasswordAuthenticationForm () {
  const login = useLogin();
  const form = useForm({
    resolver: yupResolver(passwordAuthenticationFormSchema),
    defaultValues: {
      email: "",
      password: ""
    }
  })
  const [mutationResult, executeMutation] = useMutation(AuthenticateUserMutation);
  function onSubmit (values: any) {
    executeMutation({ email: values.email, password: values.password }).then((result) => {
      const { data, error: resultError } = result;
      if (resultError) {
        console.log(resultError);
      } else {
        if (data.authenticateUser.fieldErrors) {
          form.setError("root", {message: "The email or password provided did not match"});
        }
        if (data.authenticateUser.user) {
          login(data.authenticateUser.authToken, data.authenticateUser.user, "/app/products/");
        }
      }
    });
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-4 w-full max-w-[500px]">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input {...field} placeholder="name@company.com" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <PasswordInput {...field} placeholder="Your strong password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormMessage>
          {form.formState.errors.root?.message}
        </FormMessage>
        <Button type="submit" disabled={mutationResult.fetching} icon={<KeyIcon />}>
          Submit
        </Button>
      </form>
    </Form>
  )
}
