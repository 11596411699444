import { Report } from "@Eikochain/__generated__/graphql";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, FileDownIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../button";
import ReportTypeIcon from "../report-type-icon";

const columns: ColumnDef<Report>[] = [
  {
    accessorKey: "reportType",
    header: ({ column }) => {
      return (
        <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Report type
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="flex flex-row items-center gap-4">
        <ReportTypeIcon reportType={row.getValue("reportType")} />
        <span>{(row.getValue("reportType") as string).toUpperCase()}</span>
      </div>
    ),
  },
  {
    accessorKey: "createdBy",
    header: ({ column }) => {
      return (
        <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Generated by
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div>{row.original.createdBy.email}</div>,
  },
  {
    accessorKey: "location",
    header: "Download",
    cell: ({ row }) => (
      <Link to={row.getValue("location")} target="_blank">
        <Button className="px-0" variant="link" icon={<FileDownIcon />}>
          Download
        </Button>
      </Link>
    ),
  },
]

export default columns;
