import Paragraph from "@Eikochain/components/atoms/P";
import { InfoIcon } from "lucide-react";
import { FormControl, FormItem, FormLabel, FormMessage } from "../../form";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../tooltip";

interface DerivedDistanceFieldProps {
  distance?: number;
}

export default function DerivedDistanceField({
  distance
}: DerivedDistanceFieldProps) {
  return (
    <FormItem>
      <FormLabel>
        Distance travelled
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <InfoIcon />
            </TooltipTrigger>
            <TooltipContent>
              This is calculated by the origin and destination entered
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </FormLabel>
      <FormControl>
        <Paragraph>{distance ?? "--"} km</Paragraph>
      </FormControl>
      <FormMessage />
    </FormItem>
  )
}
