import { gql, useQuery } from 'urql';

const GetEmissionFactorByIdQuery = gql`
  query($id: String!) {
    emissionFactorById(id: $id) {
      id
      name
      sources {
        id
        dataSource {
          name
          year
          region
        }
      }
      similarEfs {
        id
        name
      }
    }
  }
`;

const useGetEmissionFactorById = (id: string) => {
  const result = useQuery({
    query: GetEmissionFactorByIdQuery,
    variables: {
      id
    }
  })
  return result;
}

export default useGetEmissionFactorById;
