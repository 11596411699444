import { gql, useMutation } from 'urql';

export const CreateOrUpdatePanelByIdMutation = gql`
  mutation CreateOrUpdatePanelById($input: CreateOrUpdatePanelByIdInput!) {
    createOrUpdatePanelById(input: $input) {
      ... on CreateOrUpdatePanelByIdSuccess {
        panel {
          name
        }
      }
      ... on CreateOrUpdatePanelByIdErrors {
        fieldErrors {
          fieldName
          errors
        }
      }
    }
  }
`;
