import { gql, useQuery } from 'urql';

const CalculateDistanceBetweenPointsQuery = gql`
  query (
    $originLat: Float,
    $originLng: Float,
    $destinationLat: Float,
    $destinationLng: Float,
  ) {
    calculateDistanceBetweenPoints(
      originLat: $originLat,
      originLng: $originLng,
      destinationLat: $destinationLat,
      destinationLng: $destinationLng
    ) {
      distance
      unit
    }
  }
`;

const useCalculateDistanceBetweenPoints = (
  originLat: number | undefined,
  originLng: number | undefined,
  destinationLat: number | undefined,
  destinationLng: number | undefined,
) => {
  const result = useQuery({
    query: CalculateDistanceBetweenPointsQuery,
    variables: {
      originLat,
      originLng,
      destinationLat,
      destinationLng
    }
  })
  return result;
}

export default useCalculateDistanceBetweenPoints;
