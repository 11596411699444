import { Button } from "@Eikochain/components/ui/button";
import { logoUrlAtom } from "@Eikochain/state/atoms/CanvasMenuStyles";
import { useAtom } from "jotai";
import { PlusCircleIcon } from "lucide-react";
import { ChangeEvent } from "react";

const MarketingEditorControlLogoButton = () => {
  const [logoUrl, setLogoUrl] = useAtom(logoUrlAtom);
  function openFilePicker() {
    const elem = document.getElementById("add-logo-open-file-selection");
    elem?.click();
  }
  function logoFileSelected(e: ChangeEvent) {
    const file = (e?.target as HTMLInputElement)?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (readerEvent) => {
        const content = readerEvent?.target?.result;
        if (content) setLogoUrl(content as string);
      };
    }
  }
  function clearLogoClicked() {
    setLogoUrl(null);
  }

  return (
    <>
      {!logoUrl && (
        <input id="add-logo-open-file-selection" type="file" style={{ display: "none" }} onChange={logoFileSelected} />
      )}
      <Button onClick={!logoUrl ? openFilePicker : clearLogoClicked} icon={<PlusCircleIcon />}>
        {!logoUrl ? "Add logo" : "Remove logo"}
      </Button>
    </>
  );
};

export default MarketingEditorControlLogoButton;
