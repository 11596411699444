import { gql, useQuery } from "urql";

const GetCsvUploads = gql`
  query {
    csvUploads {
      djangoId
      csvFile
      name
      status
      totalEmissions
      createdAt
    }
  }
`;

const useCsvUploads = () => {
  const result = useQuery({
    query: GetCsvUploads,
  });
  return result;
};

export default useCsvUploads;
