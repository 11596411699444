import { useAtom, useAtomValue } from "jotai";
import { elementsForCurrentCanvasAtom, selectedElementIdAtom } from "@Eikochain/state/atoms/CanvasContent";
import { ICanvasElement } from "@Eikochain/components/organisms/MarketingEditor/types";

interface IUseEditSelectedElementExecute {
  newContent?: string;
  newX?: number;
  newY?: number;
  newWidth?: number;
  newHeight?: number;
}

const useEditSelectedElement = () => {
  const [elements, setElements] = useAtom(elementsForCurrentCanvasAtom);
  const selectedElement = useAtomValue(selectedElementIdAtom);

  function execute({
    newContent,
    newX,
    newY,
    newWidth,
    newHeight,
  }: IUseEditSelectedElementExecute) {
    const elementIndex = elements.findIndex(el => el.localId === selectedElement);
    const element = elements[elementIndex];
    const editedElement: ICanvasElement = {
      ...element,
      content: newContent ?? element.content,
      x: newX ?? element.x,
      y: newY ?? element.y,
      width: newWidth ?? element.width,
      height: newHeight ?? element.height,
    }
    setElements([
      ...elements.slice(0, elementIndex),
      editedElement,
      ...elements.slice(elementIndex + 1),
    ]);
  }

  return execute;
};

export default useEditSelectedElement;
