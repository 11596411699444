import { PlusIcon } from "lucide-react";
import { useState } from "react";
import { UseQueryExecute } from "urql";
import AddSupplierDialogContent from "./add-supplier-dialog";
import { Button } from "./button";
import { Dialog, DialogTrigger } from "./dialog";

interface AddSupplierButtonProps {
    reexecuteQuery: UseQueryExecute
}

export default function AddSupplierButton({
    reexecuteQuery
}: AddSupplierButtonProps) {
    const [open, setOpen] = useState(false)
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="fun" icon={<PlusIcon />}>
                    Add supplier
                </Button>
            </DialogTrigger>
            <AddSupplierDialogContent triggerClose={() => {
                setOpen(false); reexecuteQuery()
            }} />
        </Dialog>
    )
}
