import { Currency } from "@Eikochain/__generated__/graphql";
import { Button } from "@Eikochain/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@Eikochain/components/ui/dropdown-menu";
import { FormField } from "@Eikochain/components/ui/form";
import { cn } from "@Eikochain/utils";
import { ArrowUpDownIcon, CheckIcon } from "lucide-react";
import { Control } from "react-hook-form";

interface SpendFieldCurrencyDropdownProps {
  control: Control,
  currencies: Omit<Currency, "djangoId">[];
  className?: string;
}

export default function SpendFieldCurrencyDropdown({
  control,
  currencies,
  className,
}: SpendFieldCurrencyDropdownProps) {
  return (
    <FormField
      control={control}
      name="currency"
      render={({ field }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button type="button" className={cn("gap-2 text-base", className)} variant="outline">
              {field.value?.currencyName}
              <ArrowUpDownIcon className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="gap-4">
            {
              currencies.map((currency) => (
                <DropdownMenuItem onClick={() => field.onChange(currency)}>
                  {currency.currencyName}
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4",
                      currency.currencyName === field.value?.currencyName
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </DropdownMenuItem>
              ))
            }
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    />
  )
}
