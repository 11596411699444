// @ts-ignore
import Spacer from "@Eikochain/components/atoms/Spacer";
import ColorPickerInput from "@Eikochain/components/molecules/ColorPicker/ColorPickerInput";
import GradientPicker from "@Eikochain/components/molecules/ColorPicker/GradientPicker";
import Modal from "@Eikochain/components/molecules/Modal";
import { EikochainModalProps as ModalProps } from "@Eikochain/components/molecules/Modal/Modal";
import { Button } from "@Eikochain/components/ui/button";
import {
  backgroundColorTypeAtom,
  fontColorAtom,
  fontFamilyAtom,
  fontSizeAtom,
  gradientBackgroundColorAtom,
  solidBackgroundColorAtom,
} from "@Eikochain/state/atoms/CanvasMenuStyles";
import { Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useAtom } from "jotai";
import { fontFamilyOptions, fontSizeOptions } from "./choices";

interface CanvasMenuStyleModalProps extends ModalProps {
  instance?: any;
  onFinish?: (values: any) => void;
  onFinishFailed?: (values: any) => void;
}

const CanvasMenuStyleModal = ({
  open,
  onFinish,
  onFinishFailed,
  onOk,
  onCancel,
  instance,
}: CanvasMenuStyleModalProps) => {
  // Global state
  const [fontFamily, setFontFamily] = useAtom(fontFamilyAtom);
  const [fontSize, setFontSize] = useAtom(fontSizeAtom);
  const [fontColor, setFontColor] = useAtom(fontColorAtom);
  const [solidBackgroundColor, setLinearBackgroundColor] = useAtom(solidBackgroundColorAtom);
  const [gradientBackgroundColor, setGradientBackgroundColor] = useAtom(gradientBackgroundColorAtom);
  // Controls whether solid or gradient type of background color selected
  const [backgroundColorTypeChosen, setBackgroundColorTypeChosen] = useAtom(backgroundColorTypeAtom);

  function applyFormChanges(values: any) {
    setFontFamily(values?.fontFamily);
    setFontSize(values?.fontSize);
    setFontColor(values?.fontColor);
    setLinearBackgroundColor(values?.solidBackgroundColor);
    setGradientBackgroundColor(values?.gradientBackgroundColor);
    if (onOk) onOk();
  }

  const [form] = useForm();

  function backgroundColorChanged(color: string) {
    const colorIsGradient = color.includes("gradient");
    const type = colorIsGradient ? "gradientBackgroundColor" : "solidBackgroundColor";
    setBackgroundColorTypeChosen(colorIsGradient ? "gradient" : "solid");
    return form.setFieldValue(type, color);
  }

  return (
    <Modal open={open} title="Edit menu styles" onOk={onOk} onCancel={onCancel} hideFooter maskClosable={false}>
      <Form
        form={form}
        layout="vertical"
        name="CanvasMenuStyleForm"
        onFinish={applyFormChanges}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        colon={false}
      >
        <Form.Item label="Choose font family" name="fontFamily" initialValue={fontFamily} rules={[{ required: false }]}>
          <Select
            options={fontFamilyOptions}
            defaultValue={fontFamily}
            onChange={(v) => form.setFieldValue("fontFamily", v)}
          />
        </Form.Item>
        <Form.Item label="Choose font size" name="fontSize" initialValue={fontSize} rules={[{ required: false }]}>
          <Select
            options={fontSizeOptions}
            defaultValue={fontSize}
            onChange={(v) => form.setFieldValue("fontSize", v)}
          />
        </Form.Item>
        <Form.Item label="Choose font color" name="fontColor" initialValue={fontColor} rules={[{ required: false }]}>
          <ColorPickerInput color={fontColor} onChange={(v) => form.setFieldValue("fontColor", v)} />
        </Form.Item>
        <Form.Item
          label="Choose background color"
          name={backgroundColorTypeChosen === "solid" ? "solidBackgroundColor" : "gradientBackgroundColor"}
          initialValue={backgroundColorTypeChosen === "solid" ? solidBackgroundColor : gradientBackgroundColor}
          rules={[{ required: false }]}
        >
          <GradientPicker
            defaultColor={backgroundColorTypeChosen === "solid" ? solidBackgroundColor : gradientBackgroundColor}
            onChange={(color) => backgroundColorChanged(color)}
          />
        </Form.Item>
        <Spacer height={4} />
        <div className="flex justify-end">
          <Button type="submit">Apply changes</Button>
          <Spacer width={2} />
          <Button variant="destructive" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CanvasMenuStyleModal;
