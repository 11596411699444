import { EmissionFactorValues } from "@Eikochain/__generated__/graphql"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../table"

interface FactorValuesTableProps {
  data: EmissionFactorValues
}

export default function FactorValuesTable({
  data
}: FactorValuesTableProps) {
  const fields: {label: string, value: Exclude<keyof EmissionFactorValues, "calculationUnitType">}[] = [
    {
      label: "CO2 and equivalents total",
      value: "co2eTotal"
    },
    {
      label: "CO2",
      value: "co2"
    },
    {
      label: "CH4",
      value: "ch4"
    },
    {
      label: "N2O",
      value: "n2o"
    },
  ]
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>
            <div className="flex items-center gap-2">
              Constituent gas
            </div>
          </TableHead>
          <TableHead>
            <div className="flex items-center gap-2">
              Gas value (kg/{data.calculationUnitType.units})
            </div>
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {fields.map((f) => (
          <TableRow key={f.value}>
            <TableCell>{f.label}</TableCell>
            <TableCell className="font-mono">{data[f.value] ?? "--"}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
