import { cn } from "@Eikochain/utils";
import { CheckIcon, ChevronDownIcon, InfoIcon } from "lucide-react";
import { useState } from "react";
import { Control } from "react-hook-form";
import { Button } from "../../../button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../../../command";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../form";
import { Popover, PopoverContent, PopoverTrigger } from "../../../popover";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../tooltip";

export interface RegionFieldProps {
  control: Control<any>;
  regions: string[]
}

export default function RegionField({
  control,
  regions
}: RegionFieldProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  return (
    <FormField
      control={control}
      name="region"
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            Region (optional)
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <InfoIcon />
                </TooltipTrigger>
                <TooltipContent className="max-w-[450px]">
                  The available regions we can source your emissions data from.
                  If you can't find the exact region for this activity, selecting somewhere similar or geographically close to your activity will increase the accuracy.
                  If a region isn't selected we'll calculate the most appropriate for you.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </FormLabel>
          <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  // disabled={!regions.length}
                  className={cn(
                    "justify-between font-normal",
                    !field.value && "text-muted-foreground"
                  )}
                >
                  <span className="text-ellipsis overflow-hidden">
                    {field.value ?? "Select region"}
                  </span>
                  <ChevronDownIcon className="ml-2 h-4 w-4 flex-shrink-0" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] p-0">
              <Command>
                <CommandInput placeholder="Search region..." />
                <CommandList>
                  <CommandEmpty>No regions found</CommandEmpty>
                    <CommandGroup>
                      {
                        regions?.map(r => (
                          <CommandItem
                            key={r}
                            value={r}
                            onSelect={() => {
                              field.onChange(r);
                              setPopoverOpen(false)
                            }}
                          >
                            {r}
                            <CheckIcon
                              className={cn(
                                "ml-auto h-4 w-4",
                                r === field.value
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                          </CommandItem>
                        ))
                      }
                    </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
