import { DeleteSupplierInformationRequestMutation } from "@Eikochain/graphql/hooks/useDeleteSupplierInformationRequest";
import { Trash2Icon } from "lucide-react";
import { toast } from "sonner";
import { useMutation } from "urql";
import { Button } from "../button";
import { DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "./../dialog";

type DeleteSupplierInformationRequestDialogContentProps = {
    supplierInformationRequestId: string;
    onFinish: () => void;
}

export default function DeleteSupplierInformationRequestDialogContent({
    supplierInformationRequestId,
    onFinish
}: DeleteSupplierInformationRequestDialogContentProps) {
    const [mutationResult, executeMutation] = useMutation(DeleteSupplierInformationRequestMutation);
    function deleteFile() {
        executeMutation({ id: supplierInformationRequestId }).then((result) => {
            const { data, error } = result;
            if (error || data.error) {
                toast.error("There was an error deleting this request");
            } else {
                toast.success("Deleted request");
                onFinish();
            }
        });
    }

    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Delete request?</DialogTitle>
                <DialogDescription>
                    Confirming will irreversibly delete the request and any responses associated with it. This data may be important for use in carbon accounting. Note that the email delivered to the supplier cannot be unsent, so you may want to inform the supplier that the data you requested is no longer needed.
                </DialogDescription>
            </DialogHeader>
            <DialogFooter>
                <Button
                    variant="destructive"
                    loading={mutationResult?.fetching}
                    icon={<Trash2Icon />}
                    onClick={deleteFile}
                >
                    Delete
                </Button>
                <DialogClose asChild>
                    <Button variant="secondary" loading={mutationResult?.fetching}>Cancel</Button>
                </DialogClose>
            </DialogFooter>
        </DialogContent>
    )
}
