import { toast } from "sonner";
import { AnyVariables, UseMutationExecute } from "urql";

export default function onSubmit (
  values: any,
  onError: (errors: string[]) => void,
  executionHandler: UseMutationExecute<any, AnyVariables>,
): void {
  const data = {
    document: values.documentUpload
  };

  executionHandler({
    input: data,
  }).then((result) => {
    const { data, error } = result;
    if (error) {
      toast.error("An error occured");
    } else {
      if (data.uploadEmissionFactorsDocument?.count) {
        toast.success(`${data.uploadEmissionFactorsDocument?.count} emission factors were created successfully`);
      }
      if (data.uploadEmissionFactorsDocument?.errors) {
        onError(data.uploadEmissionFactorsDocument?.errors)
      }
    }
  });
};
