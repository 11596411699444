import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@Eikochain/components/ui/dialog";
import { useState } from "react";
import { toast } from "sonner";
import { useMutation } from "urql";
import { CreateOrUpdateOrganizationIntegrationMutation } from "../../../graphql/hooks/useCreateOrUpdateOrganizationIntegration";
import EnterIntegrationUrlForm from "./EnterIntegrationUrlForm";
import SelectIntegrationForm from "./SelectIntegrationForm";

interface AddNewIntegrationModalProps {
  open: boolean;
  onFinish: () => void;
  onOpenChange: () => void;
}

export default function AddNewIntegrationModal ({
  open,
  onFinish,
  onOpenChange
}: AddNewIntegrationModalProps) {
  const [_, executeMutation] = useMutation(CreateOrUpdateOrganizationIntegrationMutation);
  const [step, setStep] = useState(1);
  const [integrationTypeSelected, setIntegrationTypeSelected] = useState<any | null>(null);

  const newIntegrationSelected = (value: any) => {
    setIntegrationTypeSelected(value.selectedIntegration);
    setStep(2);
  }

  const getModalTitle = (): string => {
    if (step === 1) {
      return "Choose an integration provider"
    }
    if (step === 2) {
      return "Add your sites URL"
    }
    throw new Error("No step selected")
  }

  function submitIntegrationValues (values: any) {
    const integrationValues = {
      url: values.integrationUrl,
      integrationTypeId: integrationTypeSelected?.id,
    }
    executeMutation({ input: integrationValues }).then(result => {
      const { error} = result;
      if (error) {
        toast.error("Something went wrong! We're looking into it")
      } else {
        onFinish()
      }
    });
  }

  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{getModalTitle()}</DialogTitle>
        </DialogHeader>
        {
          step === 1
          ? <SelectIntegrationForm
            onFinish={newIntegrationSelected}
          /> : <EnterIntegrationUrlForm
            onFinish={submitIntegrationValues}
          />
        }
      </DialogContent>
    </Dialog>
  )
};
