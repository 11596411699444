import type { CreateSupplierInformationRequestInput, CreateSupplierInformationRequestSuccess, DeleteSupplierInformationRequestTemplateStatus, SupplierInformationRequestTemplate } from "@Eikochain/__generated__/graphql"
import { Controller, useFieldArray, type UseFieldArrayReturn, useForm, type UseFormReturn, } from "react-hook-form"
import { Button } from "@Eikochain/components/ui/button";
import { Form, FormField, FormItem, FormLabel, FormMessage } from "@Eikochain/components/ui/form";
import { type Dispatch, type FC, Fragment, type SetStateAction, useEffect, useState, ReactNode } from "react";
import { Input } from "@Eikochain/components/ui/input";
import { Checkbox } from "@Eikochain/components/ui/checkbox";
import { Textarea } from "@Eikochain/components/ui/textarea";
import { Check, CheckSquare, Pencil, PlusIcon, SaveIcon, SquareIcon, TrashIcon } from "lucide-react";
import { ChevronDownIcon } from "lucide-react";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandList, CommandItem } from "@Eikochain/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@Eikochain/components/ui/popover";
import { cn } from "@Eikochain/utils";
import { useMutation, useQuery } from "urql";
import { CreateSupplierInformationRequestMutation } from "@Eikochain/graphql/hooks/mutations/create-supplier-information-request";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import useGetSuppliers from "@Eikochain/graphql/hooks/useGetSuppliers";
import { GetInformationRequestTemplatesQuery } from "@Eikochain/graphql/hooks/useGetInformationRequestTemplates";
import { CreateSupplierInformationRequestTemplateMutation } from "@Eikochain/graphql/hooks/mutations/create-supplier-information-request-template";
import { DeleteSupplierInformationRequestTemplateMutation } from "@Eikochain/graphql/hooks/mutations/delete-supplier-information-request-template";
import { useToast } from "@Eikochain/hooks/use-toast";
import { TooltipProvider, TooltipTrigger, Tooltip, TooltipContent } from "@Eikochain/components/ui/tooltip";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@Eikochain/components/ui/dialog";
import { UpdateSupplierInformationRequestTemplateMutation } from "@Eikochain/graphql/hooks/mutations/update-supplier-information-request-template";


/**
 * Types
 */
const addSupplierFormSchema = z.object({
    supplier: z.array(z.object({
        id: z.string().uuid(),
        name: z.string(),
    })).min(1, "At least one supplier is required"),
    supplierContact: z.array(z.object({
        id: z.string().uuid(),
        name: z.string(),
        email: z.string().email(),
        organisationId: z.string().uuid(),
    })).min(1, "At least one contact is required"),
    requireProof: z.boolean(),
    requestType: z.string().min(1, "Request type is required"),
    additionalInformation: z.string().optional(),
    informationFields: z.array(z.object({
        stepNo: z.number(),
        title: z.string().min(1, "Title is required"),
        description: z.string().max(255, "Description must be under 255 characters").optional(),
        inputType: z.string().min(1, "Input type is required"),
        measurement: z.string()
    })).min(1, "At least one form field is required")
})


export type SupplierFormSchema = z.infer<typeof addSupplierFormSchema>

interface AddSupplierInformationRequestFormProps {
    supplierId?: string;
    onSuccess: (values: CreateSupplierInformationRequestSuccess) => void;
}

const inputTypes = [{ name: "General", values: ["text"] }, { name: "Number", values: ["currency", "weight"] }, { name: "Boolean", values: ["yes/no"] }]
const measurements = new Map<string, string[]>([["currency", ["€", "£", "$"]], ["weight", ["kg", "lb"]]])

/**
 * Components
 */
export const ComboBoxOption = CommandItem;
export const ComboBoxGroup = CommandGroup;

interface ComboBoxProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    value?: string;
    children?: ReactNode;
    className?: string;
    noValueDisplayText: string;
    optionsSearchDisplayText: string;
    noOptionsDisplayText: string;
    disabled?: boolean;
}

export function ComboBox({
    open,
    setOpen,
    value,
    noValueDisplayText,
    optionsSearchDisplayText,
    noOptionsDisplayText,
    disabled = false,
    className,
    children
}: ComboBoxProps) {
    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    className={cn(
                        "justify-between font-normal",
                        !value && "text-muted-foreground",
                        className
                    )}
                    disabled={disabled}
                >
                    <span className="text-ellipsis overflow-hidden">
                        {!value ? noValueDisplayText : value}
                    </span>
                    <ChevronDownIcon className="ml-2 h-4 w-4 flex-shrink-0" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-fit min-w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] p-0">
                <Command>
                    <CommandInput placeholder={optionsSearchDisplayText} />
                    <CommandList>
                        <CommandEmpty>{noOptionsDisplayText}</CommandEmpty>
                        {children}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

// LEFT
interface LeftFormProps {
    form: UseFormReturn<SupplierFormSchema>
    suppliers?: any
    supplierWatch: SupplierFormSchema["supplier"]
    contactWatch: SupplierFormSchema["supplierContact"]
    mutationIsPending: boolean
}
const LeftFormColumn: FC<LeftFormProps> = ({ form, suppliers, supplierWatch, contactWatch, mutationIsPending }) => {
    const [supplierOpen, setSupplierOpen] = useState<boolean>(false);
    const [contactOpen, setContactOpen] = useState<boolean>(false);

    return (
        <div className="min-h-1/2 mb-10 flex flex-col border-r-2 border-black pr-8">
            <div className="flex flex-col gap-y-8 pb-2">
                {/* STAGE 1: Choose a supplier */}
                <FormField
                    control={form.control}
                    name="supplier"
                    render={({ field }) => {
                        return (
                            <FormItem className="flex flex-col">
                                <FormLabel>Supplier</FormLabel>
                                <ComboBox
                                    open={supplierOpen}
                                    setOpen={setSupplierOpen}
                                    value={field.value && field.value.length != 0 ? `${field.value.length} suppliers selected` : undefined}
                                    optionsSearchDisplayText="Search suppliers..."
                                    noOptionsDisplayText="No suppliers found"
                                    noValueDisplayText="Select supplier"
                                >
                                    <ComboBoxGroup>
                                        {suppliers?.map((supplier: any) => (
                                            <ComboBoxOption
                                                key={supplier.id}
                                                value={supplier.name}
                                                className="flex gap-2"
                                                onSelect={() => {
                                                    if (field.value?.find(v => v.id === supplier.id)) {
                                                        form.setValue("supplier", field.value.filter(v => v.id !== supplier.id));
                                                        return
                                                    }

                                                    if (field.value?.length > 0) {
                                                        form.setValue("supplier", [...field.value, supplier]);
                                                    } else {
                                                        form.setValue("supplier", [supplier]);
                                                    }

                                                }}
                                            >
                                                {field.value?.find(v => v.id === supplier.id) ? <CheckSquare className="h-4 w-4" /> : <SquareIcon className="h-4 w-4" />}
                                                {supplier.name}
                                            </ComboBoxOption>
                                        ))}
                                    </ComboBoxGroup>
                                </ComboBox>
                                <FormMessage />
                            </FormItem>
                        )
                    }}
                />

                {/* STAGE 2: Choose a contact */}
                {supplierWatch && <FormField
                    control={form.control}
                    name="supplierContact"
                    render={({ field }) => {
                        return (
                            <FormItem className="flex flex-col">
                                <FormLabel>Contact email</FormLabel>
                                <ComboBox
                                    open={contactOpen}
                                    setOpen={setContactOpen}
                                    value={field.value && field.value.length !== 0 ? `${field.value.length} contacts selected` : undefined}
                                    optionsSearchDisplayText="Search contacts..."
                                    noOptionsDisplayText="No contacts found"
                                    noValueDisplayText="Select contact"
                                >
                                    <ComboBoxGroup>
                                        {suppliers?.filter((s: any) => supplierWatch?.find(v => v.id === s.id))?.map((ss: any) => ss.contacts.map((contact: any) => (
                                            <ComboBoxOption
                                                key={contact.id}
                                                value={`${contact.name} <${contact.email}>`}
                                                className="flex gap-2"
                                                onSelect={() => {
                                                    if (field.value?.find(v => v.id === contact.id)) {
                                                        form.setValue("supplierContact", field.value?.filter(v => v.id !== contact.id));
                                                        return
                                                    }

                                                    if (field.value?.length > 0) {
                                                        form.setValue("supplierContact", [...field.value, contact]);
                                                    } else {
                                                        form.setValue("supplierContact", [contact]);
                                                    }
                                                }}
                                            >
                                                <p>{field.value?.find(v => v.id === contact.id) ? <CheckSquare className="w-4 h-4" /> : <SquareIcon className="w-4 h-4" />}</p>
                                                <p>{contact.name} {`<${contact.email}>`}</p>
                                            </ComboBoxOption>
                                        )))}
                                    </ComboBoxGroup>
                                </ComboBox>
                                <FormMessage />
                            </FormItem>
                        )
                    }}
                />}

                {contactWatch && (
                    <div className="grid gap-x-10 grid-cols-2 place-items-center *:w-full">
                        {/* STAGE 3: Input request type */}
                        <FormField
                            control={form.control}
                            name="requestType"
                            render={({ field }) => {
                                return (
                                    <FormItem className="flex flex-col gap-1 w-full">
                                        <FormLabel>Request type</FormLabel>
                                        <Input {...field} placeholder="Electricity, weight, expenses, etc" value={field.value} onChange={field.onChange} />
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                        <FormField
                            control={form.control}
                            name="requireProof"
                            render={({ field }) => {
                                return (
                                    <FormItem className="flex flex-col gap-1">
                                        <div className="flex gap-2 pt-3">
                                            <Checkbox checked={field.value} onCheckedChange={field.onChange} id="proofCheck" />
                                            <div className="grid gap-1.5 leading-none">
                                                <label
                                                    htmlFor="proofCheck"
                                                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                                >
                                                    Require document proof?
                                                </label>
                                                <p className="text-sm text-muted-foreground">
                                                    Your supplier will be required to upload proof that confirms their entry
                                                </p>
                                            </div>
                                        </div>
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                    </div>)}

                {contactWatch && (
                    <div >
                        {/* STAGE 4: Input additional information */}
                        <FormField
                            control={form.control}
                            name="additionalInformation"
                            render={({ field }) => {
                                return (
                                    <FormItem className="flex flex-col gap-1">
                                        <FormLabel>Additional information (optional)</FormLabel>
                                        <Textarea {...field} className="resize-none" placeholder="Any additional information you'd like to provide" value={field.value} onChange={field.onChange} />
                                        <FormMessage />
                                    </FormItem>
                                )
                            }}
                        />
                    </div>
                )}
            </div>
            <div className="flex justify-center w-full items-end flex-grow pb-10">
                <Button
                    type="submit"
                    className="w-[50%]"
                    loading={mutationIsPending}
                >
                    Create request
                </Button>
            </div>
        </div >

    )
}

// RIGHT

const EditableStep: FC<{
    fieldsArray: UseFieldArrayReturn<SupplierFormSchema, "informationFields">,
    form: UseFormReturn<SupplierFormSchema>,
    index: number,
    setEditingIndex: Dispatch<SetStateAction<number | undefined>>
}>
    = ({ form, fieldsArray, index, setEditingIndex }) => {
        const [open, setOpen] = useState<boolean>(false)
        const [measureOpen, setMeasureOpen] = useState<boolean>(false)

        const inputTypeWatcher = form.watch(`informationFields.${index}.inputType`)

        useEffect(() => {
            const alreadyHasValidValue = measurements.has(inputTypeWatcher!) && measurements.get(inputTypeWatcher!)!.includes(form.getValues(`informationFields.${index}.measurement`)!)
            if (!inputTypeWatcher || alreadyHasValidValue) return

            switch (inputTypeWatcher) {
                case "currency":
                case "weight":
                    form.setValue(`informationFields.${index}.measurement`, measurements.get(inputTypeWatcher)![0]!)
                    break
                default:
                    form.setValue(`informationFields.${index}.measurement`, "")
            }
        }, [inputTypeWatcher])

        return (
            <div className="flex gap-2">
                <div className="flex-grow grid grid-cols-12 gap-x-2">
                    <Controller
                        control={form.control}
                        name={`informationFields.${index}.title`}
                        render={({ field, fieldState }) => {
                            return (
                                <div className="flex flex-col gap-1 col-span-2">
                                    <Input
                                        {...field}
                                        placeholder="Title"
                                        className={fieldState.error ? "border-red-500 border-2" : ""}
                                    />
                                    {fieldState.error && (
                                        <p className="text-red-500 text-xs">{fieldState.error.message}</p>
                                    )}
                                </div>
                            )
                        }}
                    />
                    <Controller
                        control={form.control}
                        name={`informationFields.${index}.description`}
                        render={({ field, fieldState }) => {
                            return (
                                <div className="flex flex-col gap-1 col-span-5">
                                    <Input
                                        {...field}
                                        placeholder="Description"
                                    />
                                    {fieldState.error && (
                                        <p className="text-red-500 text-xs">{fieldState.error.message}</p>
                                    )}
                                </div>
                            )
                        }}
                    />

                    <div className="flex gap-1 col-span-5">
                        <Controller
                            control={form.control}
                            name={`informationFields.${index}.inputType`}
                            render={({ field, fieldState }) => (
                                <div className="flex flex-grow flex-col gap-1">
                                    <ComboBox
                                        open={open}
                                        setOpen={setOpen}
                                        value={field?.value?.charAt(0)?.toUpperCase()! + field?.value?.slice(1)!}
                                        className={fieldState.error ? "border-2 border-red-500" : ""}
                                        noOptionsDisplayText="No options found"
                                        noValueDisplayText="Select type"
                                        optionsSearchDisplayText="Search types..."
                                    >
                                        {inputTypes.map((group) => (
                                            <ComboBoxGroup key={group.name} heading={group.name}>

                                                {group.values.map((value) => (
                                                    <ComboBoxOption
                                                        key={value}
                                                        value={value.charAt(0).toUpperCase() + value.slice(1)}
                                                        className="flex gap-2"
                                                        onSelect={() => {
                                                            field.onChange(value)
                                                            setOpen(false)
                                                        }}
                                                    >
                                                        {value === field.value ? <CheckSquare className="w-4 h-4" /> : <SquareIcon className="w-4 h-4" />}
                                                        {value.charAt(0).toUpperCase() + value.slice(1)}
                                                    </ComboBoxOption>
                                                ))}
                                            </ComboBoxGroup>
                                        ))}
                                    </ComboBox>
                                    {fieldState.error && (
                                        <p className="text-red-500 text-xs">{fieldState.error.message}</p>
                                    )}
                                </div>
                            )}
                        />
                        {inputTypeWatcher && measurements.has(inputTypeWatcher) && (
                            <Controller
                                control={form.control}
                                name={`informationFields.${index}.measurement`}
                                render={({ field }) => (
                                    <ComboBox
                                        open={measureOpen}
                                        setOpen={setMeasureOpen}
                                        value={field.value}
                                        className="w-full"
                                        noOptionsDisplayText="No options found"
                                        noValueDisplayText=""
                                        optionsSearchDisplayText="Search measurements..."
                                    >
                                        <ComboBoxGroup>
                                            {measurements.get(inputTypeWatcher)?.map((value) => (
                                                <ComboBoxOption
                                                    key={value}
                                                    value={value}
                                                    className="flex gap-2"
                                                    onSelect={() => {
                                                        field.onChange(value)
                                                        setMeasureOpen(false)
                                                    }}
                                                >
                                                    {value === field.value ? <CheckSquare className="w-4 h-4" /> : <SquareIcon className="w-4 h-4" />}
                                                    {value}
                                                </ComboBoxOption>
                                            ))}
                                        </ComboBoxGroup>
                                    </ComboBox>
                                )}
                            />
                        )}
                    </div>
                </div>
                <Button
                    size="icon"
                    type="button"
                    className="size-fit p-3"
                    onClick={async () => {
                        await form.trigger(`informationFields`)

                        if (!form.formState.errors.informationFields) {
                            setEditingIndex(undefined)
                        }
                    }}
                >
                    <Check className="h-4 w-4" />
                </Button>
                <Button
                    variant="destructive"
                    type="button"
                    size="icon"
                    className="size-fit p-3"
                    onClick={() => {
                        fieldsArray.remove(index)
                        setEditingIndex(undefined)
                    }}
                >
                    <TrashIcon className="h-4 w-4" />
                </Button>
            </div>
        )
    }

const Step: FC<{
    item: SupplierFormSchema["informationFields"][number],
    index: number,
    editingIndex: number | undefined,
    setEditingIndex: Dispatch<SetStateAction<number | undefined>>
}> = ({ item, index, setEditingIndex, editingIndex }) => {
    return (
        <div key={item.stepNo} className="flex gap-2 border rounded-lg border-black">
            <div className="flex-grow w-full grid grid-cols-12 place-items-center gap-x-2 *: *:h-full font-medium text-md">
                <div className="col-span-9 flex gap-2 w-full justify-center items-center border-r mr-[-10px] pr-[10px] border-black">
                    <p className="text-black font-medium">{item.title}</p>
                    <p className="text-gray-700 font-light">{item.description !== "" ? `(${item.description})` : ""}</p>
                </div>
                <div className="col-span-3 flex gap-2 w-full justify-center items-center">
                    <p className="text-black font-medium">{item.inputType.charAt(0).toUpperCase() + item.inputType.slice(1)}</p>
                    <p className="text-gray-700 font-light">{item.measurement !== "" ? `(${item.measurement})` : ""}</p>
                </div>
            </div>
            <div className="flex justify-end items-center">
                <Button
                    size="icon"
                    type="button"
                    className="rounded-r-md rounded-l-none"
                    onClick={() => {
                        if (editingIndex !== undefined) return
                        setEditingIndex(index)
                    }}
                >
                    <Pencil className="h-4 w-4" />
                </Button>
            </div>
        </div>
    )
}

const TemplateSelector: FC<{
    form: UseFormReturn<SupplierFormSchema>
    editingIndex: number | undefined,
}> = ({ editingIndex, form }) => {
    const { toast } = useToast();
    let [data, refetch] = useQuery({ query: GetInformationRequestTemplatesQuery })
    let templates: SupplierInformationRequestTemplate[] = data?.data?.supplierInformationRequestTemplates


    const [_a, executeDeleteMutation] = useMutation(DeleteSupplierInformationRequestTemplateMutation);
    const [_b, executeCreateMutation] = useMutation(CreateSupplierInformationRequestTemplateMutation);
    const [_c, executeUpdateMutation] = useMutation(UpdateSupplierInformationRequestTemplateMutation);

    const [open, setOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [createTemplateOpen, setCreateTemplateOpen] = useState(false)
    const [templateSelected, setTemplateSelected] = useState<SupplierInformationRequestTemplate | null>(null)
    const [newTemplateName, setNewTemplateName] = useState<string>("")

    if (!templates) { return null }

    const onSelectTemplate = (template: SupplierInformationRequestTemplate) => {
        setOpen(false)

        if (templateSelected?.id === template.id) {
            setTemplateSelected(null)
            return
        }

        setTemplateSelected(template)

        // Reset the information fields
        form.setValue("informationFields", template.steps.map((step) => ({
            stepNo: step!.stepNo,
            title: step!.title,
            description: step!.description ?? undefined,
            inputType: step!.inputType,
            measurement: step!.measurement
        })))
    }

    const deleteTemplate = async (template: SupplierInformationRequestTemplate) => {
        const data = await executeDeleteMutation({ input: { id: template.id } })
        const response: DeleteSupplierInformationRequestTemplateStatus = data.data?.deleteSupplierInformationRequestTemplate;

        if (response.success) {
            toast({ title: "Template deleted successfully", variant: "default" })
            setTemplateSelected(null)
            refetch({ requestPolicy: "network-only" })
        } else {
            toast({ title: response.reason ?? "Failed to delete template", variant: "destructive" })
        }
    }

    const updateTemplate = async (template: SupplierInformationRequestTemplate) => {
        const data = await executeUpdateMutation({
            input: {
                id: template.id,
                name: template.name,
                steps: form.getValues().informationFields
            }
        })

        if (data.data?.updateSupplierInformationRequestTemplate.success) {
            toast({ title: "Template updated successfully" })
            refetch({ requestPolicy: "network-only" })
        } else {
            toast({ title: "Failed to update template", variant: "destructive" })
        }
    }

    return (
        <div className="flex gap-2">
            <ComboBox
                open={open}
                setOpen={setOpen}
                value={templateSelected?.name}
                noOptionsDisplayText="No templates found"
                noValueDisplayText="Select template"
                optionsSearchDisplayText="Search templates..."
            >
                <ComboBoxGroup>
                    {templates.map((value) => (
                        <ComboBoxOption
                            key={value.id}
                            value={value.name}
                            className="flex gap-2"
                            onSelect={() => onSelectTemplate(value)}
                        >
                            {value.id === templateSelected?.id ? <CheckSquare className="w-4 h-4" /> : <SquareIcon className="w-4 h-4" />}
                            {value.name}
                        </ComboBoxOption>
                    ))}
                </ComboBoxGroup>
            </ComboBox>



            <TooltipProvider delayDuration={0}>
                {templateSelected && (
                    <>
                        <Dialog open={deleteOpen} onOpenChange={v => setDeleteOpen(v)}>
                            <DialogTrigger asChild>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Button
                                            size="icon"
                                            type="button"
                                            variant="destructive"
                                            className="size-fit p-3"
                                            onClick={() => setDeleteOpen(true)}
                                        >
                                            <TrashIcon className="h-4 w-4" />
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>Delete template</p>
                                    </TooltipContent>
                                </Tooltip>
                            </DialogTrigger>
                            <DialogContent>
                                <DialogTitle>Delete template</DialogTitle>
                                <DialogDescription>
                                    Are you sure you want to delete the template "{templateSelected.name}"?
                                </DialogDescription>
                                <div className="grid grid-cols-2 gap-4">
                                    <Button
                                        type="button"
                                        variant="destructive"
                                        onClick={() => {
                                            deleteTemplate(templateSelected)
                                            setDeleteOpen(false)
                                        }}>
                                        Delete
                                    </Button>
                                    <Button
                                        type="button"
                                        onClick={() => setDeleteOpen(false)}>
                                        Cancel
                                    </Button>
                                </div>

                            </DialogContent>
                        </Dialog>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    size="icon"
                                    type="button"
                                    className="size-fit p-3"
                                    onClick={() => updateTemplate(templateSelected)}
                                >
                                    <SaveIcon className="h-4 w-4" />
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Update template</p>
                            </TooltipContent>
                        </Tooltip>
                    </>
                )}

                {!templateSelected && (
                    <Dialog open={createTemplateOpen} onOpenChange={setCreateTemplateOpen}>
                        <DialogTrigger asChild>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        size="icon"
                                        type="button"
                                        variant="outline"
                                        className="size-fit p-3"
                                        onClick={() => {
                                            setCreateTemplateOpen(true)
                                        }}
                                    >
                                        <PlusIcon className="h-4 w-4" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Create template from current steps</p>
                                </TooltipContent>
                            </Tooltip>
                        </DialogTrigger>
                        <DialogContent>
                            <DialogTitle>Create template</DialogTitle>
                            <DialogDescription>Create a new template from the current steps.</DialogDescription>
                            <Input value={newTemplateName} onChange={(e) => setNewTemplateName(e.target.value)} placeholder="Template name" />
                            <Button
                                type="button"
                                onClick={async () => {

                                    if (!newTemplateName || newTemplateName.length === 0) {
                                        toast({ title: "Template name is required", variant: "destructive" })
                                        return
                                    }

                                    if (editingIndex !== undefined) {
                                        setCreateTemplateOpen(false)
                                        toast({ title: "Please finish editing the current field", variant: "destructive" })
                                        return
                                    }

                                    await form.trigger("informationFields")

                                    if (form.formState.errors.informationFields) {
                                        setCreateTemplateOpen(false)
                                        toast({ title: "Please fix the errors in the form", variant: "destructive" })
                                        return
                                    }

                                    const result = await executeCreateMutation({
                                        input: {
                                            name: newTemplateName,
                                            steps: form.getValues().informationFields.map((field) => ({
                                                stepNo: field.stepNo,
                                                title: field.title,
                                                description: field.description,
                                                inputType: field.inputType,
                                                measurement: field.measurement
                                            }))
                                        }
                                    })

                                    if (result.data?.createSupplierInformationRequestTemplate.success) {
                                        toast({ title: "Template created successfully" })
                                        setCreateTemplateOpen(false)
                                        setNewTemplateName("")
                                        refetch({ requestPolicy: "network-only" })
                                    } else {
                                        toast({ title: "Failed to create template", variant: "destructive" })
                                    }
                                }
                                }
                            >
                                Create
                            </Button>
                        </DialogContent>
                    </Dialog>
                )}
            </TooltipProvider>

        </div >
    )
}

interface RightFormProps {
    form: UseFormReturn<SupplierFormSchema>
    informationFieldsArray: UseFieldArrayReturn<SupplierFormSchema, "informationFields">
    editingIndex: number | undefined
    setEditingIndex: Dispatch<SetStateAction<number | undefined>>
}
const RightFormColumn: FC<RightFormProps> = ({ form, informationFieldsArray, editingIndex, setEditingIndex }) => {

    return (
        <div className="h-full flex flex-col gap-4">
            <p className="font-medium text-sm">Information fields</p>
            <TemplateSelector editingIndex={editingIndex} form={form} />
            <Controller
                control={form.control}
                name="informationFields"
                render={({ fieldState }) => {
                    return (
                        <>
                            <div className="flex flex-col gap-2">
                                {form.getValues().informationFields.map((item, index) => (
                                    <Fragment key={item.stepNo}>
                                        {index === editingIndex ? (<EditableStep
                                            fieldsArray={informationFieldsArray}
                                            form={form}
                                            index={editingIndex}
                                            setEditingIndex={setEditingIndex}
                                        />) : (<Step
                                            item={item}
                                            index={index}
                                            editingIndex={editingIndex}
                                            setEditingIndex={setEditingIndex}
                                        />)}
                                    </Fragment>
                                ))}
                            </div>
                            <p className="text-red-500 text-sm">{fieldState.error?.message}</p>
                        </>
                    )
                }}
            />
            <Button
                type="button"
                onClick={async () => {

                    // Verify that the current editing index is valid before adding a new one
                    if (editingIndex !== undefined) {
                        await form.trigger("informationFields")

                        if (form.formState.errors.informationFields) {
                            return
                        }
                    }

                    // Adding a new field
                    informationFieldsArray.append({
                        stepNo: informationFieldsArray.fields.length + 1,
                        title: "",
                        description: "",
                        inputType: "",
                        measurement: ""
                    }
                    )
                    setEditingIndex(informationFieldsArray.fields.length)
                }}
            >
                Add field
            </Button>
        </div >
    )
}


/**
 * Page
 */

export function AddSupplierInformationRequestForm({
    onSuccess,
    supplierId
}: AddSupplierInformationRequestFormProps) {
    const { toast } = useToast();
    const [editingIndex, setEditingIndex] = useState<number | undefined>(undefined)
    const [createSupplierRequest, executeMutation] = useMutation(CreateSupplierInformationRequestMutation);
    const suppliers = useGetSuppliers()[0].data?.suppliers?.map((supplier: any) => {
        return {
            ...supplier,
            contacts: supplier.contacts.map((contact: any) => {
                return {
                    ...contact,
                    organisationId: supplier.id
                }
            })
        }
    });

    const form = useForm<SupplierFormSchema>({
        resolver: zodResolver(addSupplierFormSchema),
        defaultValues: {
            supplier: [],
            supplierContact: [],
            requestType: "",
            additionalInformation: "",
            informationFields: [],
            requireProof: false
        }
    })

    const informationFieldArray = useFieldArray({ name: "informationFields", control: form.control })

    // Set the supplier field of the form if we supplier a userId and it matches a supplier
    useEffect(() => {
        const supplier = suppliers?.find((s: any) => s.id === supplierId)

        if (supplier) {
            form.setValue("supplier", [supplier])
        }
    }, [createSupplierRequest.data])


    // Reset the contact field when the supplier changes
    const supplierWatch = form.watch("supplier")
    const contactWatch = form.watch("supplierContact")
    useEffect(() => {
        // remove contacts that are not in the suppliers selected
        const newContacts = contactWatch?.filter((contact: any) => {
            return supplierWatch?.find((supplier: any) => supplier.id === contact.organisationId)
        })

        form.setValue("supplierContact", newContacts)
    }, [supplierWatch])

    // Function to handle when we submit the form
    const onSubmit = async (values: SupplierFormSchema) => {
        if (editingIndex !== undefined) {
            form.setError("informationFields", { message: "Please finish editing the current field" })
            return
        }

        const result = await executeMutation({
            input: {
                supplierContact: values.supplierContact.map(contact => ({ id: contact.id })),
                requestType: values.requestType,
                requireProof: values.requireProof,
                additionalInformation: values.additionalInformation,
                informationFields: values.informationFields
            } satisfies CreateSupplierInformationRequestInput
        });

        const data: CreateSupplierInformationRequestSuccess = result.data.createSupplierInformationRequest

        if (data.success) {
            toast({ title: "Supplier information request created successfully" })
            onSuccess(data)
        } else {
            toast({ title: data.reason ?? "Failed to send request", variant: "destructive" })
        }
    };

    return (
        <Form {...form}>
            <form
                className="grid grid-rows-1 grid-cols-2 gap-x-6"
                onSubmit={form.handleSubmit(onSubmit)}
            >
                <LeftFormColumn
                    form={form}
                    suppliers={suppliers}
                    supplierWatch={supplierWatch}
                    contactWatch={contactWatch}
                    mutationIsPending={createSupplierRequest.fetching}
                />
                {contactWatch && (<RightFormColumn
                    form={form}
                    informationFieldsArray={informationFieldArray}
                    editingIndex={editingIndex}
                    setEditingIndex={setEditingIndex}
                />)}
            </form>
        </Form >
    )
}
