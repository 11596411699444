import useGetEmissionCalculationRangeByActivityId from "@Eikochain/graphql/hooks/useGetEmissionCalculationRangeByActivityId";
import EmissionsComparisonTableByActivity from ".";

interface EmissionsComparisonTableByActivityWithDataProps {
  activityId: string;
}

export default function EmissionsComparisonTableByActivityWithData({
  activityId
}: EmissionsComparisonTableByActivityWithDataProps) {
  const [result] = useGetEmissionCalculationRangeByActivityId(activityId);
  const { data, fetching } = result;

  return (
    <EmissionsComparisonTableByActivity
      data={data?.emissionCalculationRangeByActivityId?.results ?? []}
      isFetching={fetching}
    />
  )
}
