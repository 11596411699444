import * as yup from "yup";

export const updatePasswordSchema = yup.object().shape({
  oldPassword: yup.string()
    .required("Old password is required"),
  newPassword: yup.string()
    .min(8, "At least 8 characters required")
    .max(32, "At most 32 characters required")
    .matches(/[a-z]/, 'Must contain lowercase letters')
    .matches(/[A-Z]/, 'Must contain uppercase letters')
    .matches(/\d/, 'Must contain at least one number')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Must contain at least one special character')
    .required("New password is required"),
  newPasswordConfirmed: yup.string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .required("Password confirmation is required"),
});
