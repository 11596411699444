import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@Eikochain/components/ui/form";
import { CreateCustomerLeadMutation } from "@Eikochain/graphql/hooks/mutations/useCreateInterest";
import { interestCallbackSent } from "@Eikochain/state/atoms/interest-callback-sent";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSetAtom } from "jotai";
import { SendIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { useMutation } from "urql";
import { Button } from "../button";
import { Checkbox } from "../checkbox";
import { Input } from "../input";
import { SchemaInputs, formSchema } from "./schema";

interface InterestCallbackFormProps {
  onSuccess: () => void;
}

export default function InterestCallbackForm({
  onSuccess,
}: InterestCallbackFormProps) {
  const [mutationResult, executeMutation] = useMutation(CreateCustomerLeadMutation)
  const setInterestSent = useSetAtom(interestCallbackSent)
  const form = useForm<SchemaInputs>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: "",
      company: "",
      name: "",
      agreeEmail: true,
    }
  });

  const onSubmit = async (data: SchemaInputs) => {
    executeMutation({input: {
      email: data.email,
      company: data.company,
      name: data.name
    }}).then((result): any => {
      const { data: resultData } = result;
      if (resultData?.createCustomerLead?.sent) {
        toast.success("We'll be in contact soon");
        onSuccess()
        setInterestSent(data.email)
      } else {
        toast.error("We ran into an error and we'll investigate");
        form.setError("root", resultData?.createCustomerLead?.error);
      }
    });
  }

  return (
    <Form {...form}>
      <form className="flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Your name</FormLabel>
              <FormControl>
                <Input
                  placeholder="Beyonce Knowles"
                  autoComplete="name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Work email</FormLabel>
              <FormControl>
                <Input
                  placeholder="beyonce@gmail.com"
                  autoComplete="email"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="company"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Company name</FormLabel>
              <FormControl>
                <Input
                  placeholder="Ivy Park"
                  autoComplete="organization"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="agreeEmail"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <div className="flex flex-row items-center justify-center gap-4">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="">
                  <FormLabel>
                    I&apos;m okay with receiving a follow-up email from EikoIQ
                  </FormLabel>
                </div>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" variant="fun" icon={<SendIcon />} loading={mutationResult.fetching}>Send</Button>
      </form>
    </Form>
  )
}
