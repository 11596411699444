import { gql } from 'urql';

export const DeleteSupplierInformationRequestMutation = gql`
  mutation DeleteSupplierInformationRequest($id: String!) {
    deleteSupplierInformationRequest(supplierInformationRequestId: $id) {
      ... on DeleteSupplierInformationRequestSuccess {
        success
      }
      ... on DeleteSupplierInformationRequestErrors {
        errors
      }
    }
  }
`;
