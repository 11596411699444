import { UploadEmissionFactorsDocumentMutation } from "@Eikochain/graphql/hooks/mutations/create-emission-factors-from-document"
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { useMutation } from "urql"
import { Button } from "../button"
import { Form, FormMessage } from "../form"
import DocumentUploadField from "./fields/document-upload-field"
import onSubmit from "./on-submit"
import { emissionFactorsUploadFormSchema } from "./schema"

export default function EmissionFactorsUploadForm() {
  const [mutationResult, executeMutation] = useMutation(UploadEmissionFactorsDocumentMutation)
  const form = useForm({
    resolver: yupResolver(emissionFactorsUploadFormSchema),
    defaultValues: {
      documentUpload: undefined
    }
  })

  function onError(errors: string[]) {
    form.setError("root.serverError", {message: errors?.[0]})
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-col space-y-6"
        onSubmit={form.handleSubmit((values) => onSubmit(
          values, onError, executeMutation
        ))}
      >
        <DocumentUploadField control={form.control} />
        {form.formState.errors.root && <FormMessage>{form.formState.errors.root?.message}</FormMessage>}
        <Button loading={mutationResult.fetching} type="submit">Submit</Button>
      </form>
    </Form>
  )
}
