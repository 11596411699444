import { Activity } from "@Eikochain/__generated__/graphql";
import H5 from "@Eikochain/components/atoms/H5";
import Paragraph from "@Eikochain/components/atoms/P";
import ActivityTypeIcon from "@Eikochain/components/ui/activity-type-icon";
import RouteFlags from "@Eikochain/components/ui/route-flags";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@Eikochain/components/ui/tooltip";
import { CreateOrUpdateActivityMutation } from "@Eikochain/graphql/hooks/useCreateOrUpdateActivity";
import { AlertOctagonIcon, Calculator } from "lucide-react";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { toast } from "sonner";
import { useMutation } from "urql";
import EmissionsComparisonTableByActivity from "../emissions-comparison-table";
import ActivityParametersOverview from "./activity-parameters-overview";
import ActivityStepActionsDropdown from "./activity-step-actions-dropdown";
import ActivityUnitsOverview from "./activity-units-overview";

type IActivityStep = {
  activity: Activity;
  productId: string;
  onCloneActivitySuccess: () => void;
  isEditable?: boolean;
};

export default function ActivityStep ({
  activity,
  productId,
  onCloneActivitySuccess,
  isEditable = true,
}: IActivityStep) {
  const [showEmissionSources, setShowEmissionSources] = useState(false);
  const emissionCalculationForStep = activity?.emissionCalculationRange;
  const [_, executeMutation] = useMutation(CreateOrUpdateActivityMutation);

  const onCloneActivity = () => {
    const matchingActivity = omitDeep(
      activity,
      "djangoId",
      "emissionCalculationRange",
      "mostRecentEmissionCalculation",
      "position",
      "__typename"
    );

    const reqData = {
      ...matchingActivity,
      name: `${activity?.name} (Copy)`,
    };

    executeMutation({
      input: {
        productId,
        activity: reqData,
      },
    }).then((res) => {
      const { error } = res;
      if (error) {
        toast.error("An error occured");
      } else {
        toast.success("Activity cloned successfully");
        onCloneActivitySuccess()
      }
    });
  };

  return (
    <>
      <div className="flex flex-col gap-2 rounded-2xl p-2 lg:p-4 shadow-md w-full bg-white">
        <div className="flex flex-col md:flex-row gap-2">
          <div className="flex flex-row items-center justify-between gap-2 md:w-5/12">
            <div className="flex flex-row gap-4 items-center md:w-8/12">
              <ActivityTypeIcon
                activityType={activity.activityType}
                transportMethod={activity.transportMethod ?? null}
              />
              <div className="flex flex-col">
                <H5>{activity.name}</H5>
                <Paragraph><ActivityUnitsOverview activity={activity} /></Paragraph>
              </div>
            </div>
            <div className="flex flex-col lg:w-4/12">
              <H5>{activity.activityType}</H5>
              <Paragraph><ActivityParametersOverview activity={activity} /></Paragraph>
            </div>
          </div>
          <div className="flex flex-row items-center justify-around lg:justify-between md:w-7/12">
            <div className="flex flex-row gap-2 justify-center items-center w-1/3">
              {activity.route ? (
                <RouteFlags route={activity.route} />
              ) : null}
            </div>
            <div className="flex flex-row gap-2 w-1/3">
              <div className="flex flex-row items-center gap-1">
                <p className="font-medium font-mono">
                  {emissionCalculationForStep?.results?.find((eb) => eb.isOriginal === true)?.co2e!}
                </p>
                <p className="text-sm font-mono">
                  kg <span className="hidden lg:inline-block">of</span> CO<sub>2</sub>e
                </p>
              </div>
              {!activity.mostRecentEmissionCalculation && (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <AlertOctagonIcon className="text-red-500" />
                    </TooltipTrigger>
                    <TooltipContent>Your emissions are not up to date. Please re-calculate.</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </div>
            <div className="flex flex-row items-center gap-3 relative">
              {emissionCalculationForStep ? (
                <TooltipProvider>
                  <Tooltip delayDuration={0}>
                    <TooltipTrigger asChild>
                      <Calculator
                        className="cursor-pointer"
                        size={24}
                        onClick={() => setShowEmissionSources(!showEmissionSources)}
                      />
                    </TooltipTrigger>
                    <TooltipContent>Show emissions breakdown</TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : null}
              {isEditable ? (
                <ActivityStepActionsDropdown
                  activity={activity}
                  onCloneActivity={onCloneActivity}
                />
              ) : null}
            </div>
          </div>
        </div>
        {showEmissionSources && emissionCalculationForStep ? (
          <EmissionsComparisonTableByActivity data={emissionCalculationForStep?.results!} />
        ) : null}
      </div>
    </>
  );
};
