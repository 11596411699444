import { useEffect } from 'react';
import { useSetAtom } from "jotai";
import { IMarketingPanel } from '@Eikochain/components/organisms/MarketingEditor/types';
import { canvasMenuStepsAtom } from "@Eikochain/state/atoms/CanvasMenuContent";
import { logoUrlAtom } from '@Eikochain/state/atoms/CanvasMenuStyles';
import { fontFamilyAtom } from '@Eikochain/state/atoms/CanvasMenuStyles';
import { fontSizeAtom } from '@Eikochain/state/atoms/CanvasMenuStyles';
import { fontColorAtom } from '@Eikochain/state/atoms/CanvasMenuStyles';
import { solidBackgroundColorAtom } from '@Eikochain/state/atoms/CanvasMenuStyles';
import { gradientBackgroundColorAtom } from '@Eikochain/state/atoms/CanvasMenuStyles';
import { backgroundColorTypeAtom } from '@Eikochain/state/atoms/CanvasMenuStyles';
import { currentPanelAtom } from '@Eikochain/state/atoms/CanvasContent';
import { whiteColor } from '@Eikochain/themes/constants';

const useSetJotaiStateForMarketingPanel = (instance?: IMarketingPanel) => {
  const setSteps = useSetAtom(canvasMenuStepsAtom);
  const setLogoUrl = useSetAtom(logoUrlAtom);
  const setFontFamily = useSetAtom(fontFamilyAtom);
  const setFontSize = useSetAtom(fontSizeAtom);
  const setFontColor = useSetAtom(fontColorAtom);
  const setLinearBackgroundColor = useSetAtom(solidBackgroundColorAtom);
  const setGradientBackgroundColor = useSetAtom(gradientBackgroundColorAtom);
  const setBackgroundColorType = useSetAtom(backgroundColorTypeAtom);
  const setCurrentPanel = useSetAtom(currentPanelAtom)
  const setCurrentPanelAtom = useSetAtom(currentPanelAtom)

  useEffect(() => {
    if (instance) {
      // Based on the `serverId` of each menuStep and canvas, we want to
      // create a corresponding localId that effectively pairs a canvas and a menu step
      const menuSteps = instance.menuSteps.map((menuStep, index) => ({
        ...menuStep,
        localId: index,
      }));
      const canvases = instance.canvases.map((canvas, index) => ({
        ...canvas,
        localId: menuSteps.find((menuStep) => menuStep.serverId === canvas.serverId)?.localId as number,
        elements: canvas.elements.map((element, eIndex) => ({
          ...element,
          // Element localId needs to be at least 1, since we use this our identifier for
          // drag and dropping, and a `0` or `falsy` value doesn't work
          localId: eIndex + 1
        }))
      }));
      setSteps(menuSteps)
      setLogoUrl(instance.logoUrl)
      setFontFamily(instance.menuStyling.fontFamily)
      setFontSize(instance.menuStyling.fontSize)
      setFontColor(instance.menuStyling.fontColor)
      setLinearBackgroundColor(instance.menuStyling.solidBackgroundColor)
      setGradientBackgroundColor(instance.menuStyling.gradientBackgroundColor)
      setBackgroundColorType(instance.menuStyling.backgroundColorType)
      instance.canvases = canvases;
      setCurrentPanel(instance)
    } else {
      setSteps([])
      setLogoUrl('')
      setFontFamily('Arial')
      setFontSize(16)
      setFontColor(whiteColor)
      setLinearBackgroundColor('#FFFFFF')
      setGradientBackgroundColor('linear-gradient(77deg, rgb(15, 53, 62) 0%, rgb(69, 79, 74) 81%, rgb(80, 85, 81) 100%)')
      setBackgroundColorType('gradient')
      // Set the panel to its default values.
      // TODO: Will need a better way to do this in future
      setCurrentPanel({
        menuSteps: [],
        logoUrl: '',
        menuStyling: {
          fontFamily: 'Arial',
          fontSize: 16,
          fontColor: whiteColor,
          solidBackgroundColor: '#FFFFFF',
          gradientBackgroundColor: 'linear-gradient(77deg, rgb(15, 53, 62) 0%, rgb(69, 79, 74) 81%, rgb(80, 85, 81) 100%)',
          backgroundColorType: 'gradient'
        },
        canvases: []
      })
    }
  }, [instance])
};

export default useSetJotaiStateForMarketingPanel;
