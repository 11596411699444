import { useAtom } from "jotai";
import { canvasMenuStepsAtom } from "@Eikochain/state/atoms/CanvasMenuContent";
import { ICanvasMenuStep } from "@Eikochain/components/organisms/MarketingEditor/types";

const useRenameMenuStep = () => {
  const [steps, setSteps] = useAtom(canvasMenuStepsAtom);

  function execute(stepLocalId: number, newName: string) {
    const getStep = steps.find(step => step.localId === stepLocalId) as ICanvasMenuStep;
    getStep.title = newName;
    setSteps([...steps])
  }

  return execute;
};

export default useRenameMenuStep;
