export const fontFamilyOptions = [
  {
    value: "arial",
    label: "Arial"
  },
  {
    value: "roboto",
    label: "Roboto"
  },
  {
    value: "poppins",
    label: "Poppins"
  },
  {
    value: "sans-serif",
    label: "Sans Serif"
  },
];

export const fontSizeOptions = [
  {
    value: 12,
    label: "12px"
  },
  {
    value: 16,
    label: "16px"
  },
  {
    value: 20,
    label: "20px"
  },
  {
    value: 24,
    label: "24px"
  },
];