export const reportHeaders = [
  {
    value: "OrderNumber",
    label: "Order number",
  },
  {
    value: "Activity Type",
    label: "Activity Type",
  },
  {
    value: "Scope",
    label: "Scope",
  },
  {
    value: "Date",
    label: "Date",
  },
  {
    value: "Client",
    label: "Client",
  },
  {
    value: "Warehouse City",
    label: "Warehouse City",
  },
  {
    value: "Warehouse Country",
    label: "Warehouse Country",
  },
  {
    value: "Recipient City",
    label: "Recipient City",
  },
  {
    value: "Recipient Country",
    label: "Recipient Country",
  },
  {
    value: "Shipping Method",
    label: "Shipping Method",
  },
  {
    value: "TotalWeight",
    label: "Weight (kg)",
  },
  {
    value: "Distance",
    label: "Distance (km)",
  },
  {
    value: "Emissions",
    label: "Emissions (kg)",
  },
  {
    value: "Dataset Name",
    label: "Dataset Name",
  },
  {
    value: "Dataset Link",
    label: "Dataset Link",
  },
  {
    value: "Dataset Year",
    label: "Dataset Year",
  },
  {
    value: "Dataset Region",
    label: "Dataset Region",
  },
]
