import { SupplierInformationRequest } from "@Eikochain/__generated__/graphql";
import { CoreRow } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import DataTable from "../data-table";
import { getColumns } from "./columns";

interface SupplierInformationRequestsTableProps {
    data: SupplierInformationRequest[];
    isFetching: boolean;
    showRowCount?: boolean;
    hidePaginationButtons?: boolean;
    refetch: () => void;
}

export default function SupplierInformationRequestsTable({
    data,
    isFetching,
    showRowCount = true,
    refetch,
}: SupplierInformationRequestsTableProps) {
    const navigate = useNavigate();
    return (
        <DataTable
            data={data}
            isFetching={isFetching}
            columns={getColumns({ refetch })}
            showRowCount={showRowCount}
            showColumnToggler={false}
            hidePaginationButtons={false}
            onRowClick={
                (values: CoreRow<SupplierInformationRequest>) => navigate(`/app/suppliers/requests/view/${values.id}`)
            }
        />
    )
}
