import { fieldValueOptionsForShippingMethod } from "@Eikochain/constants";
import { Control } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../select";

interface TransportMethodFieldProps {
  control: Control<any>;
}

export default function TransportMethodField({
  control
}: TransportMethodFieldProps) {
  return (
    <FormField
      control={control}
      name="transportMethod"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Transport method</FormLabel>
          <Select
            value={field.value}
            onValueChange={field.onChange}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder="Select a transport method" />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {
                fieldValueOptionsForShippingMethod.map(option => (
                  <SelectItem key={option.value} value={option.value}>{option.label}</SelectItem>
                ))
              }
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
