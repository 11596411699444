import { cn } from "@Eikochain/utils";
import { ReactNode } from "react";

interface StepContainerProps {
  className?: string;
  onClick?: () => void;
  isSelected?: boolean;
  children: ReactNode;
}

export default function StepContainer({
  className,
  onClick,
  isSelected,
  children,
}: StepContainerProps) {
  return (
    <div
      className={cn(
        "flex justify-between items-center w-full cursor-pointer rounded-md pointer-events-auto hover:bg-slate-100",
        isSelected ? "bg-slate-200" : "bg-auto",
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
