// @ts-nocheck

import H2 from "@Eikochain/components/atoms/H2";
import Spacer from "@Eikochain/components/atoms/Spacer";
import { Button } from "@Eikochain/components/ui/button";
import { Modal as AntDModal, ModalProps } from "antd";
import { ReactNode } from "react";

const ModalFooter: ReactNode = (
  <div className="flex flex-row justify-end">
    <Button>Okay</Button>
    <Spacer width={2} />
    <Button variant="destructive" disabled>Cancel</Button>
  </div>
);

export interface EikochainModalProps extends ModalProps {
  children?: ReactNode;
  title?: string;
  open: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  hideFooter?: boolean;
  maskClosable?: boolean;
}

const Modal = ({
  children,
  title,
  open,
  onOk,
  onCancel,
  footer: FooterComponent = ModalFooter,
  hideFooter = false,
  destroyOnClose = false,
  maskClosable = true,
  width,
  modalRender,
}: EikochainModalProps) => {
  function getFooter() {
    if (hideFooter === true) {
      return null;
    } else {
      if (FooterComponent) return [FooterComponent];
    }
  }

  return (
    <AntDModal
      open={open}
      footer={getFooter()}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}
      width={width}
      modalRender={modalRender}
      bodyStyle={{ maxHeight: '80vh', overflowY: "auto" }}
    >
      {title && (
        <>
          <H2>{title}</H2>
          <Spacer height={4} />
        </>
      )}
      {children}
    </AntDModal>
  );
};

export default Modal;
