
import Spacer from "@Eikochain/components/atoms/Spacer";
import Modal from "@Eikochain/components/molecules/Modal";
import Editor from "@Eikochain/components/organisms/WYSIWYGEditor/WYSIWYGEditor";
import { Button } from "@Eikochain/components/ui/button";
import { Input } from "@Eikochain/components/ui/input";
import useCreateElement from "@Eikochain/hooks/MarketingEditor/useCreateElement";
import useEditSelectedElement from "@Eikochain/hooks/MarketingEditor/useEditSelectedElement";
import { elementsForCurrentCanvasAtom, selectedElementIdAtom } from "@Eikochain/state/atoms/CanvasContent";
import { CanvasElementTypes } from "@Eikochain/types";
import { Form, Select } from "antd";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { ICanvasElementClientControlled } from "../../types";
import { elementTypes } from "./choices";

interface CreateOrUpdateCanvasElementModalProps {
  title?: string;
  // When updating a currently selected element, this will be true
  openWithSelectedInstance?: boolean;
  // When creating a new element, this will be the initial type
  initialElementType?: CanvasElementTypes;
  open?: boolean;
  onFinish: () => void;
  onFinishFailed?: () => void;
  onCancel?: () => void;
}

interface ICreateOrUpdateCanvasElementForm {
  elementType: CanvasElementTypes;
  content: string;
}

const CreateOrUpdateCanvasElementModal = ({
  title,
  openWithSelectedInstance = false,
  initialElementType = "text",
  open = false,
  onFinish,
  onFinishFailed,
  onCancel,
}: CreateOrUpdateCanvasElementModalProps) => {
  const [form] = Form.useForm();
  const elementType = Form.useWatch("elementType", form);
  const createFn = useCreateElement();
  const editFn = useEditSelectedElement();
  const canvasElements = useAtomValue(elementsForCurrentCanvasAtom);
  const selectedElementId = useAtomValue(selectedElementIdAtom);
  const selectedElementInstance = canvasElements.find((element) => element.localId === selectedElementId);

  function onFinishFn(values: ICreateOrUpdateCanvasElementForm) {
    const elementTypeValue: CanvasElementTypes = values.elementType;
    if (openWithSelectedInstance) {
      if (values?.content) editFn({ newContent: values.content });
    } else {
      let data: ICanvasElementClientControlled = {
        type: elementTypeValue,
        content: values.content,
        width: 200,
        height: 200,
        x: 0,
        y: 0,
      };
      if (elementTypeValue === "video") {
        data.content = `https://www.youtube-nocookie.com/embed/${values.content}`;
      }
      if (elementTypeValue === "imageCarousel") {
        data.content = [];
      }
      if (data) createFn(data);
    }
    onFinish();
  }

  useEffect(() => {
    if (openWithSelectedInstance) {
      form.setFieldValue("elementType", selectedElementInstance?.type);
      if (selectedElementInstance?.type === "video") {
        const embedUrlSplit = (selectedElementInstance?.content as string).split("/");
        const embedId = embedUrlSplit[embedUrlSplit.length - 1];
        form.setFieldValue("content", embedId);
      } else {
        form.setFieldValue("content", selectedElementInstance?.content);
      }
    } else {
      form.setFieldValue("elementType", initialElementType);
      form.setFieldValue("content", "");
    }
  }, [initialElementType, openWithSelectedInstance]);

  return (
    <Modal open={open} title={title} hideFooter>
      <Form
        form={form}
        name="addMarketingCanvasElement"
        layout="vertical"
        onFinish={(values: any) => onFinishFn(values)}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelAlign="left"
        labelWrap
        colon={false}
      >
        <Form.Item
          label="Element type"
          name="elementType"
          rules={[{ required: true, message: "Please select an item type" }]}
        >
          <Select
            options={elementTypes}
            onChange={(v) => form.setFieldValue("elementType", v)}
            disabled={openWithSelectedInstance}
          />
        </Form.Item>
        {elementType === "video" && (
          <Form.Item
            label="YouTube embed URL"
            name="content"
            tooltip="An 11-digit ID found in the URL of your YouTube video after https://www.youtube.com/watch?v="
            rules={[
              { required: true, message: "Please enter a embed ID" },
              {
                message: "An embed ID needs to be 11 digits long",
                validator: (_, value) => {
                  if (value.length === 11) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("An embed ID needs to be 11 digits long");
                  }
                },
              },
            ]}
          >
            <Input defaultValue="youtube.com/embed/" />
          </Form.Item>
        )}
        {elementType === "text" && (
          <Form.Item label="Content" name="content" rules={[{ required: true, message: "Please enter some text" }]}>
            <Editor
              initialValue={form.getFieldValue("content")}
              onChange={(value: string) => form.setFieldValue("content", value)}
            />
          </Form.Item>
        )}
        {elementType === "imageSingle" && (
          <Form.Item
            label="Content"
            name="content"
            rules={[{ required: true, message: "Please upload a single image or multiple" }]}
          ></Form.Item>
        )}
        <Spacer height={4} />
        <div className="flex justify-end">
          <Button type="submit">Add</Button>
          <Spacer width={2} />
          <Button variant="destructive" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateOrUpdateCanvasElementModal;
