
import CreateOrUpdateMarketingEditorForm from "@Eikochain/components/organisms/CreateOrUpdateMarketingEditorForm";
import { BackgroundColorType, MarketingPanelMeta } from '@Eikochain/components/organisms/MarketingEditor/types';
import useGetPanelById from "@Eikochain/graphql/hooks/useGetPanelById";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const marketingPanel = {
  title: "New marketing panel",
  panel: {
    logoUrl: "https://eikochain.com",
    menuStyling: {
      fontFamily: "Arial",
      fontSize: 12,
      fontColor: "#ffffff",
      gradientBackgroundColor: "linear-gradient(77deg, rgb(15, 53, 62) 0%, rgb(69, 79, 74) 81%, rgb(80, 85, 81) 100%)",
      solidBackgroundColor: "#EEE",
      backgroundColorType: "gradient" as BackgroundColorType,
    },
    menuSteps: [
      {
        id: "serverId",
        localId: 1,
        position: 1,
        title: "Just some text",
      }
    ],
    canvases: []
  }
}

const MarketingPanelsDetail = () => {
  const breadcrumbs = [{
    name: "Edit marketing panel"
  }]
  const { id: panelId } = useParams<{ id: string }>();
  const [panel, setPanel] = useState<MarketingPanelMeta>()
  const [result] = useGetPanelById(panelId as string);
  useEffect(() => {
    const { data } = result;
    const panelResult = data?.panelById;
    if (panelResult) {
      const panelResultFormatted = {
        id: panelId,
        name: panelResult.name,
        panel: {
          logoUrl: null,
          menuStyling: panelResult.menuStyling,
          canvases: panelResult.canvases,
          menuSteps: panelResult.menuSteps,
        }
      }
      setPanel(panelResultFormatted)
    }
  }, [result?.data])

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="p-4">
        <CreateOrUpdateMarketingEditorForm instance={panel} />
      </div>
    </BaseLayout>
  )
};

export default MarketingPanelsDetail;
