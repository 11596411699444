import { BreakdownItem } from "@Eikochain/__generated__/graphql";
import ActivitiesTable from "@Eikochain/components/ui/activities-table";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@Eikochain/components/ui/card";
import ActivityTypeBreakdownPieChart from "@Eikochain/components/ui/charts/activity-type-breakdown-donut-chart";
import TransportMethodBreakdownPieChart from "@Eikochain/components/ui/charts/transport-method-breakdown-donut-chart";
import ProductsTable from "@Eikochain/components/ui/products-table";
import useGetActivityTypeBreakdown from "@Eikochain/graphql/hooks/useGetActivityTypeBreakdown";
import useGetActivityTypeEmissionsBreakdown from "@Eikochain/graphql/hooks/useGetActivityTypeEmissionsBreakdown";
import useHighestEmittingActivities from "@Eikochain/graphql/hooks/useGetHighestEmittingActivities";
import useGetHighestEmittingProducts from "@Eikochain/graphql/hooks/useGetHighestEmittingProducts";
import useGetHighestEmittingRoutes from "@Eikochain/graphql/hooks/useGetHighestEmittingRoutes";
import useGetTransportMethodBreakdown from "@Eikochain/graphql/hooks/useGetTransportMethodBreakdown";
import useGetTotalCompanyEmissions from "@Eikochain/graphql/hooks/useTotalCompanyEmissions";
import useGetTotalProductEmissions from "@Eikochain/graphql/hooks/useTotalProductEmissions";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { getNumericSeparator } from "@Eikochain/utils";

export default function Dashboard() {
  const breadcrumbs = [{
    name: "Dashboard"
  }]
  const [totalCompanyEmissions] = useGetTotalCompanyEmissions()
  const [totalProductEmissions] = useGetTotalProductEmissions()
  const [highestEmittingActivities] = useHighestEmittingActivities()
  const [highestEmittingProducts] = useGetHighestEmittingProducts()
  const [highestEmittingRoutes] = useGetHighestEmittingRoutes()
  const [activityTypeBreakdown] = useGetActivityTypeBreakdown()
  const [activityTypeEmissionsBreakdown] = useGetActivityTypeEmissionsBreakdown()
  const [transportMethodBreakdown] = useGetTransportMethodBreakdown()
  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="grid gap-4">
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
          <Card className="flex flex-col h-full">
            <CardHeader className="flex flex-row items-center justify-between">
              <CardTitle>
                Total product emissions
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col justify-between h-full">
              <h1 className="font-semibold">
                {getNumericSeparator(totalProductEmissions?.data?.totalProductEmissions ?? 0)}kg
              </h1>
            </CardContent>
          </Card>
          <Card className="flex flex-col h-full">
            <CardHeader className="flex flex-row items-center justify-between">
              <CardTitle>
                Total company emissions
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col justify-between h-full">
              <h1 className="font-semibold">
                {getNumericSeparator(totalCompanyEmissions?.data?.totalCompanyEmissions ?? 0)}kg
              </h1>
            </CardContent>
          </Card>
          <Card className="flex flex-col h-full">
            <CardHeader className="flex flex-row items-center justify-between">
              <CardTitle>
                Highest emitting activities
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col justify-between h-full">
              <ActivitiesTable
                data={highestEmittingActivities?.data?.highestEmittingActivities ?? []}
                isFetching={highestEmittingActivities.fetching}
                showColumnToggler={false}
                showRowCount={false}
                hidePaginationButtons
                hideShippingCalculatorSpecificColumns
              />
            </CardContent>
          </Card>
          <Card className="flex flex-col h-full">
            <CardHeader className="flex flex-row items-center justify-between">
              <CardTitle>
                Highest emitting products
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col justify-between h-full">
              <ProductsTable
                data={highestEmittingProducts?.data?.highestEmittingProducts ?? []}
                isFetching={highestEmittingProducts.fetching}
                showColumnToggler={false}
                showRowCount={false}
                hidePaginationButtons
                withEmissions
                withActions={false}
                withUrl={false}
              />
            </CardContent>
          </Card>
          <Card className="flex flex-col h-full">
            <CardHeader className="flex flex-row items-center justify-between">
              <CardTitle>
                Highest emitting routes
              </CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col justify-between h-full">
              <ActivitiesTable
                data={highestEmittingRoutes?.data?.highestEmittingRoutes ?? []}
                isFetching={highestEmittingRoutes.fetching}
                showColumnToggler={false}
                showRowCount={false}
                hidePaginationButtons
                hideShippingCalculatorSpecificColumns
              />
            </CardContent>
          </Card>
          <Card className="flex flex-col h-full">
            <CardHeader className="flex-col">
              <CardTitle>
                Breakdown of activities by type
              </CardTitle>
              <CardDescription>All time</CardDescription>
            </CardHeader>
            <CardContent className="flex flex-col justify-between h-full">
              <ActivityTypeBreakdownPieChart
                data={activityTypeBreakdown?.data?.activityTypeBreakdown?.map((d: BreakdownItem) => (
                  {
                    label: d.name,
                    count: d.count,
                  }
                )) ?? []}
                isFetching={activityTypeBreakdown?.fetching}
                includeLegend
              />
            </CardContent>
          </Card>
          <Card className="flex flex-col h-full">
            <CardHeader className="flex-col">
              <CardTitle>
                Breakdown of activities by emissions
              </CardTitle>
              <CardDescription>All time</CardDescription>
            </CardHeader>
            <CardContent className="flex flex-col justify-between h-full">
              <ActivityTypeBreakdownPieChart
                data={activityTypeEmissionsBreakdown?.data?.activityTypeEmissionsBreakdown?.map((d: BreakdownItem) => (
                  {
                    label: d.name,
                    count: d.count,
                  }
                )) ?? []}
                dataTypeDisplayPluralised="kg CO2"
                isFetching={activityTypeEmissionsBreakdown?.fetching}
                includeLegend
              />
            </CardContent>
          </Card>
          <Card className="flex flex-col h-full">
            <CardHeader className="flex-col">
              <CardTitle>
                Breakdown of transport methods by type
              </CardTitle>
              <CardDescription>All time</CardDescription>
            </CardHeader>
            <CardContent className="flex flex-col justify-between h-full">
              <TransportMethodBreakdownPieChart
                data={transportMethodBreakdown?.data?.transportMethodBreakdown?.map((d: BreakdownItem) => (
                  {
                    label: d.name,
                    count: d.count,
                  }
                )) ?? []}
                isFetching={transportMethodBreakdown?.fetching}
                includeLegend
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </BaseLayout>
  );
}
