import { BLANK_PDF, Template } from "@pdfme/common"
import text from "./schemas/text"
import image from "./schemas/graphics/image"
import barcodes from "./schemas/barcodes"
import statistic from "./schemas/statistic"
import { Designer } from "@pdfme/ui"
import { useEffect, useRef, useState } from "react"
import { toast } from "sonner"
import BaseLayout from "@Eikochain/layouts/BaseLayout"
import { Button } from "@Eikochain/components/ui/button"
import { Save, Upload } from "lucide-react"
import { Label } from "@Eikochain/components/ui/label"

const breadcrumbs = [
    {
        name: "Templates",
        url: "/app/templates"
    },
    {
        name: "Supplier Data Request Report PDF",
        url: "/app/templates/pdfReport"
    }
]

export default function Page() {
    const designerRef = useRef<HTMLDivElement>(null)
    const [designer, setDesigner] = useState<Designer | null>(null)
    const template = null

    useEffect(() => {
        if (designerRef.current && !designer) {

            const designerEl = new Designer({
                domContainer: designerRef.current, template: template ? JSON.parse(template) : {
                    basePdf: BLANK_PDF,
                    schemas: []
                } satisfies Template,
                plugins: {
                    Text: text,
                    Image: image,
                    "QR Code": barcodes.qrcode,
                    "Statistic": statistic
                },
            })

            designerEl.onSaveTemplate((template) => {
                // TODO: Save template. using localstorage for demo
                toast.success("Template saved")
                console.log(template)
            })

            setDesigner(designerEl)
        }
    }, [designer, designerRef])

    const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files?.length === 0 || !designer) return
        const file = e.currentTarget.files![0]

        const currentTemplate = designer.getTemplate()
        const reader = new FileReader()

        reader.readAsDataURL(file)
        reader.onload = (e) => {
            designer?.updateTemplate({
                ...currentTemplate,
                basePdf: e.target?.result as string,
            })
        }
    }

    return (
        <BaseLayout breadcrumbs={breadcrumbs}>
            <div ref={designerRef} className="flex-grow"></div>
            <div className="flex flex-col gap-2 items-center flex-grow p-4">
                <div className="w-full flex gap-2 justify-end">
                    <Label
                        htmlFor="basePdfUpload"
                        className="items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 flex gap-2"
                    >
                        <Upload className="size-4" />
                        Change base PDF
                        <input onInput={handleFileChange} id="basePdfUpload" type="file" accept="application/pdf" className="hidden" />
                    </Label>
                    <Button onClick={() => designer?.saveTemplate()} className="flex gap-2">
                        <Save className="size-4" />
                        Save
                    </Button>
                </div>
            </div>
        </BaseLayout>
    )
}
