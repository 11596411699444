
import * as yup from "yup";

export const emissionFactorsUploadFormSchema = yup.object().shape({
  documentUpload: yup.mixed()
    .required("Document is required")
    .test(
      "fileSize", "The file is too large, cannot be larger than 2MB", value => {
        if (!(value as File)?.size) return true
        return (value as File).size <= 2000000
      }
    ).test(
      "fileFormat", "The file format must be CSV", (value) => {
        const supportedFormats = ['csv'];
        if (value) {
          const splitValue = ((value as File).type).split("/").at(-1) as string
          return supportedFormats.includes(splitValue)
        }
        return true
      }
    )
});
