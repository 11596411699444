import { Report } from "@Eikochain/__generated__/graphql";
import DataTable from "../data-table";
import columns from "./columns";

interface ReportsTableProps {
  data: Report[];
  isFetching: boolean;
  showRowCount?: boolean;
  hidePaginationButtons?: boolean;
}

export default function ReportsTable({
  data,
  isFetching,
  showRowCount = true,
  hidePaginationButtons,
}: ReportsTableProps) {
  return (
    <DataTable
      data={data}
      isFetching={isFetching}
      columns={columns}
      showRowCount={showRowCount}
      showColumnToggler={false}
      hidePaginationButtons={hidePaginationButtons}
    />
  )
}
