import H3 from "../atoms/H3";
import Paragraph from "../atoms/P";
import CarbonActivityMetrics from "./activity-metrics";
import CalculateEmissionsButtonForProduct from "./calculate-emissions-button/calculate-emissions-button-for-product";

interface ProductActivityMetricsSectionProps {
  productId: string;
}

export default function ProductActivityMetricsSection ({
  productId
}: ProductActivityMetricsSectionProps) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <H3>Metrics</H3>
          <Paragraph>Carbon emissions for the selected product are listed here.</Paragraph>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <CalculateEmissionsButtonForProduct productId={productId} />
        </div>
      </div>
      <CarbonActivityMetrics productId={productId} />
    </div>
  )
}
