import Authenticate from "@Eikochain/pages/Authenticate";
import Logout from "@Eikochain/pages/Logout";
import { CookiesProvider } from "react-cookie";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider as UrqlProvider } from "urql";
import { client as urqlClient } from "./graphql/client";
import CoreLayout from "./layouts/CoreLayout";
import CustomRoutes from "./layouts/CustomRoutes";
import { PrivateRoutes } from "./layouts/PrivateRoutes";
import CarbonCalculator from "./pages/CarbonCalculator/CarbonCalculator";
import IntegrationsList from "./pages/Integrations/IntegrationsList";
import AddMarketingPanels from "./pages/MarketingPanels/AddMarketingPanels";
import MarketingPanelsDetail from "./pages/MarketingPanels/MarketingPanelsDetail";
import MarketingPanelsList from "./pages/MarketingPanels/MarketingPanelsList";
import ViewMarketingPanel from "./pages/MarketingPanels/ViewMarketingPanel";

import { Toaster } from "./components/ui/toaster";
import { Toaster as SonnerToaster } from "sonner";
import "./index.css";
import ChangePassword from "./pages/ChangePassword";
import ProductCalculator from "./pages/CompanyCalculator/CompanyCalculator";
import Dashboard from "./pages/Dashboard";
import AddProduct from "./pages/Products/AddProduct";
import ProductDetail from "./pages/Products/ProductDetail";
import ProductsList from "./pages/Products/ProductsList";
import ShippingCalculator from "./pages/ShippingCalculator";
import UploadedFileDetail from "./pages/ShippingCalculator/UploadedFileDetail";
import UploadEmissionFactorsByDocument from "./pages/admin/upload-emission-factors-by-document";
import DataExplorer from "./pages/data-explorer/data-explorer";
import DataExplorerEmissionActivity from "./pages/data-explorer/data-explorer-emission-activity";
import DataExplorerEmissionSource from "./pages/data-explorer/data-explorer-emission-source";
import NotFoundPage from "./pages/not-found";
import ReportingPage from "./pages/reporting/reporting";
import SupplierInformationRequestPage from "./pages/supplier-information-request-form";
import CreateSupplierInformationRequest from "./pages/suppliers/create-supplier-request";
import SupplierDetail from "./pages/suppliers/supplier-detail";
import SupplierInformationRequestDetail from "./pages/suppliers/supplier-request-detail";
import SupplierInformationRequests from "./pages/suppliers/supplier-requests";
import Suppliers from "./pages/suppliers/suppliers";
import LandingPage from "./pages/website/landing-page";
//import PricingPage from "./pages/website/pricing";
import { PHProvider } from "./providers/posthog";
import SentryProvider from "./providers/sentry";
import { SupplierResponseDetail } from "./pages/suppliers/supplier-response-detail";
import PdfTemplateEditor from "./pages/templates/pdfTemplateEditor/page";

export default function App() {
    return (
        <SentryProvider>
            <BrowserRouter>
                <HelmetProvider>
                    <CookiesProvider>
                        <UrqlProvider value={urqlClient}>
                            <PHProvider>
                                <CoreLayout>
                                    <CustomRoutes>
                                        <Route path="/" element={<LandingPage />} />
                                        {/*<Route path="/pricing/" element={<PricingPage />} />*/}
                                        <Route path="/auth/" element={<Authenticate />} />
                                        <Route path="/supplier-portal/request/" element={<SupplierInformationRequestPage />} />
                                        <Route element={<PrivateRoutes />}>
                                            <Route path="/logout/" element={<Logout />} />
                                            <Route path="/app/dashboard/" element={<Dashboard />} />
                                            <Route path="/app/data-explorer/" element={<DataExplorer />} />
                                            <Route path="/app/suppliers/" element={<Suppliers />} />
                                            <Route path="/app/suppliers/view/:id" element={<SupplierDetail />} />
                                            <Route path="/app/suppliers/requests/" element={<SupplierInformationRequests />} />
                                            <Route path="/app/suppliers/requests/create/" element={<CreateSupplierInformationRequest />} />
                                            <Route path="/app/suppliers/requests/create/:id" element={<CreateSupplierInformationRequest />} />
                                            <Route path="/app/suppliers/requests/view/:id" element={<SupplierInformationRequestDetail />} />
                                            <Route path="/app/suppliers/responses/:id" element={<SupplierResponseDetail />} />
                                            <Route path="/app/data-explorer/emission-activity/:id" element={<DataExplorerEmissionActivity />} />
                                            <Route path="/app/data-explorer/emission-source/:id" element={<DataExplorerEmissionSource />} />
                                            <Route path="/app/products/" element={<ProductsList />} />
                                            <Route path="/app/products/add" element={<AddProduct />} />
                                            <Route path="/app/products/detail/:id" element={<ProductDetail />} />
                                            <Route path="/app/supply-chains/" element={<MarketingPanelsList />} />
                                            <Route path="/app/supply-chains/add" element={<AddMarketingPanels />} />
                                            <Route path="/app/supply-chains/detail/:id" element={<MarketingPanelsDetail />} />
                                            <Route path="/app/marketing-panel/view/:id" element={<ViewMarketingPanel />} />
                                            <Route path="/app/integrations/" element={<IntegrationsList />} />
                                            <Route path="/app/reporting/" element={<ReportingPage />} />
                                            <Route path="/app/product-calculator/" element={<CarbonCalculator />} />
                                            <Route path="/app/company-calculator/" element={<ProductCalculator />} />
                                            <Route path="/app/shipping-calculator/" element={<ShippingCalculator />} />
                                            <Route path="/app/uploads/detail/:id" element={<UploadedFileDetail />} />
                                            <Route path="/app/ChangePassword/" element={<ChangePassword />} />
                                            <Route path="/app/templates/pdfReport/" element={<PdfTemplateEditor />} />
                                            <Route path="/admin/emissions-engine/upload-emission-factors/" element={<UploadEmissionFactorsByDocument />} />
                                        </Route>
                                        <Route path="*" element={<NotFoundPage />} />
                                    </CustomRoutes>
                                </CoreLayout>
                                <Toaster />
                                <SonnerToaster />
                            </PHProvider>
                        </UrqlProvider>
                    </CookiesProvider>
                </HelmetProvider>
            </BrowserRouter>
        </SentryProvider>
    );
}
