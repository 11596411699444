import { EmissionFactorMaterialTag } from "@Eikochain/__generated__/graphql";
import { cn } from "@Eikochain/utils";
import { CheckIcon, ChevronDownIcon } from "lucide-react";
import { useState } from "react";
import { Control, UseFormSetValue } from "react-hook-form";
import { Button } from "../../button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "../../command";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Popover, PopoverContent, PopoverTrigger } from "../../popover";

interface MaterialFieldProps {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  materialsCategories: EmissionFactorMaterialTag[]
}

export default function MaterialField({
  control,
  setValue,
  materialsCategories
}: MaterialFieldProps) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  return (
    <FormField
      control={control}
      name="material"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Material</FormLabel>
          <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between font-normal",
                    !field.value && "text-muted-foreground"
                  )}
                >
                  <span className="text-ellipsis overflow-hidden">
                    {field.value ?? "Select material"}
                  </span>
                  <ChevronDownIcon className="ml-2 h-4 w-4 flex-shrink-0" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] p-0">
              <Command>
                <CommandInput placeholder="Search material..." />
                <CommandList>
                  <CommandEmpty>No materials found</CommandEmpty>
                  {
                    materialsCategories?.length ? materialsCategories.map(mt => (
                      <>
                        <CommandGroup key={mt.name} heading={mt.name}>
                          {
                            mt.materials?.map(m => (
                              <CommandItem
                                key={m.name}
                                value={m.name}
                                onSelect={() => {
                                  setValue("material", m.name);
                                  setPopoverOpen(false)
                                }}
                              >
                                {m.name}
                                <CheckIcon
                                  className={cn(
                                    "ml-auto h-4 w-4",
                                    m.name === field.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                              </CommandItem>
                            ))
                          }
                        </CommandGroup>
                      </>
                    )) : null
                  }
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
