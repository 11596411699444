import { gql } from "urql";

export const EmailResendMutation = gql`
  mutation ResendRequestEmailMutation(
    $input: ResendEmailInput!
  ) {
    resendRequestEmail(input: $input) {
        success
        reason
    }
  }
`;
