import Image from "@Eikochain/components/atoms/Image";
import BlurIn from "@Eikochain/components/ui/blur-in";
import DataProviderLogos from "@Eikochain/components/ui/data-provider-logos";
import DotPattern from "@Eikochain/components/ui/dot-pattern";
import Footer from "@Eikochain/components/ui/footer";
import InterestCallbackButton from "@Eikochain/components/ui/interest-callback-button";
import { LandingPageStatsBento } from "@Eikochain/components/ui/landing-page-stats-cards";
import FeaturesReelPlayer from "@Eikochain/components/ui/landing-page/features-reel-player";
import NavigationBar from "@Eikochain/components/ui/navigation-bar/navigation-bar";
import CoreLayout from "@Eikochain/layouts/CoreLayout";
import { interestCallbackSent } from "@Eikochain/state/atoms/interest-callback-sent";
import { useAtomValue } from "jotai";
import { CheckCheckIcon, CheckIcon } from "lucide-react";

export default function LandingPage() {
  const interestSent = useAtomValue(interestCallbackSent)
  return (
    <CoreLayout>
      <main className="overflow-hidden">
        <div className="flex justify-center z-10">
          <NavigationBar />
        </div>
        <section className="flex flex-col bg-white w-full pb-12 md:pb-24">
          <DotPattern
            width={20}
            height={20}
            cx={1}
            cy={1}
            cr={1}
          />
          <div className="flex flex-col gap-16 mt-28 lg:mt-52 mx-auto w-full max-w-[1400px]">
            <div className="flex flex-col items-center w-full gap-4 lg:gap-8 p-6 lg:p-4">
              <BlurIn
                word="Calculate, benchmark, and market your sustainability in one complete platform"
                className="font-semibold text-center text-5xl md:leading-tight tracking-tight text-balance text-black"
              />
              <div className="flex flex-row justify-center items-center flex-wrap gap-x-8 gap-y-4 text-xl text-black">
                <span className="flex flex-shrink-0 gap-2 items-center whitespace-nowrap">
                  <CheckCheckIcon className="text-emerald-600" />
                  Scope 1, 2 and 3 emissions
                </span>
                <span className="flex flex-shrink-0 gap-2 items-center whitespace-nowrap">
                  <CheckCheckIcon className="text-emerald-600" />
                  Product LCA's
                </span>
                <span className="flex flex-shrink-0 gap-2 items-center whitespace-nowrap">
                    <CheckCheckIcon className="text-emerald-600" />
                  Supplier management
                </span>
                <span className="flex flex-shrink-0 gap-2 items-center whitespace-nowrap">
                  <CheckCheckIcon className="text-emerald-600" />
                  Reporting and marketing
                </span>
              </div>
              <div className="mt-8">
                {
                  interestSent?.length ? (
                    <div className="flex flex-row items-center gap-4">
                      <CheckIcon className="text-white" />
                      <span>We'll reach you on {interestSent} soon!</span>
                    </div>
                  ) : <InterestCallbackButton variant="huge" />
                }
              </div>
            </div>
            <div className="flex flex-col w-full">
              <span className="font-lg text-black tracking-wide font-semibold text-center">TRUSTED BY</span>
              <div className="w-full h-full md:h-[45px] max-w-[1350px] mx-auto flex flex-col sm:flex-row gap-10 lg:gap-24 items-center justify-around p-8 lg:p-12">
                <Image src="/pericaya-logo.webp" alt="Pericaya logo" className="max-h-[45px]" />
                <Image src="/and-open-logo.webp" alt="&Open logo" className="max-h-[45px]" />
                <Image src="/circee-logo.webp" alt="Circee logo" className="max-h-[45px]" />
                <Image src="/sustain-ability-logo.webp" alt="UKSustainAbility logo" className="max-h-[45px]" />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-slate-50 py-12">
          <div className="p-4 lg:p-12">
            <BlurIn
              word="Keeping carbon at bay"
              className="font-semibold text-center text-5xl md:leading-tight tracking-tight text-balance text-emerald-900"
            />
          </div>
          <LandingPageStatsBento />
        </section>
        <section className="bg-emerald-900 py-12">
          <DotPattern
            width={20}
            height={20}
            cx={1}
            cy={1}
            cr={1}
            className="fill-emerald-600/80"
          />
          <div className="p-4 lg:p-12">
            <BlurIn
              word="Effortlessly handle all your sustainability needs"
              className="font-semibold text-center text-5xl md:leading-tight tracking-tight text-balance bg-gradient-to-b from-emerald-50 to-emerald-200 bg-clip-text text-transparent"
            />
          </div>
          <FeaturesReelPlayer />
          <div className="flex justify-center p-12 md:p-24 w-full">
            <InterestCallbackButton variant="huge" />
          </div>
        </section>
        <section className="bg-slate-50 pt-12">
          <DataProviderLogos />
        </section>
        <Footer />
      </main>
    </CoreLayout>
  )
}
