import { useAtom, useSetAtom } from "jotai";
import {
  canvasMenuStepsAtom
} from "@Eikochain/state/atoms/CanvasMenuContent";
import { ICanvasMenuStep } from "@Eikochain/components/organisms/MarketingEditor/types";

const useCreateMenuStep = () => {
  const [steps, setSteps] = useAtom(canvasMenuStepsAtom);

  function execute() {
    const localId = steps.length ? Math.max(...steps.map(step => step.localId)) + 1 : 1;
    const position = steps.length ? (steps[steps.length - 1] as unknown as ICanvasMenuStep).position + 1 : 1;
    const serverId = null;
    const newStep = {
      serverId,
      localId,
      title: "Your new step",
      position
    }
    setSteps([...steps, newStep])
  }

  return execute;
};
  
export default useCreateMenuStep;
