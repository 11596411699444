import { gql, useQuery } from 'urql';

const GetActivityTypeEmissionsBreakdownQuery = gql`
  query {
    activityTypeEmissionsBreakdown {
      name
      count
    }
  }
`;

const useGetActivityTypeEmissionsBreakdown = () => {
  const result = useQuery({
    query: GetActivityTypeEmissionsBreakdownQuery,
  })
  return result;
}

export default useGetActivityTypeEmissionsBreakdown;
