import { PDFRenderProps } from '@pdfme/common';
import { StatisticSchema } from './types';
import { pdfRender as parentPdfRender } from '../text/pdfRender';

export const pdfRender = async (arg: PDFRenderProps<StatisticSchema>) => {
    const { value, schema, ...rest } = arg;

    const renderArgs = {
        value: "Value for statistic",
        schema,
        ...rest,
    };

    await parentPdfRender(renderArgs);
};
