import { CreateSupplierInformationRequestSuccess } from "@Eikochain/__generated__/graphql";
import { Check, CheckCheckIcon, CopyIcon, PlusIcon } from "lucide-react";
import { useState } from "react";
import { Button } from "../../button";

interface AddSupplierInformationRequestFormSuccessProps {
    data: CreateSupplierInformationRequestSuccess;
    onCreateNewRequest: () => void;
}

export default function AddSupplierInformationRequestFormSuccess({
    onCreateNewRequest,
    data
}: AddSupplierInformationRequestFormSuccessProps) {
    const [hasCopied, setHasCopied] = useState(false);

    return (
        <div className="flex flex-col items-center justify-center gap-4">
            <div className="flex items-center justify-center rounded-full p-2 bg-emerald-100">
                <div className="flex items-center justify-center rounded-full h-16 w-16 bg-emerald-700">
                    <CheckCheckIcon className="text-white" />
                </div>
            </div>
            <h3 className="text-center">Information request has been sent</h3>
            {/* Copy link to clipboard */}
            <div className="flex flex-row gap-2 items-center">
                <Button icon={<PlusIcon />} onClick={onCreateNewRequest}>Create another supplier request</Button>
                <Button
                    variant="secondary"
                    onClick={() => {
                        navigator.clipboard.writeText(`https://eikoiq.com/supplier-portal/request?id=${data.id}`)
                        setHasCopied(true)
                    }}
                    className="flex gap-2">
                    {hasCopied ? <Check className="text-green w-4 h-4" /> : <CopyIcon className="w-4 h-4" />}
                    Copy link
                </Button>
            </div>
        </div>
    )
}
