import { Control } from "react-hook-form";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Input } from "../../input";

interface DocumentUploadFieldProps {
  control: Control<any>;
}

export default function DocumentUploadField({
  control,
}: DocumentUploadFieldProps) {
  return (
    <FormField
      control={control}
      name="documentUpload"
      render={({ field: { value, onChange, ...fieldProps } }) => {
        return (
          <FormItem>
            <FormLabel>CSV file</FormLabel>
            <FormDescription>
              A table of emission factors, their data sources and values
            </FormDescription>
            <FormControl>
              <Input
                {...fieldProps}
                type="file"
                accept=".csv"
                onChange={(event) =>
                  onChange(event.target.files && event.target.files[0])
                }
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )
      }}
    />
  )
}
