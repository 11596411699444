import { Activity } from "@Eikochain/__generated__/graphql";
import { getNumericSeparator } from "@Eikochain/utils";

interface ActivityUnitsOverviewProps {
  activity: Activity
}

export default function ActivityUnitsOverview({
  activity
}: ActivityUnitsOverviewProps) {
  const activityUnits = [
    activity.weight ? `${activity.weight}kg` : null,
    activity.energy ? `${activity.energy}kWh` : null,
    activity.spend ? `${activity.currency?.symbol}${activity.spend}` : null,
    activity.route ? `${getNumericSeparator(activity.route.distance!)}km` : null,
  ]
  const renderActivityUnits = () => {
    return activityUnits.filter(unit => unit !== null).join(', ');
  }

  return <>{renderActivityUnits()}</>
}
