import { gql, useQuery } from 'urql';

const GetProductsForOrganizationIntegrationQuery = gql`
  query($organizationIntegrationId: String!) {
    productsForOrganizationIntegration(organizationIntegrationId: $organizationIntegrationId) {
      id
      name
      url
      description
      category
      externalId
      image: imageUrl
    }
  }
`;

const useGetProductsForOrganizationIntegration = (id: string) => {
  const result = useQuery({
    query: GetProductsForOrganizationIntegrationQuery,
    variables: { organizationIntegrationId: id }
  })
  return result;
}

export default useGetProductsForOrganizationIntegration;
