import Cookies from "js-cookie";

export const COOKIE_NAME = "eikochain_authtoken";
export const COOKIE_EXPIRES_DAYS = 14;

export function setAuthToken(token: string) {
    Cookies.set(COOKIE_NAME, token, { expires: COOKIE_EXPIRES_DAYS });
}

export function clearAuthToken() {
    Cookies.remove(COOKIE_NAME);
}

export function getAuthToken() {
    return Cookies.get(COOKIE_NAME);
}
