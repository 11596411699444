import { Button } from "@Eikochain/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@Eikochain/components/ui/form";
import { PasswordInput } from "@Eikochain/components/ui/password-input";
import { UpdatePasswordForUserMutation } from "@Eikochain/graphql/hooks/useUpdatePasswordForUser";
import useLogout from "@Eikochain/hooks/useLogout";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { useMutation } from "urql";
import { updatePasswordSchema } from "./schema";

type Inputs = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirmed: string;
};

export default function UpdatePasswordForm () {
  const logout = useLogout();
  const form = useForm<Inputs>({
    resolver: yupResolver(updatePasswordSchema)
  });

  const [mutationResult, executeMutation] = useMutation(UpdatePasswordForUserMutation);

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    executeMutation(data).then((result): any => {
      const { data } = result;
      if (data?.updatePasswordForUser?.success) {
        logout();
        toast.success("Password changed successfully!");
      } else {
        toast.error("Please check the current password is correct and retry");
        form.setError("root", data?.updatePasswordForUser?.error);
      }
    });
  };
  return (
    <Form {...form}>
      <form className="flex flex-col gap-4" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="oldPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Current password</FormLabel>
              <FormControl>
                <PasswordInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
            )}
        />
        <FormField
          control={form.control}
          name="newPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>New password</FormLabel>
              <FormControl>
                <PasswordInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
            )}
        />
        <FormField
          control={form.control}
          name="newPasswordConfirmed"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirm your new password</FormLabel>
              <FormControl>
                <PasswordInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormMessage>
          {form.formState.errors.root && "Incorrect current password"}
        </FormMessage>
        <Button type="submit" loading={mutationResult.fetching}>
          Change
        </Button>
      </form>
    </Form>
  );
};
