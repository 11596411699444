import { gql } from 'urql';

export const DeleteSupplierInformationRequestTemplateMutation = gql`
  mutation DeleteSupplierInformationRequestTemplate($input: DeleteSupplierInformationRequestTemplateInput!) {
    deleteSupplierInformationRequestTemplate(input: $input) {
        success
        reason
      }
    }
`
