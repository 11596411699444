import { gql } from 'urql';

export const DeleteSupplierMutation = gql`
  mutation DeleteSupplier($id: String!) {
    deleteSupplier(supplierId: $id) {
      ... on DeleteSupplierSuccess {
        success
      }
      ... on DeleteSupplierErrors {
        errors
      }
    }
  }
`;
