import { Button } from "@Eikochain/components/ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@Eikochain/components/ui/form";
import { Input } from "@Eikochain/components/ui/input";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { enterIntegrationUrlFormSchema } from "./schema";

type EnterIntegrationUrlFormInputs = {
  integrationUrl: string;
}

interface EnterIntegrationUrlFormProps {
  onFinish: (data: EnterIntegrationUrlFormInputs) => void;
}

const EnterIntegrationUrlForm = ({
  onFinish
}: EnterIntegrationUrlFormProps) => {
  const form = useForm({
    mode: "onChange",
    resolver: yupResolver(enterIntegrationUrlFormSchema),
    defaultValues: {
      integrationUrl: ""
    }
  })

  const integrationUrl = form.watch("integrationUrl");

  const onSubmit = (data: EnterIntegrationUrlFormInputs) => {
    onFinish(data)
  };

  return (
    <Form {...form}>
      <form
        className="flex flex-col space-y-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name="integrationUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Your shop URL</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              {
                integrationUrl ? (
                  <FormDescription className="flex">
                    We'll look for products at the following URL: {integrationUrl}/products.json. Please ensure this address is reachable on your site
                  </FormDescription>
                ) : null
              }
              <FormMessage />
            </FormItem>
            )}
        />
        <Button type="submit">Integrate</Button>
      </form>
    </Form>
  )
};

export default EnterIntegrationUrlForm;
