import { Button } from '@Eikochain/components/ui/button';
import MarketingPanelsTable from "@Eikochain/components/ui/marketing-panels-table";
import useGetPanels from "@Eikochain/graphql/hooks/useGetPanels";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { PlusIcon } from 'lucide-react';
import { Link } from "react-router-dom";

const MarketingPanelsList = () => {
  const breadcrumbs = [{
    name: "Marketing panels"
  }]
  const [result, reexecuteQuery] = useGetPanels();

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="w-full">
        {
          result.data?.panels && (
            <MarketingPanelsTable
              data={result.data?.panels}
              isFetching={result.data?.isFetching}
            />
          )
        }
        <Link to="/app/supply-chains/add/">
          <Button icon={<PlusIcon />}>Add a new marketing panel</Button>
        </Link>
      </div>
    </BaseLayout>
  );
};

export default MarketingPanelsList;
