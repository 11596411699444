import Spacer from "@Eikochain/components/atoms/Spacer";
import { backgroundColorTypeAtom, gradientBackgroundColorAtom, solidBackgroundColorAtom } from "@Eikochain/state/atoms/CanvasMenuStyles";
import { whiteColor } from "@Eikochain/themes/constants";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import PanelLogo from "../PanelLogo";
import CanvasMenuSteps from "./CanvasMenuSteps";

interface INavigationMenu {
  isEditing: boolean;
  withLogo?: boolean;
}

const NavigationMenu = ({
  isEditing = false,
  withLogo = true,
}: INavigationMenu) => {
  const solidBackgroundColor = useAtomValue(solidBackgroundColorAtom);
  const gradientBackgroundColor = useAtomValue(gradientBackgroundColorAtom);
  const backgroundColorType = useAtomValue(backgroundColorTypeAtom);
  const getBackgroundColor = useMemo(
    () => backgroundColorType === "solid" ? solidBackgroundColor : gradientBackgroundColor,
    [solidBackgroundColor, gradientBackgroundColor, backgroundColorType]
  );

  return (
    <div className="flex flex-col h-full w-full justify-between p-4 min-w-[260px] max-w-[260px] overflow-y-scroll CanvasNavContainer"
      style={{
        background: getBackgroundColor,
      }}
    >
      <div className="w-full">
        {
          withLogo ? (
            <>
              <PanelLogo />
              <Spacer height={2} />
            </>
          ) : (
            <Spacer height={8} />
          )
        }
        <CanvasMenuSteps isEditing={isEditing} />
      </div>
      <div>
        <Spacer height={2} />
        <span style={{ color: whiteColor }}>Powered by Eikochain</span>
      </div>
    </div>
  )
}

export default NavigationMenu;
