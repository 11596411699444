
import { EmissionCalculationRangeResult } from "@Eikochain/__generated__/graphql";
import { getToDecimalPlaces } from "@Eikochain/utils";
import { ColumnDef } from "@tanstack/react-table";
import { InfoIcon, ScanSearchIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Badge } from "../badge";
import { Dialog, DialogTrigger } from "../dialog";
import InspectCalculationDialog from "../inspect-calculation-dialog/inspect-calculation-dialog";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../tooltip";

const columns = (originalEmissionResult: number, comparisonType?: string): ColumnDef<EmissionCalculationRangeResult>[] => [
  {
    accessorKey: "subjectType",
    header: comparisonType ?? "Subject",
    cell: ({ row }) => <div>{row.original.subject}</div>,
  },
  {
    accessorKey: "source",
    header: "Source",
    cell: ({ row }) => (
      <div className="max-w-[100px]">
        <TooltipProvider>
           <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <div className="truncate">{row.getValue("source")}</div>
            </TooltipTrigger>
            <TooltipContent>{row.getValue("source")}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    ),
  },
  {
    accessorKey: "dataset",
    header: () => (
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <div className="flex truncate gap-2 items-center">
              Datasets
              <InfoIcon />
            </div>
          </TooltipTrigger>
          <TooltipContent>
            Links may not always be available for paid datasets, however we'll try to include these where possible
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ),
    cell: ({ row }) => (
      <div className="max-w-[100px]">
        <TooltipProvider>
           <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              {
                row.original.sourceDataset?.url ? (
                  <Link to={row.original.sourceDataset?.url} target="_blank" className="text-emerald-500 hover:underline">
                    <div className="truncate">{row.original.sourceDataset?.name}</div>
                  </Link>
                ) : (
                  <div className="truncate">{row.original.sourceDataset?.name}</div>
                )
              }
            </TooltipTrigger>
            <TooltipContent>{row.original.sourceDataset?.name}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    ),
  },
  {
    accessorKey: "year",
    header: "Year published",
    cell: ({ row }) => <div>{row.getValue("year")}</div>,
  },
  {
    accessorKey: "region",
    header: () => (
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            <div className="flex truncate gap-2 items-center">
              Region
              <InfoIcon />
            </div>
          </TooltipTrigger>
          <TooltipContent>
            We try to use regional datasets that are close to where your activities occur
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ),
    cell: ({ row }) => <div>{row.getValue("region")}</div>,
  },
  {
    accessorKey: "co2e",
    header: () => <div>CO2<sub>e</sub> (kg)</div>,
    cell: ({ row }) => {
      let percentDifferenceToOriginalResult: string | number = getToDecimalPlaces(
        (row.getValue("co2e") as number / originalEmissionResult) * 100 - 100,
        0
      );
      const badgeClasses =
        percentDifferenceToOriginalResult > 0 ? "bg-red-100 text-red-800" : "bg-green-100 text-green-800";
      if (percentDifferenceToOriginalResult > 0) {
        percentDifferenceToOriginalResult = `+${percentDifferenceToOriginalResult}`;
      }
      return (
        <div className="flex flex-row items-center">
          <div className="font-mono">{row.getValue("co2e")}</div>
          <div className="w-2" />
          {!row.original.isOriginal ? (
            <Badge className={badgeClasses}>{percentDifferenceToOriginalResult}%</Badge>
          ) : null}
        </div>
      );
    },
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <div className="flex flex-row gap-2">
          <Dialog>
            <DialogTrigger>
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <ScanSearchIcon />
                  </TooltipTrigger>
                  <TooltipContent>Inspect calculation</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </DialogTrigger>
            <InspectCalculationDialog emissionResultId={row.original.id} />
          </Dialog>
        </div>
      );
    },
  },
];

export default columns;
