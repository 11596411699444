import { Panel } from "@Eikochain/__generated__/graphql";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, EditIcon, MoreHorizontalIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../dropdown-menu";

const columns: ColumnDef<Panel>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div>{row.getValue("name")}</div>,
  },
  {
    accessorKey: "dateCreated",
    header: "Created at",
    cell: ({ row }) => <div>{row.getValue("dateCreated")}</div>,
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              <MoreHorizontalIcon />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="gap-4">
            <DropdownMenuItem asChild>
              <Link to={"/app/supply-chains/detail/" + row.original.djangoId}>
                <EditIcon />
                Edit
              </Link>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];

export default columns;
