import { gql, useQuery } from 'urql';

const GetActivityTypeBreakdownQuery = gql`
  query {
    activityTypeBreakdown {
      name
      count
    }
  }
`;

const useGetActivityTypeBreakdown = () => {
  const result = useQuery({
    query: GetActivityTypeBreakdownQuery,
  })
  return result;
}

export default useGetActivityTypeBreakdown;
