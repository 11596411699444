import { EmissionFactor } from "@Eikochain/__generated__/graphql";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown } from "lucide-react";
import { Button } from "../button";

const columns: ColumnDef<EmissionFactor>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        Name
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => (
      <div className="flex flex-col">
        <div>{row.getValue("name")}</div>
      </div>
    ),
  },
  {
    accessorKey: "activityType",
    header: ({ column }) => (
      <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
        Activity type
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => (
      <div className="flex flex-col">
        <div>{row.getValue("activityType")}</div>
      </div>
    ),
  }
];

export default columns;
