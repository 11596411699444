import { gql } from 'urql';

export const DeleteActivityMutation = gql`
  mutation DeleteActivity($id: String!) {
    deleteActivity(id: $id) {
      ... on DeleteActivitySuccess {
        deleted
      }
      ... on DeleteActivityErrors {
        fieldErrors {
          fieldName
          errors
        }
      }
    }
  }
`;
