import { CSSProperties, memo } from "react";

export type MarkerPosition = "top-left" | "top" | "top-right" | "left" | "right" | "bottom-left" | "bottom" | "bottom-right";

export const allMarkerPositions: MarkerPosition[] = [
  "top-left",
  "top",
  "top-right",
  "left",
  "right",
  "bottom-left",
  "bottom",
  "bottom-right"
]

interface IResizableMarker {
  position: MarkerPosition;
  // onMouseDown allows us to get the currently selected marker position
  // so that it can be used in calculating how to change the element
  onMouseDown: (position: MarkerPosition) => void;
}

function getStylesForPosition(position: string): CSSProperties {
  let styles = {} as CSSProperties;
  if (position.startsWith("top")) {
    styles.top = "-8px";
  }
  if (position.startsWith("bottom")) {
    styles.bottom = "-8px";
  }
  if (position.endsWith("right")) {
    styles.right = "-8px";
  }
  if (position.endsWith("left")) {
    styles.left = "-8px";
  }
  if (position === "left" || position === "right") {
    styles.cursor = "ew-resize";
    styles.top = "50%";
    styles.transform = "translateY(-50%)";
  }
  if (position === "top" || position === "bottom") {
    styles.cursor = "ns-resize";
    styles.left = "50%";
    styles.transform = "translateX(-50%)";
  }
  if (position === "top-left" || position === "bottom-right") {
    styles.cursor = "nwse-resize";
  }
  if (position === "top-right" || position === "bottom-left") {
    styles.cursor = "nesw-resize";
  }
  return styles;
}

const ResizableMarker = memo(
  ({
    position,
    onMouseDown,
  }: IResizableMarker) => {
    return (
      <div
        className="absolute w-[8px] h-[8px] border-2 border-black bg-transparent"
        style={getStylesForPosition(position)}
        onMouseDown={() => onMouseDown(position)}
      />
    )
  }
)

export default ResizableMarker;
