import Spacer from "@Eikochain/components/atoms/Spacer";
import Modal from "@Eikochain/components/molecules/Modal";
import { Button } from "@Eikochain/components/ui/button";
import { QRCode } from "antd";
import { DownloadIcon } from "lucide-react";
import { ReactNode } from "react";

interface QRCodeModalProps {
  id: string;
  open?: boolean;
  onClose: () => void;
}

const QRCodeModal = ({
  id,
  open = false,
  onClose,
}: QRCodeModalProps) => {
  const value = `${window.location.origin}/MarketingPanels/ViewMarketingPanel?id=${id}`;
  const qrCodeWrapperId = "qrcode-wrapper"
  const downloadQrCode = () => {
    const canvas = document.getElementById(qrCodeWrapperId)?.querySelector<HTMLCanvasElement>('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = 'QRCode.png';
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  const Footer: ReactNode = (
    <div className="flex justify-end">
      <Button onClick={downloadQrCode} icon={<DownloadIcon />}>Download</Button>
      <Spacer width={2} />
      <Button variant="secondary">Close</Button>
    </div>
  )

  return (
    <Modal open={open} title="QR Code" onCancel={onClose} footer={Footer}>
      <div className="flex justify-center" id={qrCodeWrapperId}>
        <QRCode value={value} icon="/icon.png" size={240} />
      </div>
    </Modal>
  )
};

export default QRCodeModal;
