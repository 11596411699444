import AddSupplierButton from "@Eikochain/components/ui/add-supplier-button";
import { Button } from "@Eikochain/components/ui/button";
import SuppliersTable from "@Eikochain/components/ui/suppliers-table";
import useGetSuppliers from "@Eikochain/graphql/hooks/useGetSuppliers";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { CableIcon } from "lucide-react";
import { Link } from "react-router-dom";

export default function Suppliers() {
    const [result, refetch] = useGetSuppliers();
    const breadcrumbs = [{
        name: "Suppliers"
    }]

    const reexecuteQuery = () => {
        refetch({ requestPolicy: 'network-only' });
    }

    return (
        <BaseLayout breadcrumbs={breadcrumbs}>
            <div className="flex flex-row gap-4 justify-end w-full py-4">
                <Link to="/app/suppliers/requests/">
                    <Button variant="secondary" icon={<CableIcon />}>
                        View sent supplier information requests
                    </Button>
                </Link>
                <AddSupplierButton reexecuteQuery={reexecuteQuery} />
            </div>
            <div className="w-full px-4 py-16">
                <SuppliersTable
                    data={result.data?.suppliers ?? []}
                    isFetching={result?.fetching}
                    reexecuteQuery={() => reexecuteQuery()}
                />
            </div>
        </BaseLayout>
    )
}
