import { useState } from "react";

import { Button } from "@Eikochain/components/ui/button";
import IntegrationsTable from "@Eikochain/components/ui/integrations-table";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import AddNewIntegrationModal from "../../components/organisms/AddNewIntegrationModal/AddNewIntegrationModal";
import useGetOrganizationIntegrations from "../../graphql/hooks/useGetOrganizationIntegrations";

const IntegrationsList = () => {
  const breadcrumbs = [{
    name: "Integrations"
  }]
  const [modalOpen, setModalOpen] = useState(false);
  const [result, executeQuery] = useGetOrganizationIntegrations();

  const addNewIntegration = () => {
    setModalOpen(true);
  };

  const closeAddNewIntegrationModal = () => {
    setModalOpen(false);
  };

  function onFinish () {
    closeAddNewIntegrationModal()
    executeQuery({ requestPolicy: "network-only" })
  };

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="w-full">
        <IntegrationsTable data={result?.data?.organizationIntegrations ?? []} isFetching={result.fetching} />
        <Button onClick={addNewIntegration}>Add a new integration</Button>
      </div>
      <AddNewIntegrationModal
        open={modalOpen}
        onOpenChange={closeAddNewIntegrationModal}
        onFinish={onFinish}
      />
    </BaseLayout>
  );
};

export default IntegrationsList;
