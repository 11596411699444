import { LocationOption } from "@Eikochain/api/types";
import { Input } from "@Eikochain/components/ui/input";
import { getToDecimalPlaces } from "@Eikochain/utils";
import { useEffect, useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

type AddressAutocompleteInputProps = {
  onSelect: (option: LocationOption) => void;
  initialValue: string;
};

export default function AddressAutocompleteInput ({
  onSelect, initialValue
}: AddressAutocompleteInputProps) {
  const [address, setAddress] = useState<string>(initialValue);
  useEffect(() => {
    setAddress(initialValue)
  }, [initialValue])
  const handleChange = (value: string) => setAddress(value);
  const handleSelect = (address: string) => {
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]).then((latLng) => {
          const addressComponents = results[0].address_components;
          const country = addressComponents.find((component) => component.types.includes("country"))!;
          onSelect({
            lat: getToDecimalPlaces(latLng.lat, 6),
            lng: getToDecimalPlaces(latLng.lng, 6),
            region: address,
            countryCode: country.short_name as string,
          });
          setAddress(address);
        });
      })
      .catch((error) => console.error(error));
  };
  return (
    <PlacesAutocomplete value={address ?? ""} onChange={handleChange} onSelect={handleSelect} debounce={200}>
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div className="relative">
          <Input
            {...getInputProps({
              placeholder: "Search cities or countries",
            })}
            value={address}
          />
          {suggestions.length > 0 && (
            <div className="absolute p-2 rounded-md shadow-md max-h-[150px] overflow-y-scroll z-50 bg-[#f8fafb] w-full mt-1">
              {suggestions.map((suggestion, idx) => (
                <div
                  className="py-1 hover:bg-white rounded-md cursor-pointer"
                  {...getSuggestionItemProps(suggestion)}
                  key={idx}
                >
                  <span>{suggestion.description}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};
