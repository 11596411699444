interface ImageProps {
  src: string;
  width?: string | number;
  height?: string | number;
  alt?: string;
  loading?: "lazy"
  className?: string;
}

const Image = ({
  src,
  width,
  height,
  alt,
  loading,
  className
}: ImageProps): JSX.Element => {
  return (
    <img src={src} width={width} height={height} alt={alt} loading={loading} className={className} />
  )
};

export default Image;
