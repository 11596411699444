import { styled } from "goober";

function fromDimension (dimension: number) {
  return `${8 * dimension}px`
}

interface SpacerProps {
  width?: number;
  height?: number;
}

const Spacer = styled<SpacerProps>("div")`
  width: ${(props) => props.width ? fromDimension(props.width) : 0};
  height: ${(props) => props.height ? fromDimension(props.height) : 0};
`;

export default Spacer;
