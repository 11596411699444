import { EmissionFactor } from "@Eikochain/__generated__/graphql";
import { CoreRow } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import DataTable from "../data-table";
import columns from "./columns";

export interface EmissionFactorsTableProps {
  data: EmissionFactor[];
  isFetching: boolean;
  hidePaginationButtons?: boolean;
  showColumnToggler?: boolean;
  showRowCount?: boolean;
  exportAvailable?: boolean;
  additionalFilters?: JSX.Element[]
  searchBy?: string;
  onRowClick?: (values: CoreRow<EmissionFactor>) => void;
}

export default function EmissionFactorsTable({
  data,
  isFetching,
  showColumnToggler,
  showRowCount,
  hidePaginationButtons,
  exportAvailable = false,
  additionalFilters,
  searchBy = "name",
  onRowClick,
}: EmissionFactorsTableProps) {
  const navigate = useNavigate();
  return (
    <DataTable
      data={data}
      isFetching={isFetching}
      showColumnToggler={showColumnToggler}
      showRowCount={showRowCount}
      hidePaginationButtons={hidePaginationButtons}
      columns={columns}
      exportAvailable={exportAvailable}
      additionalFilters={additionalFilters}
      searchBy={searchBy}
      onRowClick={
        (values) => {
          onRowClick ? onRowClick(values) : navigate(`/app/data-explorer/emission-activity/${values.id}`)
        }
      }
    />
  )
}
