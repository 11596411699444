import { gql, useMutation } from 'urql';

export const CreateOrUpdateProductByIdMutation = gql`
  mutation CreateOrUpdateProduct($input: CreateOrUpdateProductInput!) {
    createOrUpdateProduct(input: $input) {
      ... on CreateOrUpdateProductSuccess {
        product {
          name
          url
          description
        }
      }
      ... on CreateOrUpdateProductErrors {
        fieldErrors {
          fieldName
          errors
        }
      }
    }
  }
`;
