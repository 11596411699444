import { clearAuthToken } from "@Eikochain/auth";
import { userAtom } from "@Eikochain/state/atoms/UserAtom";
import { useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";

export default function useLogout() {
    const setUser = useSetAtom(userAtom)
    const navigate = useNavigate();

    return async () => {
        clearAuthToken();
        setUser(null)
        navigate("/auth/");
    };
}
