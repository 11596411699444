import { EmissionFactorInputParameter } from "@Eikochain/__generated__/graphql";
import { Control } from "react-hook-form";
import DistanceField from "./fields/distance-field";
import EnergyField from "./fields/energy-field";
import SpendField from "./fields/spend-field/spend-field";
import WeightField from "./fields/weight-field";
import SwitcherFieldDropdown from "./switcher-field-dropdown";

interface SwitcherFieldProps {
  control: Control<any>;
  fields: EmissionFactorInputParameter[];
  selectedField: EmissionFactorInputParameter;
  setSelectedParameter: (parameter: EmissionFactorInputParameter) => void;
}

export default function SwitcherField({
  control,
  fields,
  selectedField,
  setSelectedParameter
}: SwitcherFieldProps) {
  return (
    <div className="flex flex-row items-end">
      <div className="w-full">
        {selectedField.name === "weight" && <WeightField control={control} />}
        {selectedField.name === "energy" && <EnergyField control={control} />}
        {selectedField.name === "distance" && <DistanceField control={control} />}
        {selectedField.name === "spend" && <SpendField control={control} />}
      </div>
      {
        fields?.length > 1 && (
          <SwitcherFieldDropdown
            fields={fields}
            selectedField={selectedField}
            onFieldChange={setSelectedParameter}
            className="flex-shrink-0"
          />
        )
      }
    </div>
  )
}
