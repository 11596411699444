import { gql, useQuery } from 'urql';

const GetEmissionFactorSourceByIdQuery = gql`
  query($id: String!) {
    emissionFactorSourceById(id: $id) {
      id
      factor {
        id
        name
      }
      dataSource {
        name
        year
        region
        url
        source {
          name
          url
        }
      }
      values {
        calculationUnitType {
          label
          value
          units
        }
        co2eTotal
        co2
        ch4
        n2o
      }
    }
  }
`;

const useGetEmissionFactorSourceById = (id: string) => {
  const result = useQuery({
    query: GetEmissionFactorSourceByIdQuery,
    variables: {
      id
    }
  })
  return result;
}

export default useGetEmissionFactorSourceById;
