import { Button } from "@Eikochain/components/ui/button";
import useGetAvailableIntegrationTypes from "@Eikochain/graphql/hooks/useGetAvailableIntegrationTypes";
import { Form, Radio } from "antd";
import { IntegrationType } from "../../../__generated__/graphql";

interface SelectIntegrationFormProps {
  onFinish: (selectedIntegration: IntegrationType) => void;
}

const SelectIntegrationForm = ({
  onFinish
}: SelectIntegrationFormProps) => {
  const [result] = useGetAvailableIntegrationTypes();

  return (
    <Form
      name="SelectIntegrationForm"
      onFinish={onFinish}
      layout="vertical"
      colon={false}
    >
      <Form.Item
        label="Select the provider that powers your ecommerce site"
        name="selectedIntegration"
        rules={[{ required: true }]}
      >
        <Radio.Group>
          {
            result?.data?.availableIntegrationTypes?.map((availableIntegration: IntegrationType) => (
              <Radio value={availableIntegration}>{availableIntegration?.name}</Radio>
            ))
          }
        </Radio.Group>
      </Form.Item>
      <Button type="submit">Next</Button>
    </Form>
  )
};

export default SelectIntegrationForm;
