import useGetEmissionFactorsAndSources from "@Eikochain/graphql/hooks/useGetEmissionFactorsAndSources";
import EmissionFactorsTable, { EmissionFactorsTableProps } from ".";

interface EmissionFactorsTableWithDataProps extends Omit<EmissionFactorsTableProps, "data" | "isFetching"> {}

export default function EmissionFactorsTableWithData({
  showColumnToggler = true,
  showRowCount,
  onRowClick
}: EmissionFactorsTableWithDataProps) {
  const [result] = useGetEmissionFactorsAndSources();
  return (
    <EmissionFactorsTable
      data={result?.data?.emissionFactorsAndSources ?? []}
      isFetching={result.fetching}
      showColumnToggler={showColumnToggler}
      showRowCount={showRowCount}
      onRowClick={onRowClick}
    />
  )
}
