import { CsvUpload } from "@Eikochain/__generated__/graphql";
import { Button } from "@Eikochain/components/ui/button";
import { UpdateCSVMutation } from "@Eikochain/graphql/hooks/mutations/useUpdateCSV";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "sonner";
import { useMutation } from "urql";
import { DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../form";
import { Input } from "../../input";
import { editUploadSchema } from "./schema";

type Inputs = {
  name: string;
};

type EditUploadDialogContentProps = {
  file: CsvUpload;
  onFinish: () => void;
};

export default function EditUploadDialogContent ({
  file,
  onFinish
}: EditUploadDialogContentProps) {
  const [mutationResult, executeMutation] = useMutation(UpdateCSVMutation);
  const form = useForm<Inputs>({
    resolver: yupResolver(editUploadSchema),
    defaultValues: {
      name: file.name
    }
  });
  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    executeMutation({ input: { id: file.djangoId, name: data.name } }).then((result) => {
      const { data, error } = result;
      if (error) {
        toast.error("Something went wrong while updating name");
      } else {
        if (data) {
          toast.success("Name updated");
          onFinish();
        } else {
          toast.error("Something went wrong while updating name");
        }
      }
    });
  };

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Editing upload</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <DialogFooter>
            <Button type="submit" variant="default" loading={mutationResult.fetching}>
              Save
            </Button>
            <DialogClose asChild>
              <Button variant="outline" disabled={mutationResult.fetching}>
                Cancel
              </Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  );
};
