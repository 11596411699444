import { CsvUpload } from "@Eikochain/__generated__/graphql"
import columns from "./columns"
import DataTable from "../data-table"

interface UploadedFilesTableProps {
  data: CsvUpload[];
  isFetching: boolean;
}

export default function UploadedFilesTable({ data, isFetching }: UploadedFilesTableProps) {
  return (
    <DataTable
      data={data}
      isFetching={isFetching}
      columns={columns}
      showRowCount={false}
    />
  )
}
