import { Product } from "./__generated__/graphql";

export interface ProductType extends Product {
  id?: string;
}

export interface CanvasInstance {}

export interface ActionItem {
  action: "create";
  canvasState: CanvasInstance;
}

export type CanvasElementTypes = "text" | "imageSingle" | "imageCarousel" | "video";

export type ActivityTypeOption = "process" | "transport" | "packaging" | "electricity" | "fuel" | "waste";

export type ScopeOption = "scope_1" | "scope_2" | "scope_3";

export const scopeOptions = [
  {
    label: "Scope 1",
    value: "scope_1"
  },
  {
    label: "Scope 2",
    value: "scope_2"
  },
  {
    label: "Scope 3",
    value: "scope_3"
  },
]

export type TOrganization = {
  id: string,
  name: string,
}

export type TUser = {
  email: string,
  firstNames: string,
  lastName: string,
  organization: TOrganization,
  isStaff: boolean,
}
