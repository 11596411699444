import { Button } from "@Eikochain/components/ui/button";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@Eikochain/components/ui/form";
import { Input } from "@Eikochain/components/ui/input";
import { Textarea } from "@Eikochain/components/ui/textarea";
import { CreateOrUpdateProductByIdMutation } from "@Eikochain/graphql/hooks/useCreateOrUpdateProductById";
import { ProductType } from "@Eikochain/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { SaveIcon, Trash2Icon } from "lucide-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useMutation } from "urql";
import * as z from "zod";
import { formSchema } from "./schema";

interface CreateOrUpdateProductFormProps {
  instance?: ProductType;
}

const CreateOrUpdateProductForm = ({ instance }: CreateOrUpdateProductFormProps) => {
  const navigate = useNavigate();

  const openNotification = (type: string) => {
    toast.dismiss();
    if (type === "success") {
      toast.success("Saved product");
    } else if (type === "error") {
      toast.error("Please correct the errors to save");
    }
  };

  const [result, executeMutation] = useMutation(CreateOrUpdateProductByIdMutation);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: instance?.name ?? "",
      url: instance?.url ?? "",
      description: instance?.description ?? "",
      category: instance?.category ?? "",
    },
  });

  function onSubmit (values: z.infer<typeof formSchema>) {
    const valuesWithId = { id: instance?.id, ...values };
    executeMutation({ input: valuesWithId }).then((result) => {
      const { error } = result;
      if (error) {
        openNotification("error");
      } else {
        openNotification("success");
        navigate("/app/products/");
      }
    });
  };

  function showProgressWarning() {
    // Need to check if form progress so far.
    // Need to check form object somehow
    // If no progress, cancel straight away
    // Otherwise, show alert
    console.log(form.formState.isDirty)
    if (form.formState.isDirty) {
      alert("Are you sure you want to cancel your progress?")
    }
    navigate("/app/products/");
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex flex-col items-start gap-12">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Product name</FormLabel>
                <FormDescription>
                  Give a unique name
                </FormDescription>
                <FormControl>
                  <Input {...field} placeholder="E.g., Blue Toothbrush" />
                </FormControl>
                <FormMessage />
              </FormItem>
              )}
          />
          <FormField
            control={form.control}
            name="url"
            render={({ field }) => (
              <FormItem>
                <FormLabel>URL</FormLabel>
                <FormDescription>
                  Link to your product (this could be an internal dashboard or your customer-facing site)
                </FormDescription>
                <FormControl>
                  <Input {...field} placeholder="E.g., https://example.com/blue-toothbrush" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormDescription>
                  Describe your product, including details of materials, weight and variants
                </FormDescription>
                <FormControl>
                  <Textarea {...field} placeholder="e.g. Toothbrush is blue, made from 50g of virgin plastic. Also comes in pink and green variations." />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Category</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Toiletries" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex flex-row gap-4">
            <Button type="submit" icon={<SaveIcon />}>Save</Button>
            <Button
              variant="destructive"
              type="button"
              disabled={!form.formState.isDirty}
              onClick={showProgressWarning}
              icon={<Trash2Icon />}
            >
              Cancel changes
            </Button>
          </div>
        </div>
      </form>
    </Form>
  );
};

export default CreateOrUpdateProductForm;
