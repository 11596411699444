import { PanelLeftOpenIcon } from "lucide-react";
import { useState } from "react";
import PanelLogo from "../PanelLogo";
import NavigationMenu from "./NavigationMenu";

const NavigationMenuMobile = ({
  isEditing = false
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <div className="h-full absolute z-10 NavigationMenuMobileContainer">
      <div className="flex justify-between items-center absolute w-full z-10">
        <div className="flex items-center justify-center absolute px-2 py-2 cursor-pointer" onClick={() => setIsCollapsed(!isCollapsed)}>
          <PanelLeftOpenIcon size={28} />
        </div>
        <PanelLogo maxWidth={250} />
        <div />
      </div>
      {
        !isCollapsed
        ? (
          <div className="absolute h-full">
            <NavigationMenu isEditing={isEditing} withLogo={false} />
          </div>
        ) : null
      }
    </div>
  )
}

export default NavigationMenuMobile;
