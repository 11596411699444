import { gql, useQuery } from 'urql';

const GetReportsQuery = gql`
  query {
    reports {
      djangoId
      createdBy {
        email
      }
      reportType
      location
    }
  }
`;

const useGetReports = () => {
  const result = useQuery({
    query: GetReportsQuery,
  })
  return result;
}

export default useGetReports;
