import { EmissionCalculationRangeResult } from "@Eikochain/__generated__/graphql";
import DataTable from "../data-table";
import columns from "./columns";

interface EmissionsComparisonTableByActivityProps {
  data: EmissionCalculationRangeResult[];
  isFetching?: boolean;
}

export default function EmissionsComparisonTableByActivity({
  data,
  isFetching = false,
}: EmissionsComparisonTableByActivityProps) {
  const originalEmissionResult = data?.find(
    (eb: EmissionCalculationRangeResult) => eb.isOriginal === true
  )?.co2e!;
  const subjectType = data[0]?.subjectType!;
  const allSameSubject = data.every(eb => eb.subjectType === subjectType);
  if (subjectType && !allSameSubject) throw new Error("All subjects should be the same");
  return (
    <DataTable
      data={data ?? []}
      isFetching={isFetching}
      columns={columns(originalEmissionResult, subjectType)}
      showRowCount={false}
      showColumnToggler={false}
      hidePaginationButtons={true}
    />
  )
}
