import { Route } from "@Eikochain/__generated__/graphql";
import { getNumericSeparator } from "@Eikochain/utils";
import { CircleFlag } from "react-circle-flags";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";

interface RouteFlagsProps {
  route: Route;
}

export default function RouteFlags({
  route,
}: RouteFlagsProps) {
  const distance = getNumericSeparator(route.distance!)
  return (
    <div className="flex">
      <TooltipProvider>
        <Tooltip delayDuration={0}>
          <TooltipTrigger asChild>
            {
              route?.origin && route?.destination && (
                <div className="flex flex-row items-center gap-2">
                  <CircleFlag
                    countryCode={route.origin.countryCode!.toLowerCase()}
                    className="w-8 h-8"
                  />
                  to
                  <CircleFlag
                    countryCode={route.destination.countryCode!.toLowerCase()}
                    className="w-8 h-8"
                  />
                </div>
              )
            }
          </TooltipTrigger>
          <TooltipContent>
            <span>
              {route?.origin && route?.destination && (
                <>{route.origin?.region} to {route?.destination?.region} ({distance}km)</>
              )}
            </span>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}
