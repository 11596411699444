import { Activity, BreakdownItem } from '@Eikochain/__generated__/graphql';
import { Page, Text, View, Document, StyleSheet, Font, Svg, Path } from '@react-pdf/renderer';
import { useMemo } from 'react';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#efe',
        fontFamily: 'Telex',
        color: "#000",
    },
    section: {
        margin: 10,
        padding: 10,
    },
    group: {
        flexDirection: 'row',
    },
    column: {
        flexDirection: "column",
        padding: 10
    },
    flexGrow: {
        flexGrow: 1,
    },
    h1: {
        fontFamily: "Anton",
        fontWeight: "bold",
        fontSize: 24,
    },
    h2: {
        fontFamily: "Anton",
        fontSize: 18,
    },
    p: {
        fontSize: 14,
    },
});

Font.register({
    family: 'Telex',
    src: "https://fonts.gstatic.com/s/telex/v5/eXTLMlW5_yOhUYbVCO_akg.ttf"
})

Font.register({
    family: "Anton",
    src: "https://fonts.gstatic.com/s/anton/v8/5t8vP9Hx2udzw372glmOxA.ttf"
})

type ScopePDFProps = {
    emissionsBreakdown: BreakdownItem[];
    activities: Activity[];
}

function classifyActivityCO2ByScope(activities: Activity[]) {
    const byYear: Map<string, number> = new Map()

    for (let i = 0; i < activities.length; i++) {
        const year = new Date(activities[i].createdAt).getFullYear().toString()
        const co2 = activities[i].mostRecentEmissionCalculation?.co2e || 0

        if (byYear.has(year)) {
            byYear.set(year, byYear.get(year)! + co2)
        } else {
            byYear.set(year, co2)
        }
    }

    return byYear
}

function classifyActivitybyTransportType(activities: Activity[]) {
    const byTransport: Map<string, { km: number, co2: number }> = new Map();

    for (let i = 0; i < activities.length; i++) {
        const co2 = activities[i].mostRecentEmissionCalculation?.co2e ?? 0
        const km = activities[i].route?.distance ?? 0
        const transportType = activities[i].transportMethod ?? ""

        if (transportType === "") {
            continue
        }

        if (byTransport.has(transportType)) {
            const oldValue = byTransport.get(transportType)!
            byTransport.set(transportType, { km: oldValue.km + km, co2: oldValue.co2 + co2 })
        } else {
            byTransport.set(transportType, { km, co2 })
        }
    }

    return byTransport
}

// Create Document Component
export const ScopePDF = ({ activities, emissionsBreakdown }: ScopePDFProps) => {
    const byYear = useMemo(() => classifyActivityCO2ByScope(activities), [activities])
    const byTransport = useMemo(() => classifyActivitybyTransportType(activities), [activities])

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={[styles.group, { alignItems: "center", gap: "2rem" }]}>
                        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <Path d="M11 20A7 7 0 0 1 9.8 6.1C15.5 5 17 4.48 19 2c1 2 2 4.18 2 8 0 5.5-4.78 10-10 10Z" />
                            <Path d="M2 21c0-3 1.85-5.36 5.08-6C9.5 14.52 12 13 13 12" />
                        </Svg>
                        <Text style={styles.h1}>EikoIQ Emissions Report</Text>
                    </View>
                </View>
                <View style={styles.column}>
                    <View style={styles.column}>
                        <View style={[styles.column, { gap: 5 }]}>
                            <Text style={[styles.p, styles.h2]}>Total emissions by scope</Text>
                            {emissionsBreakdown.map(emission => <Text style={styles.p}>{emission.name}: {emission.count} kgCO2e</Text>)}
                        </View>

                        <View style={[styles.column, { gap: 5 }]}>
                            <Text style={[styles.p, styles.h2]}>Total emissions by year</Text>
                            {Array.from(byYear.entries()).map(([year, co2]) => <Text style={styles.p}>{year}: {co2} kgCO2e</Text>)}
                        </View>

                        <View style={[styles.column, { gap: 5 }]}>
                            <Text style={[styles.p, styles.h2]}>Total emissions and distance travelled</Text>
                            {Array.from(byTransport.entries()).map(([method, { co2, km }]) => <Text style={styles.p}>{method}: {co2} kgCO2e | {km} Km</Text>)}
                        </View>
                    </View>
                </View>
            </Page>
        </Document >
    )
};

