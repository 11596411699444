import { getAuthToken, setAuthToken } from "@Eikochain/auth";
import { userAtom } from "@Eikochain/state/atoms/UserAtom";
import { TUser } from "@Eikochain/types";
import { useSetAtom } from "jotai";
import { useNavigate } from "react-router-dom";

export default function useLogin() {
  const setUser = useSetAtom(userAtom)
  const navigate = useNavigate();

  return async (authToken: string, user: TUser, nextUrl: string) => {
    if (!authToken) return;
    setAuthToken(authToken);
    setUser(user)
    if (nextUrl) {
      navigate(nextUrl);
    }
  };
}
