import { gql, useQuery } from 'urql';

const GetProductsQuery = gql`
  query {
    products {
      djangoId
      name
      description
      url
    }
  }
`;

const useGetProducts = () => {
  const result = useQuery({
    query: GetProductsQuery,
  })
  return result;
}

export default useGetProducts;
