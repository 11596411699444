import { OrganizationIntegration } from "@Eikochain/__generated__/graphql";
import DataTable from "../data-table";
import columns from "./columns";

interface IntegrationsTableProps {
  data: OrganizationIntegration[];
  isFetching: boolean;
}

export default function IntegrationsTable({ data, isFetching }: IntegrationsTableProps) {
  return (
    <DataTable
      data={data}
      isFetching={isFetching}
      columns={columns}
      hidePaginationButtons
      showColumnToggler={false}
      showRowCount={false}
    />
  )
}
