import Spacer from "@Eikochain/components/atoms/Spacer";
import { IMarketingPanel } from "@Eikochain/components/organisms/MarketingEditor/types";
import useUpdateSelectedStepId from "@Eikochain/hooks/MarketingEditor/useUpdateSelectedStepId";
import { currentPanelAtom } from "@Eikochain/state/atoms/CanvasContent";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import MarketingEditorControls from "./Controls/MarketingEditorControls";
import MarketingPanel from "./Panel";

interface MarketingEditorProps {
  onChange: (panelState: IMarketingPanel) => void;
}

const MarketingEditor = ({ onChange }: MarketingEditorProps) => {
  // Local state
  const panelState = useAtomValue(currentPanelAtom);
  useUpdateSelectedStepId();

  useEffect(() => {
    if (panelState) {
      onChange(panelState);
    }
  }, [panelState]);

  return (
    <>
      <MarketingEditorControls />
      <Spacer height={4} />
      <MarketingPanel isEditing />
    </>
  );
};

export default MarketingEditor;
