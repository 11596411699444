import { SupplierInformationResponse } from "@Eikochain/__generated__/graphql";
import { redirect } from "react-router-dom";
import DataTable from "../data-table";
import { columns, smallColumns } from "./columns";

export interface SupplierInformationResponsesTableProps {
    data: SupplierInformationResponse[];
    isFetching: boolean;
    showRowCount?: boolean;
    hidePaginationButtons?: boolean;
    small?: boolean;
}

export default function SupplierInformationResponsesTable({
    data,
    isFetching,
    showRowCount = true,
    small = false
}: SupplierInformationResponsesTableProps) {
    return (
        <DataTable
            data={data}
            isFetching={isFetching}
            columns={small ? smallColumns : columns}
            showRowCount={showRowCount}
            showColumnToggler={false}
            hidePaginationButtons={false}
            searchBy={" "}
            onRowClick={
                (values) => redirect(
                    `/app/suppliers/requests/view/${values.supplierInformationRequest.id}/`
                )
            }
        />
    )
}
