import Image from "../atoms/Image";

type LogoProps = {
  className?: string;
}

export default function Logo({
  className
}: LogoProps) {
  const src = "/eikoiq-logo.webp";
  return (
    <Image src={src} width='100%' height="100%" alt="EikoIQ logo" className={className} />
  )
}
