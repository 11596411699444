import useGetActivitiesByProductId from "@Eikochain/graphql/hooks/useGetActivitiesByProductId";
import { carbonActivitiesAtom } from "@Eikochain/state/atoms/CarbonActivitiesAtom";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import ActivityStepAlert from "../activity-step/activity-step-alert";
import CreateOrUpdateActivityModal from "../create-update-activity-dialog";
import { Dialog } from "../dialog";
import ActivitySteps from "./activity-steps";

interface ActivityStepsForProductProps {
  productId: string;
}

export default function ActivityStepsForProduct({ productId }: ActivityStepsForProductProps) {
  const [carbonActivities, setCarbonActivities] = useAtom(carbonActivitiesAtom);
  const [result, reexecuteQuery] = useGetActivitiesByProductId(productId);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setCarbonActivities(result?.data?.activitiesByProductId ?? []);
  }, [result?.data]);

  const activitiesCountForType = (type: string) => {
    return carbonActivities.filter((activity) => activity.activityType === type).length;
  };
  const transportActivitiesCount = activitiesCountForType("Transport");
  const packagingActivitiesCount = activitiesCountForType("Packaging");
  const processActivitiesCount = activitiesCountForType("Process");
  const processElectricityActivitiesCount = activitiesCountForType("Electricity");

  const onNewActivityAdded = () => {
    setModalOpen(false);
    reexecuteQuery({ requestPolicy: "network-only" });
  };

  return (
    <>
      <div className="flex flex-col justify-stretch w-full">
        {!transportActivitiesCount && (
          <div className="flex flex-col items-center w-full mb-2">
            <ActivityStepAlert
              productId={productId}
              activityType="transport"
              alertType="required"
              onActivityCreated={onNewActivityAdded}
            />
          </div>
        )}
        {!packagingActivitiesCount && (
          <div className="flex flex-col items-center w-full mb-2">
            <ActivityStepAlert
              productId={productId}
              activityType="packaging"
              alertType="required"
              onActivityCreated={onNewActivityAdded}
            />
          </div>
        )}
        {!processActivitiesCount && (
          <div className="flex flex-col items-center w-full mb-2">
            <ActivityStepAlert
              productId={productId}
              activityTypeLabel="material"
              activityType="process"
              alertType="required"
              onActivityCreated={onNewActivityAdded}
            />
          </div>
        )}
        <ActivitySteps
          data={carbonActivities}
          executeQuery={reexecuteQuery}
        />
        {!processElectricityActivitiesCount && (
          <div className="flex flex-col items-center w-full mb-2">
            <ActivityStepAlert
              productId={productId}
              activityType="electricity"
              alertType="suggested"
              onActivityCreated={onNewActivityAdded}
            />
          </div>
        )}
      </div>
      <Dialog open={modalOpen} onOpenChange={setModalOpen}>
        <CreateOrUpdateActivityModal
          productId={productId}
          onFinish={onNewActivityAdded}
        />
      </Dialog>
    </>
  )
}
