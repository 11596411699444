import ActivityTypeIcon from "../activity-type-icon";

interface FactorNameTileProps {
  name: string;
  activityType: string;
  transportMethod?: string;
}

export default function FactorNameTile({
  name,
  activityType,
  transportMethod
}: FactorNameTileProps) {
  return (
    <div className="flex flex-row items-center gap-6 p-6 rounded-sm border-2 border-gray-200">
      <ActivityTypeIcon
        activityType={activityType}
        transportMethod={transportMethod ?? null}
      />
      <div className="flex flex-col">
        <h3>{name}</h3>
        <h5>{activityType}</h5>
      </div>
    </div>
  )
}
