import { OrganizationIntegration } from "@Eikochain/__generated__/graphql";
import ImportProductsModal from "@Eikochain/components/organisms/ImportProductsModal";
import { ColumnDef } from "@tanstack/react-table";
import { PlusIcon } from "lucide-react";
import { Dialog, DialogTrigger } from "../dialog";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../tooltip";

const columns: ColumnDef<OrganizationIntegration>[] = [
  {
    accessorKey: "type",
    header: "Integration type",
    cell: ({ row }) => <div>{row.original.integrationType.name}</div>,
  },
  {
    accessorKey: "url",
    header: "URL",
    cell: ({ row }) => <div>{row.getValue("url")}</div>,
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => {
      return (
        <div className="flex flex-row gap-2">
          <Dialog>
            <DialogTrigger>
              <TooltipProvider>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <PlusIcon />
                  </TooltipTrigger>
                  <TooltipContent>Import products from integration</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </DialogTrigger>
            <ImportProductsModal organizationIntegrationId={row.original.djangoId}/>
          </Dialog>
        </div>
      );
    },
  },
];

export default columns;
