// @ts-ignore
import { useEffect, useState } from 'react';
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker'
import { GradientPickerProps } from './types';

const GradientPicker = ({
  defaultColor,
  onChange,
}: GradientPickerProps) => {
  const [color, setColor] = useState<string>(defaultColor);
  const { setSolid, setGradient } = useColorPicker(color, setColor);
  function changeColor (newColor: string) {
    setColor(newColor)
    if (onChange) onChange(newColor)
  };
  useEffect(() => {
    // On render, set picker to gradient or solid depending on color
    if (color?.includes("gradient")) {
      setGradient(color);
    } else {
      setSolid(color);
    }
  }, [])

  return (
    <ColorPicker
      value={color}
      onChange={changeColor}
      height={100}
      hideOpacity
      hidePresets
      hideEyeDrop
      hideAdvancedSliders
      hideColorGuide
    />
  )
};

export default GradientPicker;
