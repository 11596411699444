import { gql } from 'urql';

export const CreateSupplierInformationRequestTemplateMutation = gql`
  mutation CreateSupplierInformationRequestTemplate($input: CreateSupplierInformationRequestTemplateInput!) {
    createSupplierInformationRequestTemplate(input: $input) {
        success
        reason
      }
    }
`
