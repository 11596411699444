import { Control } from "react-hook-form";
import { FormField } from "../../form";
import WeightFormItem from "../../weight-form-item";

interface WeightFieldProps {
  control: Control<any>
}

export default function WeightField({
  control
}: WeightFieldProps) {
  return (
    <FormField
      control={control}
      name="weight"
      render={({ field }) => (
        <WeightFormItem field={field} />
      )}
    />
  )
}
