
import { Button } from "@Eikochain/components/ui/button";
import { QrCodeIcon } from "lucide-react";
import { useState } from "react";
import QRCodeModal from "../Modals/QRCodeModal";

const MarketingEditorControlGenerateQRButton = ({ disabled = false }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)} disabled={disabled} icon={<QrCodeIcon />}>
        Generate QR Code
      </Button>
      <QRCodeModal open={open} onClose={() => setOpen(false)} id="e2eyeyygwq" />
    </>
  );
};

export default MarketingEditorControlGenerateQRButton;
