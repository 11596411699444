import { gql } from 'urql';

export const CalculateEmissionResultsForScopedActivitiesMutation = gql`
  mutation CalculateEmissionResultsForScopedActivities {
    calculateEmissionResultsForScopedActivities {
      ... on CalculateEmissionResultsForScopedActivitiesSuccess {
        success
      }
      ... on CalculateEmissionResultsForScopedActivitiesErrors {
        errors
      }
    }
  }
`;
