import { PlusIcon } from "lucide-react";
import { useState } from "react";
import H3 from "../atoms/H3";
import Paragraph from "../atoms/P";
import Spacer from "../atoms/Spacer";
import ActivityStepsForProduct from "./activity-steps/activity-steps-for-product";
import { Button } from "./button";
import CreateOrUpdateActivityModal from "./create-update-activity-dialog";
import { Dialog, DialogTrigger } from "./dialog";

interface ProductActivityStepsSectionProps {
  productId: string;
}

export default function ProductActivityStepsSection ({ productId }: ProductActivityStepsSectionProps) {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row justify-between gap-4 w-full">
        <div className="flex flex-col w-full">
          <H3>Activities</H3>
          <Paragraph className="hidden md:flex">
            Here you can enter a product's supply chain activities
          </Paragraph>
        </div>
        <Dialog open={openModal} onOpenChange={setOpenModal}>
          <DialogTrigger asChild>
            <Button icon={<PlusIcon />}>
              Add activity
            </Button>
          </DialogTrigger>
          <CreateOrUpdateActivityModal productId={productId} onFinish={() => setOpenModal(false)} />
        </Dialog>
      </div>
      <Spacer height={2} />
      <ActivityStepsForProduct productId={productId} />
    </div>
  );
};
