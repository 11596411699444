import { BriefcaseIcon, FuelIcon, GaugeIcon, PackageIcon, PlaneIcon, RadiationIcon, ShapesIcon, ShipIcon, TrainFrontIcon, TruckIcon, ZapIcon } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";

const mapActivityTypeToIcon = (activityType: string, transportMethod: string | null) => {
  switch (activityType) {
    case "Transport":
      switch (transportMethod) {
        case "Road Freight":
          return <TruckIcon />
        case "Air Freight":
          return <PlaneIcon />
        case "Rail Freight":
          return <TrainFrontIcon />
        case "Sea Freight":
          return <ShipIcon />
      }
    case "Fuel":
      return <FuelIcon />
    case "Process":
      return <GaugeIcon />
    case "Electricity":
      return <ZapIcon />
    case "Waste":
      return <RadiationIcon />
    case "Packaging":
      return <PackageIcon />
    case "Services":
      return <BriefcaseIcon />
    case "Goods":
      return <ShapesIcon />
    default:
      return <GaugeIcon />
  }
};

interface ActivityTypeIconProps {
  activityType: string;
  transportMethod: string | null;
}

export default function ActivityTypeIcon({
  activityType,
  transportMethod,
}: ActivityTypeIconProps) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <div>{mapActivityTypeToIcon(activityType, transportMethod)}</div>
        </TooltipTrigger>
        <TooltipContent>
          {!transportMethod ? activityType : `${activityType} via ${transportMethod}`}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
