import { gql } from 'urql';

export const DeleteProductMutation = gql`
  mutation DeleteProduct($id: String!) {
    deleteProduct(id: $id) {
      ... on DeleteProductSuccess {
        deleted
      }
      ... on DeleteProductErrors {
        fieldErrors {
          fieldName
          errors
        }
      }
    }
  }
`;
