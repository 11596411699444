import { Badge } from "@Eikochain/components/ui/badge";
import { Table, TableBody, TableCell, TableRow } from "@Eikochain/components/ui/table";
import useGetEmissionFactorSourceById from "@Eikochain/graphql/hooks/useGetEmissionFactorSourceById";
import BaseLayout from "@Eikochain/layouts/BaseLayout";
import { ExternalLinkIcon } from "lucide-react";
import { Link, useParams } from "react-router-dom";

export default function DataExplorerEmissionSource () {
  const { id } = useParams<{ id: string }>();
  const [result] = useGetEmissionFactorSourceById(id as string);

  const breadcrumbs = [
    {
      name: "Data Explorer",
      url: "/app/data-explorer"
    },
    {
      name: result?.data?.emissionFactorSourceById?.factor?.name,
      url: `/app/data-explorer/emission-activity/${result?.data?.emissionFactorSourceById?.factor?.id}`
    },
    {
      name: `${result?.data?.emissionFactorSourceById?.factor?.name} (Source)`
    }
  ]

  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="p-4">
        <div className="flex flex-col gap-6 mt-12">
          <div><Badge>Emission Source</Badge></div>
          <div className="flex flex-col gap-2">
            <h1 className="text-3xl">
              {result?.data?.emissionFactorSourceById?.factor?.name}
            </h1>
            <div className="flex flex-col gap-2">
              <h1 className="flex text-2xl whitespace-nowrap">
                <span className="text-slate-600">Sourced from&nbsp;</span>
                <span className="text-blue-600 cursor-pointer">
                  <Link to={result?.data?.emissionFactorSourceById?.dataSource?.source.url} className="flex whitespace-nowrap gap-2">
                    {result?.data?.emissionFactorSourceById?.dataSource?.source.name}
                    <ExternalLinkIcon />
                  </Link>
                </span>
              </h1>
              <h1 className="flex text-2xl whitespace-nowrap">
                <span className="text-slate-600">Using dataset&nbsp;</span>
                <span className="text-blue-600 cursor-pointer">
                  <Link to={result?.data?.emissionFactorSourceById?.dataSource?.url} className="flex whitespace-nowrap gap-2">
                    {result?.data?.emissionFactorSourceById?.dataSource?.name}
                    <ExternalLinkIcon />
                  </Link>
                </span>
              </h1>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <h1 className="text-5xl font-mono text-indigo-600">
              {result?.data?.emissionFactorSourceById?.values?.co2eTotal}
            </h1>
            <h3 className="text-2xl">
              kg CO<sub>2</sub>e/{result?.data?.emissionFactorSourceById?.values?.calculationUnitType?.units}
            </h3>
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-32">
          <h2>More details</h2>
          <Table className="max-w-[600px]">
            <TableBody>
              <TableRow className="hover:bg-inherit border-b-0">
                <TableCell className="p-0 py-4">Region</TableCell>
                <TableCell className="p-0 py-4">{result?.data?.emissionFactorSourceById?.dataSource.region}</TableCell>
              </TableRow>
              <TableRow className="hover:bg-inherit border-b-0">
                <TableCell className="p-0 py-4">Year</TableCell>
                <TableCell className="p-0 py-4">{result?.data?.emissionFactorSourceById?.dataSource.year}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </BaseLayout>
  )
};
