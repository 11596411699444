
import UpdatePasswordForm from "@Eikochain/components/ui/update-password-form";
import BaseLayout from "@Eikochain/layouts/BaseLayout";

export default function ChangePassword () {
  const breadcrumbs = [{
    name: "Settings"
  }]
  return (
    <BaseLayout breadcrumbs={breadcrumbs}>
      <div className="container flex flex-col self-center max-w-lg md:max-w-xl pt-20 md:pt-32">
        <p className="font-bold text-2xl flex flex-row justify-center pb-10">Change password</p>
        <UpdatePasswordForm />
      </div>
    </BaseLayout>
  );
};
