import { DatabaseIcon, FastForwardIcon, GoalIcon } from "lucide-react";
import { BentoCard, BentoGrid } from "../bento-grid";
import CalculateEmissionsButton from "../calculate-emissions-button/calculate-emissions-button";
import { Command, CommandInput, CommandItem, CommandList } from "../command";
import DotPattern from "../dot-pattern";
import { LandingPageAnimatedBeam } from "./landing-page-animated-beam";

const features = [
  {
    Icon: FastForwardIcon,
    name: "First calculations in minutes",
    description: "Efficiency without compromising on accuracy.",
    className: "col-span-3 lg:col-span-1",
    background: (
      <div className="h-full w-full">
        <DotPattern
          width={20}
          height={20}
          cx={1}
          cy={1}
          cr={1}
        />
        <div className="absolute top-16 w-full flex justify-center origin-top">
          <CalculateEmissionsButton
            onCalculate={() => null}
            loading={false}
            disabled={false}
            canBeRecalculated={true}
            className="pointer-events-none from-slate-400 to-slate-600 group-hover:from-emerald-600 group-hover:to-emerald-800 transition-all duration-300 ease-out"
          />
        </div>
      </div>
    ),
  },
  {
    Icon: DatabaseIcon,
    name: "1000's of emission factors",
    description: "No need to go data-hunting, it's all here.",
    className: "col-span-3 lg:col-span-2",
    background: (
      <Command className="absolute right-10 top-10 w-[70%] origin-top translate-x-0 border transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_20%,#000_100%)] group-hover:-translate-x-10 pointer-events-none">
        <CommandInput placeholder="Search emission factors..." />
        <CommandList>
          {/* Strange landing page bug occurs without the forceMount prop applied to all CommandItem */}
          {/* As of writing, the BlurIn heading in the hero will jump or have some layout shift without this prop */}
          <CommandItem forceMount>Cotton production</CommandItem>
          <CommandItem forceMount>Cotton production</CommandItem>
          <CommandItem forceMount>Electric Motors production</CommandItem>
          <CommandItem forceMount>Air Freight</CommandItem>
          <CommandItem forceMount>Polyester packaging</CommandItem>
          <CommandItem forceMount>And many, many more</CommandItem>
        </CommandList>
      </Command>
    ),
  },
  {
    Icon: GoalIcon,
    name: "Easy as 1, 2, 3",
    description: "All scope emissions covered, giving you a birds eye view.",
    className: "col-span-3",
    background: (
      <div className="absolute right-10 top-10 w-[60%] origin-top translate-x-0 transition-all duration-300 ease-out group-hover:-translate-x-20 pointer-events-none">
        <LandingPageAnimatedBeam />
      </div>
    ),
  },
];

export function LandingPageStatsBento() {
  return (
    <div className="p-8 w-full max-w-[1200px] mx-auto">
      <BentoGrid>
        {features.map((feature, idx) => (
          <BentoCard key={idx} {...feature} />
        ))}
      </BentoGrid>
    </div>
  );
}
