import { DialogContent, DialogHeader, DialogTitle } from "../dialog";
import ExportDataForm, { ExportDataFormProps } from "./export-data-form";

export interface ExportDataDialogContentProps extends ExportDataFormProps {}

export default function ExportDataDialogContent({
  csvUploadId,
  activities
}: ExportDataDialogContentProps) {
  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Export emissions data</DialogTitle>
      </DialogHeader>
      <ExportDataForm
        csvUploadId={csvUploadId}
        activities={activities}
      />
    </DialogContent>
  )
}
