import { CsvUpload } from "@Eikochain/__generated__/graphql";
import StatusBadge from "@Eikochain/components/molecules/StatusBadge";
import useCsvUploads from "@Eikochain/graphql/hooks/useGetCsvUploads";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, EyeIcon, MoreHorizontalIcon, PencilIcon, Trash2Icon } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../button";
import { Dialog } from "../dialog";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../dropdown-menu";
import DeleteUploadDialogContent from "./delete-upload-dialog-content";
import EditUploadDialogContent from "./edit-upload-dialog-content";

const columns: ColumnDef<CsvUpload>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <Button className="p-0" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div>{row.getValue("name")}</div>,
  },
  {
    accessorKey: "status",
    header: "Status",
    cell: ({ row }) => <StatusBadge status={row.getValue("status")} />,
  },
  {
    accessorKey: "createdAt",
    header: "Uploaded",
    cell: ({ row }) => <div>{row.getValue("createdAt")}</div>,
  },
  {
    accessorKey: "totalEmissions",
    header: () => <div>CO2<sub>e</sub> (kg)</div>,
    cell: ({ row }) => <div className="font-mono">{row.getValue("totalEmissions")}</div>,
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => {
      const [result, executeQuery] = useCsvUploads()
      const [editDialogOpen, setEditDialogOpen] = useState(false);
      const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
      function onEditFinish() {
        executeQuery({ requestPolicy: "network-only" });
        setEditDialogOpen(false)
      }
      function onDeleteFinish() {
        executeQuery({ requestPolicy: "network-only" });
        setDeleteDialogOpen(false)
      }
      return (
        <>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                <MoreHorizontalIcon />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="gap-4">
              <DropdownMenuItem asChild>
                <Link to={"/app/uploads/detail/" + row.original.djangoId}>
                  <EyeIcon />
                  View
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setEditDialogOpen(true)}>
                <PencilIcon />
                Rename
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => setDeleteDialogOpen(true)}>
                <Trash2Icon />
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Dialog open={editDialogOpen} onOpenChange={setEditDialogOpen}>
            <EditUploadDialogContent onFinish={onEditFinish} file={row.original} />
          </Dialog>
          <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
            <DeleteUploadDialogContent fileId={row.original.djangoId} onFinish={onDeleteFinish} />
          </Dialog>
        </>
      );
    },
  },
];

export default columns;
