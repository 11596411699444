import { ProductToIntegrate } from "@Eikochain/__generated__/graphql";
import { Button } from "@Eikochain/components/ui/button";
import ImportProductsFromIntegrationTable from "@Eikochain/components/ui/import-products-from-integration-table";
import { BulkCreateProductsMutation } from "@Eikochain/graphql/hooks/useBulkCreateProducts";
import useGetProductsForOrganizationIntegration from "@Eikochain/graphql/hooks/useGetProductsForOrganizationIntegration";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useMutation } from "urql";

interface SelectProductsToImportProps {
  organizationIntegrationId: string;
}

const SelectProductsToImport = ({
  organizationIntegrationId,
}: SelectProductsToImportProps) => {
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState<ProductToIntegrate[]>([]);
  const [mutationResult, executeMutation] = useMutation(BulkCreateProductsMutation)
  const [result] = useGetProductsForOrganizationIntegration(organizationIntegrationId)

  function importProducts () {
    const input = {
      organizationIntegrationId,
      products: omitDeep(selectedProducts, "id", "image", "key", "__typename")
    }
    executeMutation({ input }).then(result => {
      const { data, error} = result;
      if (error) {
        toast.error("An error occured. We'll look into it.")
      } else {
        toast.success("Successfully imported products")
        navigate("/app/products/");
      }
    });
  }

  function onRowChange(selectedRows: any[]) {
    setSelectedProducts(selectedRows);
  }

  return (
    <>
      <ImportProductsFromIntegrationTable
        data={result?.data?.productsForOrganizationIntegration ?? []}
        isFetching={result?.fetching}
        onRowChange={onRowChange}
      />
      <div className="flex flex-end">
        <Button onClick={importProducts} disabled={mutationResult?.fetching}>Import</Button>
      </div>
    </>
  )
};

export default SelectProductsToImport;
