import { gql } from 'urql';

export const RetrieveEmissionResultsMutation = gql`
  mutation RetrieveEmissionResults(
    $productId: String!
  ) {
    retrieveEmissionResults(productId: $productId) {
      ... on RetrieveEmissionResultsSuccess {
        success
      }
      ... on RetrieveEmissionResultsErrors {
        errors
      }
    }
  }
`;
