import { gql } from 'urql';

export const CreateExportForActivitiesMutation = gql`
  mutation createExportForActivities(
    $format: String!,
    $id: String,
    $activities: [String!]
    $headers: [String!]
  ) {
    createExportForActivities(id: $id, format: $format, activities: $activities, headers: $headers) {
      ... on CreateExportForActivitiesSuccess {
        url
      }
      ... on CreateExportForActivitiesErrors {
        errors
      }
    }
  }
`;
