import { gql, useQuery } from 'urql';

const GetOrganizationIntegrationsQuery = gql`
  query {
    organizationIntegrations {
      djangoId
      url
      integrationType {
        name
      }
    }
  }
`;

const useGetOrganizationIntegrations = () => {
  const result = useQuery({
    query: GetOrganizationIntegrationsQuery,
  })
  return result;
}

export default useGetOrganizationIntegrations;
