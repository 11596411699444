import Spacer from "@Eikochain/components/atoms/Spacer";
import { Button } from "@Eikochain/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@Eikochain/components/ui/tooltip";
import useCreateMenuStep from "@Eikochain/hooks/MarketingEditor/useCreateMenuStep";
import useDeleteMenuStep from "@Eikochain/hooks/MarketingEditor/useDeleteMenuStep";
import useDuplicateMenuStep from "@Eikochain/hooks/MarketingEditor/useDuplicateMenuStep";
import useRenameMenuStep from "@Eikochain/hooks/MarketingEditor/useRenameMenuStep";
import useReorderMenuSteps from "@Eikochain/hooks/MarketingEditor/useReorderMenuSteps";
import { canvasMenuSelectedStepIdAtom, canvasMenuStepsAtom } from "@Eikochain/state/atoms/CanvasMenuContent";
import { useAtom, useAtomValue } from "jotai";
import { PlusIcon } from "lucide-react";
import { toast } from "sonner";
import CanvasMenuStep from "./CanvasMenuStep";

interface CanvasMenuStepsProps {
  isEditing?: boolean;
}

const CanvasMenuSteps = ({ isEditing = false }: CanvasMenuStepsProps) => {
  const steps = useAtomValue(canvasMenuStepsAtom);
  const [selectedStepId, setSelectedStepId] = useAtom(canvasMenuSelectedStepIdAtom);

  const createStep = useCreateMenuStep();
  const deleteStep = useDeleteMenuStep();
  const duplicateStep = useDuplicateMenuStep();
  const renameStep = useRenameMenuStep();
  const reorderSteps = useReorderMenuSteps();

  const deleteStepFn = (stepLocalId: number) => {
    deleteStep(stepLocalId);
    toast.success("Deleted step");
  };
  const duplicateStepFn = (stepLocalId: number) => {
    duplicateStep(stepLocalId);
    toast.success("Duplicated step");
  };
  const renameStepFn = (stepLocalId: number, newName: string) => {
    renameStep(stepLocalId, newName);
    toast.success("Renamed step");
  };

  const AddNewStep = () => {
    return (
      <>
        <Spacer height={4} />
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger asChild>
              <Button className="w-full" onClick={createStep} icon={<PlusIcon />}>
                Add a new step
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              Steps are the first building block in creating your marketing canvas
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </>
    );
  };

  const onMenuStepClick = (localId: number, serverId: string) => {
    const menuStepIds = { localId, serverId };
    // No need to set the selected step if it's the same
    if (localId !== selectedStepId?.localId) setSelectedStepId(menuStepIds);
  };

  return (
    <div className="w-full">
      {steps.map((step, index) => {
        const isSelected = selectedStepId?.localId === step.localId;
        return (
          <CanvasMenuStep
            key={step.localId}
            data={step}
            isSelected={isSelected}
            isEditing={isEditing}
            isFirst={index === 0}
            isLast={index === steps.length - 1}
            onClick={(stepLocalId, stepServerId) => onMenuStepClick(stepLocalId, stepServerId)}
            onRename={(stepLocalId, newName) => renameStepFn(stepLocalId, newName)}
            onDuplicate={(stepLocalId) => duplicateStepFn(stepLocalId)}
            onDelete={(stepLocalId) => deleteStepFn(stepLocalId)}
            onReorder={(stepLocalId, direction) => reorderSteps(stepLocalId, direction)}
          />
        );
      })}
      {isEditing && <AddNewStep />}
    </div>
  );
};

export default CanvasMenuSteps;
