import { gql } from 'urql';

export const AuthenticateUserMutation = gql`
  mutation AuthenticateUser(
    $email: String!,
    $password: String!
  ) {
    authenticateUser(email: $email, password: $password) {
      ... on AuthenticateUserSuccess {
        user {
          email
          firstNames
          lastName
          organization {
            id: djangoId
            name
          }
          isStaff
        }
        authToken
      }
      ... on AuthenticateUserErrors {
        fieldErrors {
          fieldName
          errors
        }
      }
    }
  }
`;
